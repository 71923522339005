import React from 'react';

import { Link, FinePrint } from '@uikit';
import { useCopy } from '@app/utils';
import { Color } from '@app/styles';

const RegistrationDisclosures = () => {
  const { c } = useCopy('catch.module.disclosures');

  return (
    <FinePrint
      items={[
        c('register', {
          terms: (
            <Link testID="terms" key="l-2" color={Color.subtle} href="https://catch.co/legal/terms">
              {c('platform.terms')}
            </Link>
          ),
          privacy: (
            <Link
              testID="privacy"
              key="l-3"
              color={Color.subtle}
              href="https://catch.co/legal/privacy"
            >
              {c('platform.privacy')}
            </Link>
          ),
        }),
      ]}
    />
  );
};

export default RegistrationDisclosures;
