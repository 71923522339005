import React, { useMemo } from 'react';
import { mutations, queries, updates, UpsertHealthApplication, useMutation, useQuery } from '@data';
import { navigate } from '@navigate';
import { PrivacyAgreementsQueryData } from '@app/data/queries/PrivacyAgreementsQuery';
import { hasAgreedToPrivacy } from '@app/utils';
import { HealthZipTriage } from '@partial';
import { Route } from '@types';

const CoverageInfo = () => {
  const { data } = useQuery<PrivacyAgreementsQueryData>(queries.PRIVACY_AGREEMENTS);

  const hasAgreed = useMemo(() => {
    return hasAgreedToPrivacy(data?.viewerTwo?.agreements);
  }, [data?.viewerTwo.agreements]);

  const [upsertApplication, { loading: creating }] = useMutation(UpsertHealthApplication, {
    onCompleted: (data) => {
      navigate(hasAgreed ? Route.EDE_HOUSEHOLD : Route.EDE_PRIVACY, {
        applicationID: data?.upsertHealthApplication?.id,
      });
    },
  });

  const [upsertExplorer, { loading: upserting }] = useMutation(mutations.UPSERT_EXPLORER, {
    update: updates.UPDATE_EXPLORER,
    onCompleted: (data) => {
      if (data.upsertHealthExplorerData.pathwayType === 'STATE_EXCHANGE') {
        navigate(Route.EXPLORER_STATE_HANDOFF);
      }
    },
  });

  const onSubmit = async ({ coverageYear, location }) => {
    // update the explorer regardless of pathway
    upsertExplorer({
      variables: {
        input: {
          coverageYear,
          zipcode: location.zip,
          countyfips: location.fips,
          state: location.state,
          pathwayType: location.pathway,
        },
      },
    });

    if (location.pathway === 'EDE') {
      upsertApplication({
        variables: {
          input: {
            coverageYearNumber: coverageYear,
            coverageState: location.state,
            zip: location.zip,
            countyfips: location.fips,
            isStartedApplication: true,
          },
        },
      });
    }
  };

  return (
    <HealthZipTriage
      loading={!data}
      submitting={creating || upserting}
      title="Let's get started with the basics"
      oeDates={data?.reference?.health?.openEnrollmentDates}
      onSubmit={onSubmit}
    />
  );
};

export const CoverageInfoView = {
  name: 'EDE_COVERAGE_INFO',
  component: CoverageInfo,
  options: {
    title: 'Location',
  },
};
