import React, { useMemo } from 'react';
import { ScrollView } from 'react-native';

import { getEventProperties } from '@app/utils';
import EventReceipt from '@common/components/EventReceipt';
import { Route } from '@types';

const Receipt = ({ event }) => {
  const translated = useMemo(() => getEventProperties(event), [event]);

  return (
    <ScrollView>
      <EventReceipt event={event} eventProperties={translated} />
    </ScrollView>
  );
};

/**
 * @todo E-3287
 */
export const ReceiptView = {
  name: Route.EVENT_RECEIPT,
  component: Receipt,
  options: {
    title: ({ event }) => {
      const props = getEventProperties(event);
      return props?.title || event?.source?.text || props?.receiptTitle;
    },
  },
};
