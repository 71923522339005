import React from 'react';
import { Text } from '../Text';
import BaseRow from './BaseRow';
import {
  Copy,
  TextColor,
  BackgroundColor,
  AssetProps,
  AccessoryType,
  InteractionAccessoryType,
  SemanticColor,
} from '@types';
import Badge, { BadgeColor } from '../Badge';
import { Block } from '../Block';

interface Props {
  loading?: boolean;
  testID: string;
  asset?: AssetProps;
  label: Copy;
  sublabel: Copy;
  badge?: string;
  labelColor?: TextColor;
  sublabelColor?: TextColor;
  interaction?: InteractionAccessoryType;
  accessory?: AccessoryType;
  disabled?: boolean;
  onPress?: any;
  selectedBg?: BackgroundColor;
  selected?: boolean;
  selectedByDefault?: boolean;
  bold?: boolean;
  fp?: boolean;
  raised?: boolean;
  accentColor?: SemanticColor;
  bg?: string;
  sensitive?: boolean;
}

/**
 * The base row only hold the common aspects of different types of row.
 * Not designed be used in UI Kit, only use as base for other row components.
 *
 * @todo should the typing be more hierarchical to reflect this^?
 * (e.g. BaseRowProps, ComplexRowProps extends that, etc...)
 */
const ComplexRow = ({
  loading,
  testID,
  label,
  labelColor = 'text',
  sublabel,
  sublabelColor = 'subtle',
  asset,
  interaction,
  accessory,
  disabled,
  onPress,
  onLabelPress,
  badge,
  selectedBg,
  selected,
  selectedByDefault,
  raised,
  accentColor,
  bg,
  inset,
  sensitive,
  noPadding,
  ...rest
}: Props) => {
  return (
    <BaseRow
      inset={inset}
      loading={loading}
      testID={testID}
      asset={asset}
      selected={selected}
      selectedByDefault={selectedByDefault}
      selectedBg={selectedBg}
      raised={raised}
      interaction={interaction}
      accessory={accessory}
      disabled={disabled}
      onPress={onPress}
      onLabelPress={onLabelPress}
      accentColor={accentColor}
      bg={bg}
      sensitive={sensitive}
      noPadding={noPadding}
      content={
        <Block>
          {badge && <Badge label={badge} color={BadgeColor.success} />}
          <Text
            inline
            group
            loading={loading}
            color={labelColor}
            weight="medium"
            size="p"
            gutter={badge && 'top'}
          >
            {label}
          </Text>
          {!!sublabel && (
            <Text inline group loading={loading} color={sublabelColor} size="fp">
              {sublabel}
            </Text>
          )}
        </Block>
      }
      {...rest}
    />
  );
};

export default ComplexRow;
