import { useMutation, CreatePresignedUrl, DocFileType } from '@data';

export type SupportedFileType =
  | 'JPG'
  | 'JPEG'
  | 'image/jpeg'
  | 'PNG'
  | 'image/png'
  | 'application/pdf'
  | 'HEIF'
  | 'image/heif'
  | 'HEIC'
  | 'image/heic';

const fileTypes: Record<SupportedFileType, DocFileType> = {
  JPG: DocFileType.Jpeg,
  JPEG: DocFileType.Jpeg,
  'image/jpeg': DocFileType.Jpeg,
  PNG: DocFileType.Png,
  'image/png': DocFileType.Png,
  'application/pdf': DocFileType.Png,
  HEIF: DocFileType.Heif,
  'image/heif': DocFileType.Heif,
  HEIC: DocFileType.Heic,
  'image/heic': DocFileType.Heic,
};

const mimeTypes = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  PDF: 'application/pdf',
  HEIC: 'image/heic',
  HEIF: 'image/heif',
};

// 10MB
const DEFAULT_MAX = 10000000;

export interface UploadData {
  fileKey: string;
  fileType: DocFileType;
}

export interface UploadOptions {
  onStart: () => void;
  onError: (err: string) => void;
  onCompleted: (data: UploadData) => void;
}

export interface UploadProps {
  name: string;
  file: File;
  options: UploadOptions;
}

export interface UseUploadProps {
  filePrefix: string;
  onSuccess: (data: UploadData) => void;
}

export function useUpload({ filePrefix, onSuccess }: UseUploadProps): {
  upload: (props: UploadProps) => void;
} {
  const [createPresignedUrl] = useMutation(CreatePresignedUrl);

  const validateFile = (file, options): boolean => {
    if (file?.size > DEFAULT_MAX) {
      options.onError('File is too large');
      return false;
    }

    if (!fileTypes[file?.type]) {
      options.onError('Invalid format');
      return false;
    }

    return true;
  };

  const upload = async ({ file, options }) => {
    options?.onStart();
    const isValid = validateFile(file, options);

    if (isValid) {
      const fileType = fileTypes[file.type];
      const fileName = `${filePrefix}-${Date.now()}.${fileType}`;
      const mimeType = mimeTypes[fileType];

      const uploadData: UploadData = {
        fileKey: fileName,
        fileType,
      };

      // generate the presigned URL
      createPresignedUrl({
        variables: {
          input: { fileName, fileType: mimeType },
        },
        onCompleted: async (data) => {
          const presignedUrl = data?.createPresignedUrl?.url;

          try {
            await fetch(presignedUrl, {
              method: 'PUT',
              body: file,
            });

            options.onCompleted(uploadData);
            onSuccess(uploadData);
          } catch (e) {
            options.onError('Something went wrong. Try again.');
          }
        },
        onError: () => {
          console.log('error fetching presigned url');
          options.onError('Something went wrong. Try again.');
        },
      });
    }
  };

  return { upload };
}
