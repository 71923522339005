import { optionPresets } from '@app/forms';
import { lowercase } from '@app/utils';

const sharedFA = {
  copy: 'catch.ede',
  type: 'radio',
  name: 'isRequestingFinancialAssistance',
  required: true,
  dependencies: ['isRequestingFinancialAssistance', 'explorerPlan', 'householdIncome'],
  options: [
    { key: 'yes', label: { id: 'Screening.apply' }, value: 'YES' },
    { key: 'no', label: { id: 'Screening.payFull' }, value: 'NO' },
  ],
  fieldAddition: ({ isRequestingFinancialAssistance, explorerPlan }) => {
    if (isRequestingFinancialAssistance === 'NO') {
      return { component: 'FINANCIAL_ASSISTANCE_WARNING', props: { explorerPlan } };
    }
  },
};

export const fields = [
  {
    copy: 'catch.ede',
    name: 'financial-assistance-section',
    type: 'section',
    title: { id: 'Screening.sectionFinAssist' },
    fields: [
      {
        ...sharedFA,
        testID: 'S5',
        label: { id: 'HealthEnrollment.S5_Q' },
        help: { id: 'HealthEnrollment.S5_T' },
        dependencies: ['isRequestingFinancialAssistance', 'explorerPlan', 'householdIncome'],
        display: ({ householdIncome }) => !householdIncome,
      },
      {
        ...sharedFA,
        testID: 'S6',
        label: { id: 'HealthEnrollment.S6_Q' },
        help: { id: 'HealthEnrollment.S6_T' },
        dependencies: [
          'isRequestingFinancialAssistance',
          'explorerPlan',
          'householdIncome',
          'qualifiesForFinancialHelp',
        ],
        display: ({ householdIncome, qualifiesForFinancialHelp }) =>
          householdIncome && qualifiesForFinancialHelp,
      },
      {
        ...sharedFA,
        testID: 'S7',
        label: { id: 'HealthEnrollment.S7_Q' },
        help: { id: 'HealthEnrollment.S7_T' },
        dependencies: [
          'isRequestingFinancialAssistance',
          'explorerPlan',
          'householdIncome',
          'qualifiesForFinancialHelp',
        ],
        display: ({ householdIncome, qualifiesForFinancialHelp }) =>
          householdIncome && !qualifiesForFinancialHelp,
      },
      {
        copy: 'catch.ede',
        required: true,
        type: 'checkbox',
        name: 'agreesToFullPrice',
        dependencies: [
          'isRequestingFinancialAssistance',
          'premium',
          'premiumWithCredit',
          'hasSavings',
        ],
        label: ({ premium, premiumWithCredit }) => ({
          id: 'Screening.warning',
          data: { premium, premiumWithCredit },
        }),
        display: ({ isRequestingFinancialAssistance, hasSavings }) => {
          return hasSavings && isRequestingFinancialAssistance === 'NO';
        },
      },
    ],
  },
  {
    copy: 'catch.ede',
    name: 'household-section',
    type: 'section',
    title: { id: 'Screening.sectionHousehold' },
    fields: [
      {
        copy: 'catch.ede',
        testID: 'S8',
        type: 'radio',
        dependencies: ['householdCategory', 'coverageState'],
        label: ({ householdCategory, coverageState }) => ({
          id: 'HealthEnrollment.S8_Q',
          data: { category: householdCategory, state: coverageState },
        }),
        help: { id: 'HealthEnrollment.S8_T' },
        name: 'areNotSameAddress',
        options: optionPresets.YES_NO_TRINARY,
        required: true,
        equals: 'NO',
      },
      {
        copy: 'catch.ede',
        testID: 'S9',
        type: 'radio',
        dependencies: ['isRequestingFinancialAssistance', 'maritalStatus', 'coverageYear'],
        label: ({ maritalStatus, coverageYear }) => ({
          id: 'HealthEnrollment.S9_Q',
          data: { maritalStatus: lowercase(maritalStatus), coverageYear },
        }),
        help: ({ coverageYear }) => ({
          id: 'HealthEnrollment.S9_T',
          data: { coverageYear, followingYear: coverageYear ? coverageYear + 1 : '' },
        }),
        name: 'isPlanningToFileTax',
        display: (values) => values.isRequestingFinancialAssistance === 'YES',
        equals: 'YES',
        options: ({ maritalStatus, coverageYear }) => {
          const options = [
            {
              label: { id: 'screening.isPlanningToFileTax.JOINTLY', data: { coverageYear } },
              value: 'YES',
              maritalStatus: 'MARRIED',
            },
            {
              label: { id: 'screening.isPlanningToFileTax.SEPARATELY', data: { coverageYear } },
              value: 'SEPARATELY',
              maritalStatus: 'MARRIED',
            },
            {
              label: { id: 'screening.isPlanningToFileTax.YES', data: { coverageYear } },
              value: 'YES',
              maritalStatus: 'SINGLE',
            },
            {
              label: { id: 'screening.isPlanningToFileTax.NO', data: { coverageYear } },
              value: 'NO',
            },
          ];

          return options.filter((o) => !o.maritalStatus || o.maritalStatus === maritalStatus);
        },
        required: true,
      },
      {
        copy: 'catch.ede',
        testID: 'S10',
        type: 'radio',
        dependencies: ['isRequestingFinancialAssistance', 'maritalStatus'],
        label: ({ maritalStatus }) => ({
          id: 'HealthEnrollment.S10_Q',
          data: { maritalStatus: lowercase(maritalStatus) },
        }),
        help: { id: 'HealthEnrollment.S10_T' },
        name: 'isResponsibleForChildNotOnTaxReturn',
        display: (values) => values.isRequestingFinancialAssistance === 'YES',
        options: optionPresets.YES_NO_TRINARY,
        required: true,
        equals: 'NO',
      },
      {
        copy: 'catch.ede',
        testID: 'S19',
        type: 'radio',
        dependencies: ['isRequestingFinancialAssistance', 'householdCategory'],
        label: ({ householdCategory }) => ({
          id: 'HealthEnrollment.S19_Q',
          data: { category: householdCategory },
        }),
        help: { id: 'HealthEnrollment.S19_T' },
        name: 'isIndianOrAlaskan',
        options: optionPresets.YES_NO_TRINARY,
        required: true,
        equals: 'NO',
      },
    ],
  },
  {
    copy: 'catch.ede',
    name: 'applicants-section',
    type: 'section',
    title: { id: 'Screening.sectionApplicants' },
    fields: [
      {
        copy: 'catch.ede',
        testID: 'S20',
        type: 'radio',
        dependencies: ['isRequestingFinancialAssistance', 'applicantsCategory'],
        label: ({ applicantsCategory }) => ({
          id: 'HealthEnrollment.S20_Q',
          data: { category: applicantsCategory },
        }),
        name: 'isCoveredThroughJobOrCobra',
        help: { id: 'HealthEnrollment.S20_T' },
        display: (values) => values.isRequestingFinancialAssistance === 'YES',
        options: optionPresets.YES_NO_TRINARY,
        required: true,
        equals: 'NO',
      },
      {
        copy: 'catch.ede',
        testID: 'S27',
        type: 'radio',
        dependencies: ['applicantsCategory'],
        label: ({ applicantsCategory }) => ({
          id: 'HealthEnrollment.S27_Q',
          data: { category: applicantsCategory },
        }),
        name: 'isOfferedIchra',
        help: { id: 'HealthEnrollment.S27_T' },
        options: optionPresets.YES_NO_TRINARY,
        required: true,
        equals: 'NO',
      },
    ],
  },
  {
    copy: 'catch.ede',
    name: 'dependents-section',
    type: 'section',
    title: { id: 'Screening.sectionDependents' },
    dependencies: ['numTaxDependents', 'isRequestingFinancialAssistance'],
    display: (values) =>
      values.numTaxDependents > 0 && values.isRequestingFinancialAssistance === 'YES',
    fields: [
      {
        copy: 'catch.ede',
        testID: 'S22',
        type: 'radio',
        dependencies: ['isRequestingFinancialAssistance', 'coverageYear', 'dependentsCategory'],
        label: ({ coverageYear, dependentsCategory }) => ({
          id: 'HealthEnrollment.S22_Q',
          data: { coverageYear, category: dependentsCategory },
        }),
        help: { id: 'HealthEnrollment.S22_T' },
        name: 'notClaimingAllDependentsOnTax',
        display: (values) => values.isRequestingFinancialAssistance === 'YES',
        options: optionPresets.YES_NO_TRINARY,
        required: true,
        equals: 'NO',
      },
      {
        copy: 'catch.ede',
        testID: 'S23',
        type: 'radio',
        dependencies: ['isRequestingFinancialAssistance', 'dependentsCategory'],
        label: ({ dependentsCategory }) => ({
          id: 'HealthEnrollment.S23_Q',
          data: { category: dependentsCategory },
        }),
        name: 'isDependentSingleChild25OrYounger',
        display: (values) => values.isRequestingFinancialAssistance === 'YES',
        options: optionPresets.YES_NO_TRINARY,
        required: true,
        equals: 'NO',
      },
      {
        copy: 'catch.ede',
        testID: 'S26',
        type: 'radio',
        dependencies: ['isRequestingFinancialAssistance', 'dependentsCategory'],
        label: ({ dependentsCategory }) => ({
          id: 'HealthEnrollment.S26_Q',
          data: { category: dependentsCategory },
        }),
        name: 'areDependentsLivingWithParentNotOnTaxReturn',
        display: (values) => values.isRequestingFinancialAssistance === 'YES',
        options: optionPresets.YES_NO_TRINARY,
        required: true,
        equals: 'NO',
      },
    ],
  },
];
