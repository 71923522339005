import { ScreenDefinition, paths, TabComponent, StackComponent } from '@navigate';

// stack imports
import AuthStack from './auth/AuthStack';
import HomeStack from './home/HomeStack';
import CoverageStack from './coverage/CoverageStack';
import ActivityStack from './activity/ActivityStack';
import MeStack from './me/MeStack';
import HealthExplorerStack from './explorer/HealthExplorerStack';
import HealthLinkStack from './link/HealthLinkStack';
import HealthApplicationStack from './application/HealthApplicationStack';
import CICStack from './cic/CICStack';

// view imports
import { HealthResumeView } from './application/routes/HealthResumeView';
import { EditUserDetailsView } from './me/partials/EditUserDetailsView';
import { ChangeAliasView } from './me/partials/ChangeAliasView';
import { ChangePasswordView } from './me/partials/ChangePasswordView';
import DevView from '@app/dev/DevView';
import { WalletItemView } from './misc/WalletItemView';
import { HealthDocsUploadView } from './application/routes/docs/HealthDocsUploadView';
import { HealthDocsUploadedView } from './application/routes/docs/HealthDocsUploadedView';

// tab declarations
export const tabs: Array<TabComponent> = [HomeStack, CoverageStack];

// layers that open above main app
export const layers: Array<StackComponent> = [
  ActivityStack,
  MeStack,
  HealthExplorerStack,
  HealthLinkStack,
  HealthApplicationStack,
  CICStack,
];

// stack for unauthed users
export const guest: StackComponent = AuthStack;

// standalone pages that can be opened from anywhere
export const standalone: Array<ScreenDefinition> = [
  HealthResumeView, // @todo E-3445 typescript
  HealthDocsUploadView,
  HealthDocsUploadedView,
  WalletItemView,
  EditUserDetailsView,
  ChangeAliasView,
  ChangePasswordView,
  DevView,
];

/**
 * Heads up!
 * -------------------------
 * Everything below is just a consolidated mapping of stack/screens
 * IF a screen/stack is missing, add it to the corresponding configs above instead
 * -------------------------
 */

// consolidated definition for all stacks
export const stacks: Array<StackComponent> = [guest, ...tabs, ...layers];

/**
 * Builds a single array of all screens
 * Note: do not remove sort -- it's in place to ensure that web routing happens in correct order
 * so that a url like /plan/:slug/intro gets matched before /plan
 */
export const screens: Array<ScreenDefinition> = stacks
  .reduce((acc: Array<ScreenDefinition>, stack: StackComponent) => {
    return [...acc, ...stack.config.screens];
  }, [])
  .concat(standalone)
  .sort(({ name: pageName }, { name: secondPageName }) => {
    return paths[pageName] > paths[secondPageName] ? -1 : 1;
  });
