import access from 'safe-access';
import { benefitColorLookup } from '../color';
import { formatBenefit } from '../format_old/benefit';
import { accountRef } from '../format_old';
import { capitalize } from '../format_old/strings';
import {
  formatGoalEvent,
  formatHealthEvent,
  formatDentalEvent,
  formatUnknownEvent,
  formatAutopilotAction,
  formatGoalPercentage,
  formatPaycheckAmount,
  formatGoalForGenericContext,
  formatContributionAmount,
  formatHealthColor,
  formatAutopilotColor,
  showReceiptAction,
  matchByTransferDirection,
  formatStatus,
  normalizeType,
  formatIcon,
  formatSource,
  formatAmount,
  formatDate,
  formatAutoIcon,
} from './formatActivity';
import { identity } from 'lodash/util';

const pendingTitle = 'Pending';
const failedTitle = 'Failed';

export const ACTIVITY_EVENT_TYPES = {
  TransferEvent_INCOME: {
    asset: formatSource,
    title: ({ status, transferAmount }) => {
      if (status === 'NOT_INCOME') return 'Ignored';
      if (status === 'SKIPPED') return 'Skipped contribution';
      if (status === 'USER_PENDING') return 'Pending contribution';
      if (status === 'CANCELED' || status === 'FAILED') return 'contribution';
      return !!transferAmount ? `contributed` : 'Contribution';
    },
    subtitle: (event) => {
      if (event?.status === 'CANCELED' || event?.status === 'FAILED') return 'Failed';
      if (event?.transferStatus === 'PROCESSING' || event?.status === 'PROCESSING')
        return pendingTitle;
      if (event?.transferStatus === 'FAILED') return failedTitle;
      if (event?.status === 'NOT_INCOME') return `from ${event?.source?.text}`;
      return formatPaycheckAmount(event);
    },
    amount: formatContributionAmount,
    action: (e, a) => () => {
      const fn =
        e?.status === 'DELAYED'
          ? a?.showScheduled
          : e?.status === 'USER_PENDING' || e?.status === 'SKIPPED'
          ? a?.showIncomeDecision
          : a?.showReceipt;
      if (typeof fn === 'function') fn(e);
    },
    receiptTitle: ({ source }) => {
      return source?.text || source?.tag || 'Income Source';
    },

    dateLabel: 'Initiated',
    from: (event) => accountRef(event),
    to: 'Catch',
  },

  TransferEvent_SINGLE_DEPOSIT: {
    asset: (e, c) =>
      formatIcon('DEPOSIT', {
        color: c,
        pending: e?.status === 'PROCESSING',
        failed: e?.status === 'FAILED',
      }),
    title: 'Deposit',
    subtitle: (e) =>
      e?.status === 'PROCESSING'
        ? pendingTitle
        : e?.status === 'FAILED'
        ? failedTitle
        : `to ${formatBenefit.name(access(e, 'transactionRecords[0].goal'))}`,
    action: showReceiptAction,
    dateLabel: 'Initiated',
    from: (event) => accountRef(event),
    to: (e) => formatBenefit.name(access(e, 'transactionRecords[0].goal')),
    color: (e) => benefitColorLookup({ slug: access(e, 'transactionRecords[0].goal.slug') }),
  },

  TransferEvent_BANK_TRANSFER: {
    asset: (e, c) =>
      formatIcon(e?.amount > 0 ? 'DEPOSIT' : 'WITHDRAW', {
        color: e?.amount > 0 ? 'debit' : 'credit',
        pending: e?.status === 'PROCESSING',
        failed: e?.status === 'FAILED',
      }),
    title: (e, c) => (e?.amount > 0 ? 'Deposit' : 'Withdrawal'),
    subtitle: (e) =>
      e?.status === 'PROCESSING'
        ? pendingTitle
        : e?.status === 'FAILED'
        ? failedTitle
        : `${e?.amount > 0 ? 'to' : 'from'} ${formatBenefit.name(
            access(e, 'transactionRecords[0].goal'),
          )}`,
    action: showReceiptAction,
    dateLabel: 'Initiated',
    from: (e) => (e, c) =>
      e?.amount > 0 ? undefined : formatBenefit.name(access(e, 'transactionRecords[0].goal')),
    to: (event) => (event?.amount > 0 ? undefined : accountRef(event)),
    color: (e) => benefitColorLookup({ slug: access(e, 'transactionRecords[0].goal.slug') }),
  },

  TransferEvent_WITHDRAWAL: {
    asset: (e, c) =>
      formatIcon('WITHDRAW', {
        color: c,
        pending: e?.status === 'PROCESSING',
        failed: e?.status === 'FAILED',
      }),
    title: 'Withdrawal',
    subtitle: (e) =>
      e?.status === 'PROCESSING'
        ? pendingTitle
        : e?.status === 'FAILED'
        ? failedTitle
        : `from ${formatBenefit.name(access(e, 'transactionRecords[0].goal'))}`,
    action: showReceiptAction,
    dateLabel: 'Initiated',
    from: (e) => formatBenefit.name(access(e, 'transactionRecords[0].goal')),
    to: (event) => accountRef(event),
    color: (e) => benefitColorLookup({ slug: access(e, 'transactionRecords[0].goal.slug') }),
  },

  TransferEvent_BETWEEN_GOALS: {
    asset: (e, c) => formatIcon('TRANSFER', { color: c }),
    title: 'Catch Transfer',
    subtitle: (e) => {
      const destination = formatBenefit.name(access(e, 'transactionRecords[0].goal'));
      const source = formatBenefit.name(access(e, 'transactionRecords[1].goal'));
      return matchByTransferDirection(e, {
        destination: `from ${source}`,
        source: `to ${destination}`,
        default: `${source} to ${destination}`,
      });
    },
    color: (event) =>
      matchByTransferDirection(event, {
        destination: 'debit',
        source: 'credit',
        default: 'text',
      }),
    amount: (event) => access(event, 'transactionRecords[0].amount'),
    totalAmount: (event) => access(event, 'transactionRecords[0].amount'),
    action: showReceiptAction,
    dateLabel: 'Initiated',
    from: (e) => formatBenefit.name(access(e, 'transactionRecords[0].goal')),
    to: (e) => formatBenefit.name(access(e, 'transactionRecords[1].goal')),
  },

  TransferEvent_REFERRAL: {
    asset: (e, c) => formatIcon('HEART', { color: c }),
    title: 'Referral',
  },

  TransferEvent_REWARD: {
    asset: (e, c) => formatIcon('HEART', { color: c }),
    title: 'Savings Boost',
    subtitle: (e) => e?.description,
  },

  TransferEvent: {
    asset: (e, c) =>
      formatIcon('TRANSFER', {
        color: c,
        pending: e?.status === 'PROCESSING',
        failed: e?.status === 'FAILED',
      }),
    title: 'Transfer',
    subtitle: (event) =>
      event?.status === 'PROCESSING'
        ? pendingTitle
        : event?.status === 'FAILED'
        ? failedTitle
        : capitalize(normalizeType(event)),
    amount: (e) => e?.amount || access(e, 'transactionRecords[0].amount'),
  },

  PaymentEvent: {
    asset: (e, c) =>
      formatIcon('PAYMENT', {
        color: c,
        pending: /INITIAL|SENT/.test(e?.status),
        failed: /FAILED|CANCELED/.test(e?.status),
      }),
    title: `Tax Payment`,
    subtitle: (event) => {
      return /INITIAL|SENT/.test(event?.status)
        ? pendingTitle
        : /FAILED|CANCELED/.test(event?.status)
        ? failedTitle
        : `to the IRS`;
    },
    receiptTitle: 'Tax Payment',
    action: showReceiptAction,
    dateLabel: 'Initiated',
    from: ({ goalType }) => `Catch ${formatBenefit.name({ goalType })}`,
    to: 'The IRS',
    color: 'taxes',
  },

  CatchFeePaymentEvent_RETIREMENT_FEE: {
    asset: (e, c) => formatIcon('PAYMENT', { color: c }),
    title: 'Management Fee',
    subtitle: 'from Retirement',
    action: showReceiptAction,
    receiptTitle: 'Management Fee',
    dateLabel: 'Billed',
    to: 'Catch Financial',
    color: 'retirement',
    from: (event) => {
      if (event?.goal) {
        return `Catch ${formatBenefit.name(event?.goal)}`;
      } else {
        return 'Catch Retirement';
      }
    },
  },

  AutopilotRuleAddedEvent: {
    asset: (e) => formatAutoIcon(e),
    title: formatAutopilotAction,
    color: formatAutopilotColor,
  },
  AutopilotRuleResumedEvent: {
    asset: (e) => formatAutoIcon(e),
    title: formatAutopilotAction,
    color: formatAutopilotColor,
  },
  AutopilotRulePausedEvent: {
    asset: (e) => formatAutoIcon(e),
    title: formatAutopilotAction,
  },
  AutopilotRuleRemovedEvent: {
    asset: (e) => formatAutoIcon(e),
    title: formatAutopilotAction,
  },

  GoalAddedEvent: {
    asset: (e, c) => formatIcon('ADD', { color: c }),
    title: formatGoalEvent,
    subtitle: formatGoalPercentage,
  },

  GoalUpdatedEvent: {
    asset: (e, c) => formatIcon('PERCENT', { color: c }),
    title: formatGoalEvent,
    subtitle: formatGoalPercentage,
  },

  GoalMetEvent: {
    asset: (e, c) => formatIcon('STAR', { color: c }),
    color: 'success',
    title: formatGoalEvent,
  },

  GoalRemovedEvent: {
    asset: (e, c) => formatIcon('REMOVE', { color: c }),
    title: formatGoalEvent,
  },

  GoalResumedEvent: {
    asset: (e, c) => formatIcon('PLAY', { color: c }),
    color: 'success',
    title: 'Contributions resumed',
    subtitle: formatGoalForGenericContext,
  },

  GoalPausedEvent: {
    asset: (e, c) => formatIcon('PAUSE', { color: c }),
    title: 'Contributions paused',
    subtitle: formatGoalForGenericContext,
  },

  HealthApplicationEvent: {
    asset: (e, c) => formatIcon('SHIELD', { color: c }),
    color: formatHealthColor,
    title: formatHealthEvent,
  },

  HealthEnrollmentEvent: {
    asset: (e, c) => formatIcon('SHIELD', { color: c }),
    color: formatHealthColor,
    title: formatHealthEvent,
  },

  DentalEnrollmentEvent: {
    asset: (_, c) => formatIcon('SHIELD', { color: c }),
    color: formatHealthColor,
    title: formatDentalEvent,
  },

  DividendReceivedEvent: {
    asset: (e, c) => formatIcon('CHART', { color: c }),
    color: 'debit',
    title: 'Dividends earned',
    subtitle: 'from Retirement',
  },

  RolloverEvent: {
    asset: (e, c) => formatIcon('DEPOSIT', { color: c }),
    color: 'debit',
    title: 'Rollover',
    subtitle: 'to Retirement',
  },

  StatementAvailableEvent: {
    asset: (e, c) => formatIcon('DOCUMENT', { color: c }),
    title: 'New statement available',
    action: (e, actions) => actions?.downloadDocument,
  },

  UnknownEvent: {
    title: formatUnknownEvent,
    subtitle: (event) => capitalize(normalizeType(event)),
  },
};

/**
 * Uses the event to lookup the event type above
 * - First, look for a specific one like TransferEvent_INCOME
 * - Then, fallback to something like TransferEvent
 * - Otherwise, use the UnknownEvent
 */
export const lookupEvent = (event) =>
  ACTIVITY_EVENT_TYPES[`${event.__typename}_${event.type}`] ||
  ACTIVITY_EVENT_TYPES[event.__typename];

/**
 * Helper function that checks type of the value
 * - If a function, invoke it and pass the event
 * - If not a fn, return
 * - If undefined, then just return a specified fallback
 */
const invoke = (val, event, second = '', fallback) => {
  const value = typeof val === 'function' ? val(event, second) : val;
  return value || fallback;
};

/**
 * @todo refactor this a little
 * Given the event and possible actions (for mapping)
 */
export const getEventProperties = (event = {}, actions = {}, options = {}) => {
  const lookup = lookupEvent(event);
  if (!lookup || typeof lookup === 'undefined') return null;

  const { showContributed } = options;

  const title = invoke(lookup?.title, event);
  const subtitle = invoke(lookup?.subtitle, event, showContributed);
  const color = invoke(lookup?.color, event, '', 'text');
  const amount = invoke(lookup?.amount, event, '', event?.amount);
  const totalAmount = invoke(lookup?.totalAmount, event, '', event?.amount);
  const asset = invoke(lookup?.asset, event, color);
  const onPress = invoke(lookup?.action, event, actions, null);

  // receipt-specific
  const receiptTitle = invoke(lookup?.receiptTitle, event) || title; // fall back  to the other
  const status = invoke(lookup?.status, event, '', formatStatus(event));
  const dateLabel = invoke(lookup?.dateLabel, event);
  const from = invoke(lookup?.from, event);
  const to = invoke(lookup?.to, event);
  const returnCode = event?.transfer?.returnCode || event?.returnCode;

  const formattedAmount = amount ? formatAmount(amount, color) : '';

  return {
    loading: false,
    asset: { ...asset, bg: options?.accentColor + 'Color' || color + 'LightColor' },
    color,
    title: [formattedAmount, title].filter(identity).join(' '),
    subtitle,
    onPress,
    amount: formattedAmount,
    totalAmount: formatAmount(totalAmount, color),
    time: formatDate(event),
    receiptTitle,
    status: capitalize(status),
    dateLabel,
    from,
    to,
    returnCode,
  };
};
