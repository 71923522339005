import { UIAccentColor } from '@app/_ui-kit/types';
import { LayoutContext } from '@app/layouts';
import { Presentation } from '@app/styles';
import * as RadixDialog from '@radix-ui/react-dialog';
import styled from 'styled-components';

export interface DialogProps {
  isOpen: boolean;
  onRequestClose: () => void;
  preventClose?: boolean;
  presentation?: Presentation;
  title?: string;
  close?: boolean;
  accentColor?: UIAccentColor;
  children: React.ReactNode;
}

const Overlay = styled(RadixDialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  opacity: 1;
  animation: fadeIn 200ms ease;
`;

const Content = styled(RadixDialog.Content)<{ $presentation: Presentation }>`
  padding: 0px;
  border-radius: 24px;
  backdrop-filter: blur(50px);
  background: ${({ theme }) => theme.colors.bgSheet};
  width: ${({ theme, $presentation }) => theme.containers[$presentation]}px;
  z-index: 10000;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: dialogIn 400ms cubic-bezier(0.13, 1.34, 0.24, 0.97);

  max-height: 360px;
  overflow-y: scroll;
`;

export function Dialog({
  isOpen,
  onRequestClose,
  preventClose,
  presentation = Presentation.actionSheet,
  accentColor,
  children,
}: DialogProps) {
  const handleChange = (open: boolean) => {
    if (!open) onRequestClose();
  };

  const handleInteraction = (e) => {
    if (preventClose) {
      e.preventDefault();
    }
  };

  return (
    <RadixDialog.Root defaultOpen={isOpen} open={isOpen} onOpenChange={handleChange}>
      <RadixDialog.Portal>
        <Overlay />
        <Content $presentation={presentation} onInteractOutside={handleInteraction}>
          <LayoutContext layout="sheet" accentColor={accentColor}>
            {children}
          </LayoutContext>
        </Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}
