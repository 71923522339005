import gql from 'graphql-tag';
import access from 'safe-access';
import { activity as fragments } from '../fragments';

const EVENTS = gql`
  query Events(
    $page: Pagination!
    $goalID: ID
    $sourceID: ID
    $enrollmentID: ID
    $eventTypes: [EventType!]
  ) {
    viewer {
      id
      activity(
        pagination: $page
        goalID: $goalID
        sourceID: $sourceID
        enrollmentID: $enrollmentID
        eventTypes: $eventTypes
      ) {
        edges {
          ... on GoalAddedEvent {
            id
            percentage
            time
            goal {
              id
              type: goalType
              slug
              name
            }
          }
          ... on GoalUpdatedEvent {
            id
            percentage
            time
            goal {
              id
              slug
              ...ActivityGoal
            }
          }
          ... on GoalRemovedEvent {
            id
            time
            goal {
              id
              type: goalType
              slug
              name
            }
          }
          ... on GoalMetEvent {
            id
            time
            goal {
              id
              type: goalType
              slug
              name
            }
          }
          ... on GoalPausedEvent {
            id
            time
            goal {
              id
              type: goalType
              slug
              name
            }
          }
          ... on GoalResumedEvent {
            id
            time
            goal {
              id
              type: goalType
              slug
              name
            }
          }
          ... on RolloverEvent {
            id
            time
            amount
          }
          ... on StatementAvailableEvent {
            id
            time
            statementType: type
          }
          ... on DividendReceivedEvent {
            id
            time
            amount
          }
          ... on AutopilotRuleAddedEvent {
            id
            time
            automationType
            source {
              id
              ...ActivitySource
            }
          }
          ... on AutopilotRuleResumedEvent {
            id
            time
            automationType
            source {
              id
              ...ActivitySource
            }
          }
          ... on AutopilotRulePausedEvent {
            id
            time
            automationType
            source {
              id
              ...ActivitySource
            }
          }
          ... on AutopilotRuleRemovedEvent {
            id
            time
            automationType
            source {
              id
              ...ActivitySource
            }
          }
          ... on HealthApplicationEvent {
            id
            time
            type
            applicationType: type
          }
          ... on HealthEnrollmentEvent {
            id
            time
            enrollmentType: type
          }
          ... on DentalEnrollmentEvent {
            id
            time
            enrollmentType: type
          }
          ... on PaymentEvent {
            id
            time
            amount
            paymentStatus: status
            payeeName
            goalType
          }
          ... on CatchFeePaymentEvent {
            id
            time
            feeType
            amount
            feeStatus: status
            account: aggregationAccount {
              id
              ...ActivityAccount
              bankLink {
                id
                providerType
                bankName
              }
            }
            goal {
              id
              slug
              ...ActivityGoal
            }
          }
          ... on TransferEvent {
            id
            time
            description
            transferType: type
            amount
            incomeAmount
            transferStatus: status
            isAutopilot
            bankName
            accountName
            accountNumber
            transfer {
              id
              returnCode
            }
            source {
              id
              ...ActivitySource
            }
            transactionRecords {
              id
              amount
              goal {
                id
                type: goalType
                slug
                name
              }
            }
          }
        }
        pageInfo {
          currentPageNumber
          hasNextPage
          hasPrevPage
          totalItems
        }
      }
    }
  }
  ${fragments.ActivityGoal}
  ${fragments.ActivitySource}
  ${fragments.ActivityAccount}
`;

const formatter = (data, options) => {
  const get = access(data);

  const events = (get('viewer.activity.edges') || []).map((event) => ({
    ...event,
    type:
      event?.transferType ||
      event?.feeType ||
      event?.statementType ||
      event?.enrollmentType ||
      event?.applicationType,

    status: event?.transferStatus || event?.paymentStatus || event?.feeStatus,

    // provides the context that this was used in
    context: { goalID: options?.variables?.goalID },

    // automatically sorts transaction records from highest to lowest amount
    transactionRecords: event?.transactionRecords
      ? event?.transactionRecords.sort((a, b) => (a.amount > b.amount ? -1 : 1))
      : undefined,
  }));

  return {
    events,
    pageInfo: get('viewer.activity.pageInfo'),
  };
};

export default {
  document: EVENTS,
  formatter,
};
