import React, { useMemo } from 'react';
import { ScreenDefinition, pop } from '@navigate';
import { Route } from '@types';
import { useQuery, ExplorerEligibilityQuery } from '@app/data';
import { Button, ComplexRow, Loading } from '@app/_ui-kit';
import { BasicLayout, Card, Stack, Toolbar } from '@app/layouts';
import { useCopy } from '@app/utils';
import { EligibilityCallout, EligibilityScenario, getEligibility } from '../utils/getEligibility';

import ShieldTick from '@svg/shield-tick.svg';
import ShieldWarning from '@svg/shield-warning.svg';
import Shield from '@svg/shield.svg';
import Check from '@svg/checkmark.svg';
import Plus from '@svg/plus.svg';

const copy: Record<EligibilityScenario, { icon: any; title: string; subtitles?: Array<string> }> = {
  [EligibilityScenario.Savings]: {
    icon: ShieldTick,
    title: 'savings.eligible.title',
  },
  [EligibilityScenario.Enroll]: {
    icon: Shield,
    title: 'savings.none.title',
    subtitles: ["We'll still help you find the most affordable, effective coverage out there"],
  },
  [EligibilityScenario.Medicare]: {
    icon: Shield,
    title: 'savings.medicare.title',
    subtitles: [
      'Medicare provides free or affordable coverage to people over the age of 65 and other qualifying individuals.',
      'Learn more at medicare.gov',
    ],
  },
  [EligibilityScenario.Medicaid]: {
    icon: Shield,
    title: 'savings.medicaid.title',
    subtitles: [
      'Plans will be listed at full price. You can take a look, but your household may qualify for lower priced plans through Medicaid.',
    ],
  },
  [EligibilityScenario.NoPlans]: {
    icon: ShieldWarning,
    title: 'savings.noPlans.title',
    subtitles: ["We couldn't find any plans for your household at the moment."],
  },
};

const callouts: Record<EligibilityCallout, { icon: any; title: string; subtitle?: string }> = {
  [EligibilityCallout.CSR]: {
    icon: Plus,
    title: 'Save more with Silver',
    subtitle: 'Choose a silver plan to save on out-of-pocket costs',
  },
  [EligibilityCallout.StateSubsidies]: {
    icon: Plus,
    title: 'Your household may qualify for additional savings',
    subtitle:
      'States may offer additional discounts, subsidies, or alternative plans and your household may qualify for health insurance at a lower cost.',
  },
  [EligibilityCallout.CHIP]: {
    icon: Check,
    title: 'Your children may qualify for CHIP',
    subtitle:
      "CHIP provides free or affordable coverage to children based on household income. If you apply for coverage, we'll submit their info to your state to determine final eligibility.",
  },
  [EligibilityCallout.Medicaid]: {
    icon: Check,
    title: 'Someone may qualify for Medicaid',
  },
  [EligibilityCallout.MedicareSelf]: {
    icon: Check,
    title: 'You may qualify for Medicare',
  },
  [EligibilityCallout.MedicareSpouse]: {
    icon: Check,
    title: 'Your spouse qualify for Medicare',
  },
};

const ExplorerSavings = ({ handleNext }) => {
  const { loading, data } = useQuery(ExplorerEligibilityQuery, {
    fetchPolicy: 'network-only',
  });

  const { c, $ } = useCopy('catch');

  const eligibility = useMemo(() => {
    return data ? getEligibility(data) : undefined;
  }, [data]);

  if (loading || !eligibility) {
    return <Loading status="loading" titles={{ loading: 'Checking your eligibility' }} />;
  }

  return (
    <BasicLayout
      loading={loading}
      icon={{ svg: copy[eligibility?.scenario].icon, gradient: 'coverage' }}
      title={c(copy[eligibility?.scenario].title, {
        amount: $(eligibility?.savings, { whole: true }),
      })}
      subtitles={copy[eligibility?.scenario].subtitles || []}
      toolbar={
        <Toolbar type="flow" onBack={pop}>
          <Button testID="next" inherit onPress={handleNext}>
            Next
          </Button>
        </Toolbar>
      }
    >
      <Stack>
        <Card padded={false}>
          {eligibility?.callouts?.map((callout) => (
            <ComplexRow
              key={callout}
              inset
              asset={{ svg: callouts[callout].icon }}
              label={callouts[callout].title}
              sublabel={callouts[callout].subtitle}
            />
          ))}
        </Card>
      </Stack>
    </BasicLayout>
  );
};

export const ExplorerSavingsView: ScreenDefinition = {
  name: Route.EXPLORER_SAVINGS,
  component: ExplorerSavings,
  options: {
    title: 'Savings',
  },
};
