import React from 'react';
import { LabelText } from '@common';
import { useQuery, UserQuery } from '@data';
import { open } from '@navigate';
import { TouchableField, Address } from '@uikit';
import { formatDOB, STATES, useCopy } from '@app/utils';
import { SettingsLayout } from '@common';
import { Section, Stack } from '@app/layouts';
import { Route } from '@types';

const PREFIX = 'catch.module.me.UserDetailsView';

const UserInfo = () => {
  const { c, $ } = useCopy(PREFIX);
  const { c: filingStatusCopy } = useCopy('catch.util.format.filingStatuses');
  const WORK_TYPE_COPY = {
    WORK_TYPE_W2: c('workType.w2'),
    WORK_TYPE_1099: c('workType.1099'),
    WORK_TYPE_DIVERSIFIED: c('workType.mixed'),
  };

  const { loading, data } = useQuery(UserQuery, {
    fetchPolicy: 'cache-first',
  });

  const {
    nickname,
    dob,
    legalName,
    legalAddress,
    jobTitle,
    workType,
    workState,
    filingStatus,
    estimatedIncome,
    spouseIncome,
    dba,
    ein,
    ssn,
  } = data?.viewer?.user || {};

  const toggleUpdate = (field) => open(Route.EDIT_USER_DETAILS, { field });

  return (
    <>
      <SettingsLayout title="Info">
        <Stack mobileGap>
          <Section offset title={c('legalInfo.title')}>
            <Stack separatorComponent>
              <TouchableField
                disabled
                link={!nickname}
                value={nickname || c('addInfo')}
                label={<LabelText fieldName="PreferredNameField" />}
                onPress={() => toggleUpdate('NICKNAME')}
                loading={loading}
              />
              <TouchableField
                disabled
                link={!legalName}
                value={legalName || c('addInfo')}
                label={<LabelText fieldName="LegalNameField" />}
                onPress={() => toggleUpdate('LEGAL_NAME')}
                loading={loading}
              />
              {!!ssn && (
                <TouchableField
                  disabled
                  link={!ssn}
                  value={`••• - •• - ${ssn}`}
                  label={<LabelText fieldName="SSNField" />}
                  onPress={() => {}}
                  loading={loading}
                />
              )}
              <TouchableField
                disabled={true || kycStatus === 'KYC_GOOD'}
                link={!dob}
                value={dob ? formatDOB(dob) : c('addInfo')}
                label={<LabelText fieldName="DobField" />}
                onPress={() => toggleUpdate('DOB')}
                loading={loading}
              />

              <TouchableField
                link={!legalAddress?.street1}
                value={!legalAddress?.street1 && c('addInfo')}
                label={<LabelText fieldName="LegalAddressField" />}
                onPress={() => toggleUpdate('LEGAL_ADDRESS')}
                loading={loading}
              >
                {legalAddress?.street1 && (
                  <Address textProps={{ weight: 'medium' }} {...legalAddress} />
                )}
              </TouchableField>
            </Stack>
          </Section>
          <Section offset title={c('workInfo.title')} divider>
            <Stack separatorComponent>
              <TouchableField
                disabled
                link={!jobTitle}
                value={jobTitle || c('addInfo')}
                label={<LabelText fieldName="JobTitleField" />}
                onPress={() => toggleUpdate('JOB_TITLE')}
                loading={loading}
              />
              <TouchableField
                disabled
                link={!WORK_TYPE_COPY[workType]}
                value={WORK_TYPE_COPY[workType] || c('addInfo')}
                label={c('workType.label')}
                onPress={() => toggleUpdate('EMPLOYMENT_TYPE')}
                loading={loading}
              />
              <TouchableField
                disabled
                link={!STATES[workState]}
                value={STATES[workState] || c('addInfo')}
                label={<LabelText fieldName="WorkStateField" />}
                onPress={() => toggleUpdate('WORK_STATE')}
                loading={loading}
              />
              <TouchableField
                disabled
                link={!filingStatus}
                value={filingStatusCopy(filingStatus) || c('addInfo')}
                label={<LabelText fieldName="FilingStatusField" />}
                onPress={() => toggleUpdate('FILING_STATUS')}
                loading={loading}
              />
              <TouchableField
                disabled
                link={!estimatedIncome}
                value={estimatedIncome ? $(estimatedIncome) : c('addInfo')}
                label={<LabelText fieldName="UserIncomeField" />}
                onPress={() => toggleUpdate('ESTIMATED_INCOME')}
                loading={loading}
              />
              {filingStatus === 'MARRIED' && (
                <TouchableField
                  disabled
                  link={!spouseIncome}
                  value={spouseIncome ? $(spouseIncome) : c('addInfo')}
                  label={<LabelText fieldName="SpouseIncomeField" />}
                  onPress={() => toggleUpdate('SPOUSE_INCOME')}
                  loading={loading}
                />
              )}
              <TouchableField
                disabled
                link={!dba && !ein}
                label={'Business info'}
                value={dba || ein || c('addInfo')}
                onPress={() => toggleUpdate('BUSINESS')}
                loading={loading}
              />
            </Stack>
          </Section>
        </Stack>
      </SettingsLayout>
    </>
  );
};

export const UserInfoView = {
  name: 'ACCOUNT_INFO',
  component: UserInfo,
  options: {
    title: 'My Info',
    largeTitle: true,
  },
};
