import React, { useState } from 'react';
import { FieldConfig, Route } from '@types';
import { AuthBlueprint } from '@app/blueprints';
import { getFullName, safeFormatDate } from '@app/utils';
import { Button, popToast, ToastType } from '@app/_ui-kit';
import { Navigate, useSearchParams } from 'react-router-dom';
import { paths } from '@app/navigate';
import {
  ConsentStatus,
  ConsentType,
  GetConsentRequest,
  ResendConsentRequest,
  SignConsentRequest,
  usePublicMutation,
  usePublicQuery,
} from '@app/data';
import { RegistrationDisclosures } from '@app/_common';
import Checkmark from '@svg/checkmark.svg';
import Clock from '@svg/clock.svg';

const fields: Record<ConsentType, Array<FieldConfig>> = {
  [ConsentType.Broker]: [
    {
      type: 'checkbox',
      name: 'consent',
      dependencies: ['givenName', 'familyName'],
      required: true,
      scroll: 345,
      plain: true,
      label: ({ givenName, familyName }) => {
        const fullName = getFullName({ givenName, familyName });

        return {
          id: 'ede.HealthEnrollment.BROKER_CONSENT',
          data: {
            legalName: fullName ? `, ${fullName},` : '',
            date: safeFormatDate(new Date(), 'MMMM d, yyyy'),
          },
        };
      },
    },
  ],
};

const ConsentComponent = () => {
  // get token from url params
  const [params] = useSearchParams();
  const [token] = useState(params.get('token') || '');

  const { loading, data } = usePublicQuery(GetConsentRequest, {
    variables: { token },
    skip: !token,
  });

  // add query to lookup consent request (these are hardcoded for now)
  const { givenName, familyName, status, consentType } = data?.consentRequest || {};

  const [sign, { loading: signing }] = usePublicMutation(SignConsentRequest);
  const [resend, { loading: resending }] = usePublicMutation(ResendConsentRequest, {
    onCompleted: () => popToast({ type: ToastType.important, title: 'Check your email' }),
  });

  const handleSubmit = ({ consent }) => {
    sign({
      variables: {
        input: {
          token,
          agreed: !!consent,
        },
      },
    });
  };

  const handleResend = () => {
    const input = { token };
    resend({ variables: { input } });
  };

  // if no token or no response, redirect somewhere
  if (!token || (!loading && !data?.consentRequest)) {
    return <Navigate to={paths.LOGIN} />;
  }

  if (status === ConsentStatus.Ready) {
    return (
      <AuthBlueprint
        loading={loading}
        submitting={signing}
        title="Opt-in to working with Catch"
        subtitle="Sign your broker consent"
        actions={({ handleSubmit, disabled }) => [
          <Button key="submit" testID="sign-consent" onPress={handleSubmit} disabled={disabled}>
            Submit
          </Button>,
        ]}
        formConfig={{
          initialValues: {
            givenName,
            familyName,
          },
          onSubmit: handleSubmit,
          fields: consentType ? fields[consentType] : [],
        }}
        postContent={<RegistrationDisclosures />}
      />
    );
  }

  if (status === ConsentStatus.Expired) {
    return (
      <AuthBlueprint
        submitting={resending}
        icon={{ svg: Clock, gradient: 'brand' }}
        title="Your broker consent link has expired"
        subtitle="Click the button below and we'll email you a new link        "
        actions={() => [
          <Button key="resend-consent" testID="resend-consent" onPress={handleResend}>
            Send me a new link
          </Button>,
        ]}
      />
    );
  }

  if (status === ConsentStatus.Agreed) {
    return (
      <AuthBlueprint
        icon={{ svg: Checkmark, gradient: 'brand' }}
        title="You're all set!"
        subtitle="We'll be in touch"
        actions={() => []}
      />
    );
  }

  return null;
};

export const ConsentView = {
  name: Route.CONSENT,
  component: ConsentComponent,
  guest: true,
  options: {},
};
