import React, { useMemo, useState, useCallback } from 'react';
import { View } from 'react-native';
import { Copy, FieldConfig } from '@types';
import { Fields, useForm } from '@f';
import { Loading, Button, Text } from '@uikit';
import { pop } from '@navigate';
import { HelpTextInline } from '@app/_ui-kit/components/HelpText';
import { useCopy } from '@app/utils/language';

import { default as SplitLayout } from '@app/layouts/SplitLayout';
import { default as Layout } from '@app/layouts/Layout';
import { default as Toolbar } from '@app/layouts/Toolbar';
import { default as Card } from '@app/layouts/Card';

interface Member {}

export interface Question {
  name: string;
  title: Copy;
  label: Copy;
  sublabel: Copy;
  show: (data: object) => boolean;
  filterMembers: (members: Array<Member>) => Array<Member>;
  field: FieldConfig;
}

interface MultiQuestionSplitFormBlueprintProps<TFormValues> {
  loading: boolean; // shows a loader during initial load
  submitting: boolean; // while submitting the form, disable all buttons and inputs
  questions: Array<Question>;
  members: Array<Member>;
  data?: object; // data to share with all initial values
  getInitialValues: (member: Member) => TFormValues;
  onNext: (values: TFormValues, currentQuestion: Question) => void;
  onBack: () => void;
  onComplete: () => void;
}

const BigQuestion = ({ children, values }) => {
  const [toggle, ...subfields] = children;

  const toggleName = toggle?.props?.subfield?.name;

  const isToggled = useMemo(() => {
    return !!values[toggleName];
  }, [toggleName, values]);

  return (
    <View style={{ paddingBottom: 32 }}>
      <Card shadow={false} checked={isToggled} padded={false}>
        {toggle}
        <View style={isToggled ? { padding: 16 } : undefined}>{subfields}</View>
      </Card>
    </View>
  );
};

export const MultiQuestionSplitFormBlueprint = <
  TFormValues extends Record<string, any> = Record<string, any>,
>({
  loading, // for initial load
  submitting, // for example when submitting the form
  questions,
  members,
  data = {},
  getInitialValues,
  onNext,
  onBack,
  onComplete,
}: MultiQuestionSplitFormBlueprintProps<TFormValues>) => {
  const filteredQuestions = useMemo(() => {
    return questions.filter((question) => {
      return !!question.show ? question.show(data) : true;
    });
  }, [data, questions]);

  // keeps track of current index/question
  const [currentIndex, setIndex] = useState(0);

  const currentQuestion = useMemo(() => {
    return filteredQuestions[currentIndex];
  }, [currentIndex, filteredQuestions]);

  // handles copy for main big questions
  const { c } = useCopy(currentQuestion?.copy || 'catch');

  const { title, label, sublabel, help } = useMemo(() => {
    return {
      title: c(currentQuestion?.title?.id, currentQuestion?.title?.data),
      label: c(currentQuestion?.label?.id, currentQuestion?.label?.data),
      sublabel: c(currentQuestion?.sublabel?.id, currentQuestion?.sublabel?.data),
      help: c(currentQuestion?.help?.id, currentQuestion?.help?.data),
    };
  }, [currentQuestion]);

  // computes the initial values for all members
  const initialValues = useMemo(() => {
    const filtered = currentQuestion?.filterMembers
      ? members.filter(currentQuestion?.filterMembers)
      : members;

    return {
      ...data,
      members: filtered?.map((member) => getInitialValues(member)),
    };
  }, [members, getInitialValues, currentQuestion]);

  const fields = useMemo(() => {
    if (!currentQuestion) return [];

    return [
      {
        Component: BigQuestion,
        Footer: () => null,
        ...currentQuestion?.field,
      },
    ];
  }, [currentQuestion]);

  const next = useCallback(
    async (values) => {
      if (currentIndex < filteredQuestions.length - 1) {
        onNext(values, currentQuestion);
        setIndex(currentIndex + 1);
      } else {
        await onNext(values, currentQuestion);
        onComplete();
      }
    },
    [setIndex, currentIndex, filteredQuestions],
  );

  const form = useForm<TFormValues>({
    loading,
    disabled: submitting,
    fields,
    initialValues,
    onSubmit: next,
  });

  return (
    <SplitLayout
      gradient="coverageLight"
      handleContent="HIDDEN"
      toolbar={
        <Toolbar
          onBack={() => {
            if (currentIndex === 0) {
              if (onBack) {
                onBack();
              } else {
                pop();
              }
            } else {
              setIndex((prev) => prev - 1);
            }
          }}
        >
          <Button inherit testID="next" onPress={form.submitForm} disabled={form.disableSubmit}>
            Next
          </Button>
        </Toolbar>
      }
    >
      <>
        <Layout.Header
          title={title}
          subtitles={[
            label,
            <Text key={sublabel} size="fp" color="subtle">
              {sublabel}
            </Text>,
          ]}
          titleSize="form"
          bottomSpace
        />
        {loading ? <Loading /> : <Fields form={form} fields={fields} />}
      </>

      {form.currentHelpText ? (
        <HelpTextInline content={form.currentHelpText} offset={form?.layout?.y} />
      ) : (
        help && <HelpTextInline content={help} offset={form?.layout?.y / 2} />
      )}
    </SplitLayout>
  );
};

MultiQuestionSplitFormBlueprint.options = SplitLayout.options;
export default MultiQuestionSplitFormBlueprint;
