import { paths, Route } from '../paths';
import { matchRoutes } from 'react-router';

export const getRouteFromURL = (location: Location): Route | '' | undefined => {
  if (!location) return '';

  const routeObject = Object.values(paths).map((path) => {
    return { path };
  });

  const match = matchRoutes(routeObject, location)?.[0] || undefined;

  return Object.keys(paths).find((key) => paths[key] === match?.route?.path);
};
