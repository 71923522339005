import { currentYear } from '../time';

// Index 0 of each list equals amount for each additional person after 8
const guidelines = {
  2024: {
    HI: [6190, 17310, 23500, 29690, 35880, 42070, 48260, 54450, 60640],
    AK: [6730, 18810, 25540, 32270, 39000, 45730, 52460, 59190, 65920],
    default: [5380, 15060, 20440, 25820, 31200, 36580, 41960, 47340, 52720],
  },
  2023: {
    HI: [5910, 16770, 22680, 28590, 34500, 40410, 46320, 52230, 58140],
    AK: [6430, 18210, 24640, 31070, 37500, 43930, 50360, 56790, 63220],
    default: [5140, 14580, 19720, 24860, 30000, 35140, 40280, 45420, 50560],
  },
};

export const calcPovertyLine = ({
  coverageYear = currentYear,
  maritalStatus,
  numTaxDependents,
  state,
}) => {
  const totalHousehold = (maritalStatus === 'MARRIED' ? 2 : 1) + numTaxDependents;

  const guides = guidelines[coverageYear - 1][state] || guidelines[coverageYear - 1].default;

  const povertyLine =
    totalHousehold > 8 ? guides[8] + (totalHousehold - 8) * guides[0] : guides[totalHousehold];

  const fpls420 = Math.round((povertyLine * 4.2) / 1000) * 1000;

  return {
    povertyLine,
    fpls420,
  };
};
