import gql from 'graphql-tag';

const VERIFY_PHONE = gql`
  mutation _VerifyPhone($input: VerifyPhoneInput!) {
    verifyPhone(input: $input) {
      error
      personId
    }
  }
`;

export default {
  document: VERIFY_PHONE,
};
