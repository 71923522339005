import gql from 'graphql-tag';
import { SCORED_HEALTH_PLAN, TOP_PLANS } from './healthPlans';

const EnrollmentGroup = gql`
  fragment _EnrollmentGroup on EnrollmentGroup {
    id
    enrollmentType
    selectedProviderPlanID
    planPremium
    planPremiumEffective
    members {
      id
      givenName
      familyName
      enrollmentGroupID
    }
    sortedPlans {
      plans {
        planID: id
        ...ScoredHealthPlan
      }
      topPlans {
        ...TopPlans
      }
    }
  }
  ${SCORED_HEALTH_PLAN}
  ${TOP_PLANS}
`;

const ApplicationExchangeMembers = gql`
  fragment ApplicationExchangeMembers on HealthApplication {
    id
    exchangeEligibleMembers {
      id
      givenName
      familyName
      enrollmentGroupID
    }
    __typename
  }
`;

export default {
  EnrollmentGroup,
  ApplicationExchangeMembers,
};
