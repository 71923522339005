import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useDropzone } from 'react-dropzone';
import { Layout } from '@layouts';
import { DocFileType } from '@app/data';
import { UploadProps } from '@app/client/useUpload';
import UploadIcon from '@svg/upload.svg';
import Loading from '../Loading';
import Text from '../Text';
import { generateAcceptedFiles } from './fileTypes';

type DropzoneDisplay = 'avatar';

const StyledContainer = styled.div<{ display?: DropzoneDisplay }>`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  min-height: 200px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgGray};

  ${(p) =>
    p.display === 'avatar' &&
    `
    height: 250px;
    width: 250px;
  `}

  transition: all 300ms ease-in-out;
  &:hover {
    transition: all 300ms ease-in-out;
    opacity: 0.7;
  }
`;

const StyledPreview = styled.img<{ src: string; loading: boolean }>`
  position: absolute;
  height: auto;
  width: 100%;
  opacity: ${(p) => (p.loading ? 0.1 : 1)};
`;

export interface DropzoneProps {
  name: string;
  label: string;
  sublabel?: string;
  disabled?: boolean;
  acceptedTypes?: Array<DocFileType>;
  display?: DropzoneDisplay;
  onUpload?: (props: UploadProps) => void;
  onChange?: (file: File) => void;
  svg?: string; // note: ideally, this would be a specific type
}

export const Dropzone = ({
  name,
  label,
  sublabel,
  disabled,
  acceptedTypes = Object.values(DocFileType),
  display,
  onUpload,
  onChange,
  svg = UploadIcon,
}: DropzoneProps) => {
  // sets a URL to show the preview
  const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const accept = useMemo(() => generateAcceptedFiles(acceptedTypes), [acceptedTypes]);

  const onDrop = useCallback(
    (files: Array<File>) => {
      setPreview(window.URL.createObjectURL(files[0]));

      // Allow a single file for now
      if (onUpload) {
        onUpload({
          name,
          file: files[0],
          options: {
            onStart: () => {
              setLoading(true);
            },
            onError: (e: string) => {
              setLoading(false);
              setError(e);
            },
            onCompleted: (data) => {
              setLoading(false);
            },
          },
        });
      } else {
        onChange?.(files[0]);
      }
    },
    [name],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    disabled: loading || disabled,
  });

  return (
    <StyledContainer {...getRootProps()} display={display} preview={preview}>
      {preview ? (
        <>
          <StyledPreview loading={loading || disabled} src={preview} />
          {loading && <Loading status="loading" titles={{ loading: 'Uploading...' }} />}
        </>
      ) : (
        <>
          <input {...getInputProps()} />
          <Layout.Header margins light align="center" svg={svg} title={label} subtitle={sublabel} />
        </>
      )}
      {error && <Text>{error}</Text>}
    </StyledContainer>
  );
};
