/** allows us to spread objects that are possibly undefined */
export const safeObject = (obj) => obj ?? {};

/**
 * @todo put this somewhere and build into ES at some point 😂
 */
export function groupBy(arr = [], property) {
  return arr?.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

/**
 *
 * assign a tax payment year, and choose the previous year for Q4 payments in Jan
 *
 * */
export const formatTaxPayment = (pmt) => {
  const year = new Date(pmt.createdOn).getFullYear();
  const month = new Date(pmt.createdOn).getMonth();
  const timeframe = pmt?.timeframe?.replace('undefined', '?');
  return {
    ...pmt,
    timeframe: timeframe,
    year: timeframe === 'Q4' && month === 0 ? year - 1 : year,
  };
};
