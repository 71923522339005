import React from 'react';
import styled from 'styled-components';
import Check from '@svg/checkmark.svg';
import { IconSvg } from '../Asset';
import Text from '../Text';
import HelpText from '../HelpText';

export interface CheckboxProps {
  testID: string;
  title?: string;
  label: string;
  value: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  help?: string;
  scroll?: number;
  bare?: boolean; // when set, show ONLY the checkbox (no labels, no card)
  plain?: boolean; // when set, show checkbox + label (but no card styling)
  children?: React.ReactNode;
}

const Label = styled.label<{
  $start: boolean;
  $checked: boolean;
  $option: boolean;
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  line-height: 24px;

  ${({ theme, $option, $checked }) =>
    $option &&
    `
    background-color: red;
    border: 2px solid;
    border-radius: 12px;
    padding: 16px;
    background-color: ${$checked ? theme.colors.bg : theme.colors.bg3};
    border-color: ${$checked ? theme.colors.fg3 : 'transparent'};
    box-shadow: ${$checked ? '0 2px 6px 0px #0000001A' : ''};
  `}

  ${({ $disabled }) =>
    $disabled
      ? `
    opacity: 0.3;
  `
      : `
    &:hover {
      cursor: pointer;
    }
  `}
`;

const Container = styled.div<{ $disabled: boolean }>`
  display: inline-block;
  vertical-align: middle;
  ${({ $disabled }) => $disabled && 'opacity: 0.5;'}
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ $checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1.5px solid ${({ theme }) => theme.colors.fg2};
  transition: all 150ms;

  ${({ theme, $checked }) =>
    $checked &&
    `
    background-color: ${theme.colors.fg};
    border-color: ${theme.colors.fg};
  `}

  ${HiddenCheckbox}:focus + & {
    box-shadow:
      0 0 0 2px ${({ theme }) => theme.colors.bg},
      0 0 0 4px ${({ theme }) => theme.colors.fg};
  }

  ${HiddenCheckbox}:hover + & {
    border-color: ${({ theme }) => theme.colors.fg2};
    cursor: pointer;
  }

  svg {
    path {
      stroke-width: 2px;
    }
  }
`;

const Content = styled.div<{ $scroll: number }>`
  display: flex;
  flex-direction: column;

  ${({ $scroll, theme }) =>
    $scroll &&
    `
    max-height: ${$scroll}px;
    overflow-y: scroll;
    padding-bottom: 36px;

    &&::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background: linear-gradient(to bottom, transparent, ${theme.colors.fade});
      pointer-events: none;
    }
  `}
`;

const Relative = styled.div`
  padding-left: 12px;
  position: relative;
  height: 100%;
`;

const Tooltip = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export function Checkbox({
  testID,
  title,
  label,
  value,
  help,
  onChange,
  scroll,
  bare,
  plain,
  disabled,
  children,
}: CheckboxProps) {
  return (
    <Label
      $start={!!scroll}
      $checked={!!value}
      $disabled={disabled}
      $option={!bare && !plain}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Container>
        <HiddenCheckbox
          data-testid={testID}
          id={testID}
          checked={!!value}
          disabled={disabled}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.checked);
            }
          }}
          onKeyPress={({ key }) => {
            if (key === 'Enter' && onChange) {
              onChange(!value);
            }
          }}
        />
        <StyledCheckbox $checked={!!value}>
          {!!value && <IconSvg color="bg" size="xs" svgComponent={Check} />}
        </StyledCheckbox>
      </Container>
      {!bare && (
        <Relative>
          <Content $scroll={scroll}>
            {help && (
              <Tooltip>
                <HelpText content={help} />
              </Tooltip>
            )}

            <Text weight="medium">{title}</Text>
            {/* uses line height to properly center text vertically */}
            <Text size="fp" weight={title ? undefined : 'medium'} style={{ lineHeight: 24 }}>
              {label}
            </Text>

            {children}
          </Content>
        </Relative>
      )}
    </Label>
  );
}
