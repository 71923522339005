import { useEffect, useRef } from 'react';
import { create, GetState, SetState } from 'zustand';
import { navigate, replace, start } from '@navigate';
import * as Cognito from './Cognito';
import * as API from './userApi';
import { signApolloIn, signApolloOut } from './Apollo';
import { useHealthStore } from './useHealthStore';
import { useStore } from '@hooks/useEnrollId';
import { useSession } from '@hooks/useSession';
import { lowercase } from '@app/utils';
import { Route } from '@app/types';

export type AuthState =
  | 'SIGNED_IN'
  | 'SIGNED_OUT'
  | 'PENDING_CONFIRMATION'
  | 'PENDING_NEW_PASSWORD';

export type AuthError =
  | 'USER_NOT_CONFIRMED'
  | 'USER_NOT_FOUND'
  | 'USER_NOT_CREATED'
  | 'USER_LAMBDA_VALIDATION'
  | 'NOT_AUTHORIZED'
  | 'CODE_MISMATCH'
  | 'INVALID_PARAMETER'
  | 'LIMIT_EXCEEDED'
  | 'USERNAME_EXISTS';

type AuthException =
  | 'UserNotConfirmedException'
  | 'UserNotFoundException'
  | 'NotAuthorizedException'
  | 'CodeMismatchException'
  | 'UsernameExistsException'
  | 'UserLambdaValidationException'
  | 'InvalidParameterException'
  | 'LimitExceededException'
  | 'GraphQL error: rpc error: code = NotFound desc = ';

const errors: Record<AuthError, AuthException> = {
  USER_NOT_CONFIRMED: 'UserNotConfirmedException',
  USER_NOT_FOUND: 'UserNotFoundException',
  USER_LAMBDA_VALIDATION: 'UserLambdaValidationException',
  NOT_AUTHORIZED: 'NotAuthorizedException',
  CODE_MISMATCH: 'CodeMismatchException',
  USERNAME_EXISTS: 'UsernameExistsException',
  INVALID_PARAMETER: 'InvalidParameterException',
  LIMIT_EXCEEDED: 'LimitExceededException',
  USER_NOT_CREATED: 'GraphQL error: rpc error: code = NotFound desc = ',
};

const states: Record<AuthState, AuthState> = {
  SIGNED_IN: 'SIGNED_IN', // We have a valid jwt to query the api
  SIGNED_OUT: 'SIGNED_OUT', // Email and password are required to sign in
  PENDING_CONFIRMATION: 'PENDING_CONFIRMATION', // Cognito user was created, email not confirmed
  PENDING_NEW_PASSWORD: 'PENDING_NEW_PASSWORD', // User in password reset flow
};

export interface Credentials {
  alias: string;
  password: string;
}

interface APIUser {
  id: string;
  email: string;
  phone: string;
  givenName: string;
}

type AuthStore = {
  // data
  loading: boolean;
  jwtToken?: string;
  cognitoAccessToken?: string;
  authState?: AuthState;
  states: Record<AuthState, AuthState>;
  created: boolean;
  user?: APIUser;
  lastAlias?: string;
  passwordChanged: boolean;
  temporaryCredentials: Credentials;
  error?: AuthError;
  attributes?: {
    signupParams?: object;
  };
  code: {
    sending: boolean;
    sent: boolean;
  };

  // api
  init: (initialAuthState?: AuthState) => void;
  getJWT: () => void;
  reset: () => void;
  signIn: (Credentials) => any;
  signUp: (SignUpParams) => void;
  signOut: () => void;
  createUser: (cognitoUser: any) => void;
  confirmCode: (Code) => void;
  resendCode: () => void;
  requestNewPassword: (credentials: Omit<Credentials, 'password'>) => void;
  changePassword: (passwords: { oldPassword: string; newPassword: string }) => void;
  confirmNewPassword: (Code) => void;

  initialRouteName?: 'GUEST' | 'MAIN_APP';
};

const useAuth = create<AuthStore>((set: SetState<AuthStore>, get: GetState<AuthStore>) => ({
  loading: false,
  jwtToken: undefined,
  states,
  authState: undefined,
  user: undefined,
  error: undefined,
  passwordChanged: false,
  created: false,
  temporaryCredentials: {
    alias: '',
    password: '',
  },
  code: {
    sending: false,
    sent: false,
  },
  initialRouteName: undefined,
  attributes: undefined,

  reset: () => {
    set({
      loading: false,
      jwtToken: undefined,
      authState: states.SIGNED_OUT,
      user: undefined,
      error: undefined,
      passwordChanged: false,
      initialRouteName: 'GUEST',
      temporaryCredentials: {
        alias: '',
        password: '',
      },
      code: {
        sending: false,
        sent: false,
      },
      attributes: undefined,
    });
  },

  // initializes the state of authentication
  init: async (initialAuthState) => {
    try {
      set({ loading: true });

      const jwtToken = await Cognito.getJWTToken();
      const cognitoAccessToken = await Cognito.getCognitoAccessToken();

      // if the user isn't auth'd no need to continue
      if (!jwtToken || !cognitoAccessToken) {
        get().reset();
        return;
      }

      // Hydrate the jwtToken and cognitoAccessToken from Cognito session
      set({
        jwtToken,
        cognitoAccessToken,
      });

      // If our user exists we can sign in
      set({
        user: await API.fetchUser(),
        loading: false,
        authState: states.SIGNED_IN,
        initialRouteName: 'MAIN_APP',
      });
    } catch (e) {
      get().reset();
    }
  },

  getJWT: () => {
    const currentJWT = get().jwtToken;

    // This promise cannot fail and will always fallback to currently saved token
    return new Promise(async (resolve) => {
      try {
        const newJWT = await Cognito.getJWTToken();
        if (newJWT && newJWT !== currentJWT) {
          // Only trigger a store update if the token has changed
          set({ jwtToken: newJWT });
          resolve(newJWT);
        } else {
          resolve(currentJWT);
        }
      } catch (e) {
        resolve(currentJWT);
      }
    });
  },

  getCognitoAccessToken: () => {
    const currentJWT = get().cognitoAccessToken;

    // This promise cannot fail and will always fallback to currently saved token
    return new Promise(async (resolve) => {
      try {
        const newJWT = await Cognito.getCognitoAccessToken();
        if (newJWT && newJWT !== currentJWT) {
          // Only trigger a store update if the token has changed
          set({ cognitoAccessToken: newJWT });
          resolve(newJWT);
        } else {
          resolve(currentJWT);
        }
      } catch (e) {
        resolve(currentJWT);
      }
    });
  },

  // signs an existing user in
  signIn: async (credentials) => {
    let cognitoUser;

    // normalizes alias (esp. email) so that we always match
    const alias = lowercase(credentials.alias);
    const password = credentials.password;

    try {
      set({ loading: true, error: undefined });

      // check credentials by looking up cognito user
      cognitoUser = await Cognito.signIn({ alias, password });
      const attributes = await Cognito.getUserAttributes(cognitoUser);

      // store the jwt token
      set({
        jwtToken: cognitoUser.signInUserSession.idToken.jwtToken,
        cognitoAccessToken: cognitoUser.signInUserSession.accessToken.jwtToken,
      });

      // We can fetch our actual platform user in order to check they do exist
      const user = await API.fetchUser();
      signApolloIn();

      // If the query didn't throw the user does exist and we can sign in
      // we can remove the pendingUser and keychain objects
      set({
        user,
        loading: false,
        authState: states.SIGNED_IN,
        lastAlias: alias,
        attributes,
      });

      // clear our extra stores
      useHealthStore.getState().reset();
      useStore.getState().reset();
    } catch (e) {
      switch (e?.code || e?.message) {
        case errors.USER_NOT_CONFIRMED:
          set({
            temporaryCredentials: {
              alias,
              password,
            },
            authState: states.PENDING_CONFIRMATION,
          });

          // user is not logged in yet, we can just push the register screen
          navigate(Route.CONFIRM_CODE);
          break;
        case errors.USER_NOT_CREATED:
          // If a user was accidentally not fully created we create it
          // on the fly and let the user in afterwards
          await get().createUser(cognitoUser);
          const attributes = await Cognito.getUserAttributes(cognitoUser);

          set({ authState: states.SIGNED_IN, attributes });
          break;
        // returns a sign in error so we can use this
        case errors.NOT_AUTHORIZED:
          set({ loading: false, error: 'NOT_AUTHORIZED' });
          return errors.NOT_AUTHORIZED;
        default:
          set({ loading: false, error: 'NOT_AUTHORIZED' });

          break;
      }
    } finally {
      set({ loading: false });
    }
  },

  // registers a user with cognito
  signUp: async ({
    alias: a,
    password,
    givenName,
    familyName,
    brokerConsent,
    dob,
    zip,
    signupCode,
    expressBenefit,
    signupParams: paramsObj,
    signupContext,
  }) => {
    // ensures alias is normalized so that we always match
    const alias = lowercase(a);

    // we shouldn't be using this for anything anymore
    // the only place now is to determine if it was healthexpress user (but that should be done differently)
    const userScope = expressBenefit === 'health-express' ? 'HEALTH' : 'PLATFORM';

    // add broker consent to signup params
    const signupParams = JSON.stringify({
      ...paramsObj,
      brokerConsent,
    });

    try {
      set({ loading: true, error: undefined });

      /**
       * Store the user attributes in cognito for reference
       *
       *
       * @warning
       * DO NOT ADD ANYTHING HERE THAT IS NOT A COGNITO CUSTOM ATTRIBUTE
       * SENDING PROPERTIES THAT ARE NOT CUSTOM ATTRIBUTES WILL CAUSE THE SIGNUP TO FAIL
       * ADD TO ALL 3 ENVIRONMENTS AND CHECK THAT YOU ARE USING THE CORRECT USER POOL
       *
       * Prod: us-****-*_*******M4
       * Stage: us-****-*_*******au
       * Dev: us-****-*_*******2p
       *
       * TESTS RARELY CATCH MISTAKES HERE; USERS DO
       *
       * */
      await Cognito.signUp({
        alias,
        givenName,
        familyName,
        password,
        dob,
        zip,
        userScope,
        signupCode,
        signupParams,
        signupContext,
      });

      // now, user needs to confirm their alias (email or phone)
      set({
        loading: false,
        temporaryCredentials: { alias, password },
        authState: states.PENDING_CONFIRMATION,
      });

      navigate(Route.CONFIRM_CODE);
    } catch (e) {
      // user already exists; attempt login instead
      if (e.code === errors.USERNAME_EXISTS || e.code === errors.USER_LAMBDA_VALIDATION) {
        // if auto sign in fails, redirect the user to the login page with their credentials
        const signInError = await get().signIn({ alias, password });

        if (!!signInError) {
          set({ loading: false });

          // will navigate from registration -> login
          navigate('LOGIN', {
            initialAlias: alias,
            initialPassword: password,
          });
        }
      } else {
        set({ loading: false, error: 'NOT_AUTHORIZED' });
      }
    }
  },

  signOut: async () => {
    try {
      // Set the signed out immediately so it feels responsive
      start('SIGNED_OUT', {});
      set({ authState: states.SIGNED_OUT });

      // Tell Cognito we're done
      Cognito.signOut();

      // Blacklisting jwt so it cannot be used again
      // and clearing the apollo store
      await API.signOut();
      signApolloOut();

      // clear our state
      get().reset();

      // clear our extra stores
      useHealthStore.getState().reset();
      useStore.getState().reset();
      useSession.getState().reset();

      // needs to use replace in order to clear any route params
      replace(Route.LOGIN);
    } catch (e) {}
  },
  // Create a platform user from a confirmed cognito user
  createUser: async (cognitoUser) => {
    set({ loading: true });

    const {
      givenName,
      familyName,
      signupCode,
      userScope,
      dob,
      zip,
      signupParams,
      email,
      phoneNumber,
      signupContext,
    } = await Cognito.getUserAttributes(cognitoUser);

    // @ts-ignore
    const user = await API.createUser({
      email,
      phoneNumber,
      givenName,
      familyName,
      signupCode,
      userScope,
      dob,
      zip,
      signupParams,
      signupContext,
    });

    set({ user, loading: false, created: true });
  },

  // attempts to resend to the current alias
  resendCode: async () => {
    try {
      const { temporaryCredentials } = get();
      await Cognito.resendAuthCode(temporaryCredentials.alias);
      set({ code: { sending: true, sent: false } });
      setTimeout(() => {
        set({ code: { sending: false, sent: true } });
      }, 3000);
    } catch (e) {
      set({
        loading: false,
        error: Object.entries(errors).find((err) => err?.[1] === e?.name)?.[0],
      });
    }
  },

  // confirms a cognito code for current session
  confirmCode: async ({ code }) => {
    try {
      set({ loading: true, error: undefined });

      // Email should be normalized already
      const { temporaryCredentials } = get();

      await Cognito.confirmSignUp({
        alias: temporaryCredentials.alias,
        code,
      });

      // Now we can finally sign in and create our platform user
      const cognitoUser = await Cognito.signIn(temporaryCredentials);
      const attributes = await Cognito.getUserAttributes(cognitoUser);

      set({
        jwtToken: cognitoUser.signInUserSession.idToken.jwtToken,
        cognitoAccessToken: cognitoUser.signInUserSession.accessToken.jwtToken,
      });

      await get().createUser(cognitoUser);

      set({
        loading: false,
        authState: states.SIGNED_IN,
        attributes,
      });
    } catch (e) {
      const { name: authException }: { name: AuthException } = e;
      switch (authException) {
        case 'CodeMismatchException': {
          set({ loading: false, error: 'CODE_MISMATCH' });
        }
      }
    }
  },

  requestNewPassword: async (credentials) => {
    try {
      const alias = lowercase(credentials.alias);
      set({ loading: true, temporaryCredentials: { alias, password: '' } });
      await Cognito.forgotPassword(alias);
      set({ loading: false, authState: states.PENDING_NEW_PASSWORD });
    } catch (e) {
      set({
        loading: false,
        temporaryCredentials: { alias: '', password: '' },
        error: Object.entries(errors).find((err) => err?.[1] === e?.name)?.[0],
      });
    }
  },
  // Set the new password with the reset code received
  confirmNewPassword: async ({ newPassword, code }) => {
    try {
      set({ loading: true });
      const { alias } = get().temporaryCredentials;

      await Cognito.confirmForgotPassword({
        code,
        newPassword,
        alias,
      });
      await get().signIn({ alias, password: newPassword });
    } catch (e) {
      set({
        loading: false,
        error: Object.entries(errors).find((err) => err?.[1] === e?.name)?.[0],
      });
    }
  },

  // Directly change a password if a user remembers their current password
  changePassword: async ({ oldPassword, newPassword }) => {
    try {
      set({ loading: true, passwordChanged: false });
      await Cognito.changePassword({ oldPassword, newPassword });
      set({ loading: false, passwordChanged: true });
    } catch (e) {
      set({
        loading: false,
        error: Object.entries(errors).find((err) => err?.[1] === e?.name)?.[0],
      });
    }
  },
}));

const Auth = useAuth;

/**
 *
 * @param {Object} events
 * @param {function} events.onPendingNewPassword
 * @param {function} events.onPendingConfirmation
 * @param {function} events.onSignedIn
 * @param {function} events.onPasswordChanged
 * Pass a dependency array to renew subscriptions if a value changes
 * Indeed if your callback depends on an external value that may change
 * you will need to pass it there otherwise it will only remember the initial value
 * @param {Array} dep
 */
function useAuthEvents(events, dep?: Array<any>) {
  const prevState = useRef(Auth.getState());

  useEffect(() => {
    const unsub = Auth.subscribe((state) => {
      if (state.authState !== prevState.current.authState) {
        switch (state.authState) {
          case states.PENDING_NEW_PASSWORD:
            void events?.onPendingNewPassword?.(state);
            break;
          case states.PENDING_CONFIRMATION:
            void events?.onPendingConfirmation?.(state);
            break;
          case states.SIGNED_IN:
            void events?.onSignedIn?.(state);
            break;
          default:
        }
      }

      if (state.passwordChanged && !prevState.current.passwordChanged) {
        void events?.onPasswordChanged?.(state);
      }

      prevState.current = state;
    });
    return () => unsub();
  }, dep || []);
}

export { useAuth, Auth, useAuthEvents };
