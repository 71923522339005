import gql from 'graphql-tag';

const errorItem = gql`
  fragment ErrorItem on ErrorItem {
    code
    field
    message
  }
`;

export default {
  errorItem,
};
