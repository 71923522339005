import React from 'react';
import { ComplexRow, Stepper, Text, FormLabel } from '@uikit';
import { Stack } from '@layouts';
import { InputProps } from '../types';

interface StepperInputProps<T> extends InputProps<T> {
  label: string | ((value: any) => string);
  stepperType: 'percentage' | 'integer';
  increment?: number;
  min?: number;
  max?: number;
  headerText?: string;
  help?: string;
  inline?: boolean;
}

const formatStep = (val, stepperType) => {
  // converts .25 -> 25 for display
  if (stepperType === 'percentage') {
    return Math.round(val * 100);
  }

  return val;
};

const parseStep = (val, stepperType) => {
  // converts .25 -> 25 for display
  if (stepperType === 'percentage') {
    return val / 100;
  }

  return val;
};

const StepperInput = React.forwardRef(
  <T extends any>(
    {
      value,
      stepperType,
      onChange,
      onBlur,
      increment,
      min,
      max,
      inline,
      label,
      headerText,
      help,
      optional,
      testID,
      disabled,
    }: StepperInputProps<T>,
    ref,
  ) => {
    const stepper = (
      <Stack spacing="1">
        {!inline && <FormLabel label={headerText} help={help} optional={optional} />}

        <Stepper
          testID={testID}
          value={formatStep(value, stepperType)}
          onChange={(val) => {
            onChange(parseStep(val, stepperType));
            onBlur();
          }}
          step={increment}
          min={min}
          max={max}
          suffix={stepperType === 'percentage' ? '%' : ''}
          large={inline ? false : true}
          disabled={disabled}
        />

        {inline ? null : (
          <Text fluid align="center" color="subtle">
            {label}
          </Text>
        )}
      </Stack>
    );

    return inline ? (
      <ComplexRow testID="" label={headerText} sublabel={label} accessory={stepper} />
    ) : (
      stepper
    );
  },
);

StepperInput.displayName = 'StepperInput';
export default StepperInput;
