import 'styled-components';
import { FontSize, FontWeight } from '@app/types';

export enum ColorScheme {
  light = 'light',
  dark = 'dark',
}

export enum Color {
  text = 'text',
  subtle = 'subtle',
  fg = 'fg',
  fg2 = 'fg2',
  fg3 = 'fg3',
  bg = 'bg',
  bgTransparent = 'bgTransparent',
  bgSheet = 'bgSheet',
  bgCard = 'bgCard',
  border = 'border',
  bg2 = 'bg2',
  bg3 = 'bg3',
  fade = 'fade',
  bgGray = 'bgGray',
  void = 'void',
  veil = 'veil',
  acrylic = 'acrylic',
  brand = 'brand',
  brandLight = 'brandLight',
  coverage = 'coverage',
  coverageLight = 'coverageLight',
  positive = 'positive',
  positiveHover = 'positiveHover',
  positiveLight = 'positiveLight',
  medium = 'medium',
  mediumLight = 'mediumLight',
  negative = 'negative',
  negativeLight = 'negativeLight',
  inputBg = 'inputBg',
  snow = 'snow', // always white
  system = 'system',
}

export enum Gradient {
  brand = 'brand',
  brandLight = 'brandLight',
  brandSmall = 'brandSmall',
  coverage = 'coverage',
  coverageLight = 'coverageLight',
  coverageSmall = 'coverageSmall',
  income = 'income',
  incomeLight = 'incomeLight',
  incomeSmall = 'incomeSmall',
  lobby = 'lobby',
  retirement = 'retirement',
  retirementLight = 'retirementLight',
  retirementSmall = 'retirementSmall',
  saved = 'saved',
  savedLight = 'savedLight',
  savedSmall = 'savedSmall',
  taxes = 'taxes',
  taxesLight = 'taxesLight',
  taxesSmall = 'taxesSmall',
  positive = 'positive',
  positiveLight = 'positiveLight',
  medium = 'medium',
  mediumLight = 'mediumLight',
  negative = 'negative',
  negativeLight = 'negativeLight',
}

export enum Presentation {
  actionSheet = 'actionSheet',
  formSheet = 'formSheet',
  pageSheet = 'pageSheet',
  largeSheet = 'largeSheet',
}

export type Container = Presentation | 'dialog' | 'miniPage' | 'basic' | 'page';

// selection of colors that are allowed for links
export type TextColor = Color.text | Color.subtle;
export type LinkColor =
  | Color.text
  | Color.subtle
  | Color.brand
  | Color.coverage
  | Color.negative
  | Color.snow;

export type Theme = {
  colors: Record<Color, string>;
  gradients: Record<Gradient, Array<string>>;
  fonts: {
    sizes: Record<FontSize, number>;
    weights: Record<FontWeight, number>;
  };
  breakpoints: {
    s: number;
    m: number;
    l: number;
    xl: number;
  };
  containers: Record<Container, number>;
  constants: {
    blur: number;
    buttonHeight: number;
    borderWidth: number;
    checkRadioSize: number;
    modalBlur: number;
    disabledOpacity: number;
    promptWidthSm: number;
    promptWidthLg: number;
    rootHeaderHeight: number;
  };
};
