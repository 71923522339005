import { truncate, lastFour } from './misc';

/**
 * Formats the name of a benefit
 * - If a name is specified, we use the capitalized version of it
 * - Otherwise, we should default to the catch benefit name
 * - If benefit is not defined in copy sheet, make the type readable
 */
export const bankName = (bank) => {
  return bank?.bankName;
};

export const bankDetail = (bank) => {
  return `${truncate(bank?.accountName, 20)} ${lastFour(bank?.accountNumber)}`;
};

export const formatBank = {
  name: bankName,
  detail: bankDetail,
};

// Helpful to format a reference to a specific bank account
export function accountRef({ bankName, accountName = 'Account', accountNumber = '0000000' } = {}) {
  const account = accountOnly({ accountName, accountNumber });
  return bankName ? `${bankName} ${account}` : account;
}

export function accountOnly({ accountName = 'Account', accountNumber = '0000000' } = {}) {
  return `${accountName} - ${lastFour(accountNumber)}`;
}
