import { FieldsConfig } from '@types';

// set of form fields that we want to be able to reuse across multiple screens
type FormType = 'CMS_PRIVACY';

export const forms: Record<FormType, FieldsConfig> = {
  CMS_PRIVACY: [
    {
      copy: 'catch.ede.HealthEnrollment',
      type: 'checkbox',
      name: 'isPrivacyDataUseAgree',
      required: true,
      title: 'Catch can securely handle my info',
      dependencies: ['isGA'],
      label: { id: '3A_Q' },
      help: ({ isGA }) => ({ id: isGA ? '3A_T_GA' : '3A_T' }),
    },
    {
      copy: 'catch.ede.HealthEnrollment',
      type: 'checkbox',
      name: 'isPrivacyTruthfulAgree',
      required: true,
      title: "I'll be honest",
      label: { id: '3B_Q' },
      help: { id: '3B_T' },
    },
    {
      copy: 'catch.ede.HealthEnrollment',
      type: 'checkbox',
      name: 'isPrivacyAuthorizeAgree',
      required: true,
      title: 'Catch can submit on my behalf',
      label: { id: '3C_Q' },
    },
  ],
};
