import { isBefore } from 'date-fns';
import {
  triToBool,
  boolToTri,
  toDate,
  twelveYearsAgo,
  normalize,
  formatMaskedPayload,
} from '@app/utils';
import { Gender, SexAssignedAtBirth, SexualOrientation } from '@app/types';

export const getInitialValues = (member = {}) => {
  return {
    id: member.id,
    givenName: member.givenName || '',
    middleName: member.middleName || '',
    familyName: member.familyName || '',
    nameSuffix: member.nameSuffix || '',
    relation: member.relation,
    filerRelation: member.relation === 'CHILD' ? member.filerRelation || '' : undefined,
    spouseRelation: member.relation === 'CHILD' ? member.spouseRelation || '' : undefined,
    sex: member.sex || '',
    sexAssignedAtBirth: member.sexAssignedAtBirth || '',
    sexAssignedAtBirthOtherText: member.sexAssignedAtBirthOtherText || '',
    gender: member.gender || '',
    genderDifferentTermText: member.genderDifferentTermText || '',
    sexualOrientation: member.sexualOrientation || '',
    sexualOrientationDifferentTermText: member.sexualOrientationDifferentTermText || '',
    ssn: member.ssn || '',
    confirmMissingSSN: false,
    age: member.age,
    isLongTermCare: boolToTri(member.isLongTermCare),
    isBlindOrDisabled: boolToTri(member?.isBlindOrDisabled),
    lastTobaccoUseDate: member.lastTobaccoUseDate,
    dob: member.dob || '',
    isRequestingCoverage: boolToTri(member?.isRequestingCoverage),
    isSmoker: boolToTri(member?.isSmoker),
    isCitizen: boolToTri(member?.isCitizen),
    isHispanicOrigin: boolToTri(member?.isHispanicOrigin),
    ethnicity: member?.ethnicity,
    isSameNameApplicationAndDocument: !member.ssn
      ? null
      : !!member?.ssnAlternateName?.givenName
      ? 'NO'
      : null,
    ssnAlternateName: member?.ssnAlternateName,
    race: member?.race,
    otherRaceText: member?.otherRaceText,
  };
};

export const formatPayload = ({ applicationID, values }) => {
  const isOlderThan12 = isBefore(toDate(values?.dob), twelveYearsAgo());

  return {
    variables: {
      input: {
        applicationID,
        applicationMembersInput: [
          {
            id: values?.id,
            givenName: values?.givenName,
            middleName: values?.middleName,
            familyName: values?.familyName,
            nameSuffix: values?.nameSuffix,
            sex: values?.sex || undefined,
            ssn: values?.confirmMissingSSN ? null : formatMaskedPayload(values?.ssn),
            sexAssignedAtBirth: values?.sexAssignedAtBirth || undefined,
            sexAssignedAtBirthOtherText:
              values?.sexAssignedAtBirth === SexAssignedAtBirth.OTHER
                ? values?.sexAssignedAtBirthOtherText
                : '',
            gender: values?.gender || undefined,
            genderDifferentTermText:
              values?.gender === Gender.DIFFERENT_TERM ? values?.genderDifferentTermText : '',
            sexualOrientation: values?.sexualOrientation || undefined,
            sexualOrientationDifferentTermText:
              values?.sexualOrientation === SexualOrientation.DIFFERENT_TERM
                ? values?.sexualOrientationDifferentTermText
                : '',
            dob: values?.dob,
            filerRelation: values?.filerRelation || undefined,
            spouseRelation: values?.spouseRelation || undefined,
            isSmoker: isOlderThan12 ? triToBool(values?.isSmoker) : false,
            lastTobaccoUseDate:
              isOlderThan12 && values?.isSmoker === 'YES' && !!values?.lastTobaccoUseDate
                ? values?.lastTobaccoUseDate
                : null,
            isBlindOrDisabled: values?.isBlindOrDisabled,
            isLongTermCare: values?.isLongTermCare,
            isHispanicOrigin: values?.isHispanicOrigin,
            ethnicity: values?.ethnicity,
            race: values?.race,
            otherRaceText: values?.otherRaceText,
            isCitizen: normalize(values?.isCitizen),
            ssnAlternateName:
              values?.isSameNameApplicationAndDocument === 'NO'
                ? {
                    firstName: normalize(values?.ssnAlternateName?.givenName),
                    middleName: normalize(values?.ssnAlternateName?.middleName),
                    lastName: normalize(values?.ssnAlternateName?.familyName),
                    nameSuffix: normalize(values?.ssnAlternateName?.nameSuffix),
                  }
                : null,
          },
        ],
      },
    },
    optimisticResponse: {
      upsertApplicationMembers: [
        {
          id: values?.id,
          givenName: values?.givenName,
          middleName: values?.middleName,
          familyName: values?.familyName,
          __typename: 'ApplicationMember',
        },
      ],
    },
  };
};
