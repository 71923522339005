import React from 'react';
import { Text } from '@uikit/components/Text';

const SectionTitle = React.forwardRef(({ testID, children, loading }, ref) => {
  return (
    <Text
      testID={testID}
      ref={ref}
      size="h4"
      weight="semibold"
      gutter="top|bottom"
      loading={loading}
    >
      {children}
    </Text>
  );
});

SectionTitle.displayName = 'SectionTitle';
export default SectionTitle;
