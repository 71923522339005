import React, { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import queryString from 'query-string';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useAuth } from '@client/Auth';
import { paths } from '@navigate';
import { Route } from '@types';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const params = useParams();

  const { authState, states, init } = useAuth(
    (state) => ({
      authState: state.authState,
      init: state.init,
      states: state.states,
    }),
    shallow,
  );

  useEffect(() => {
    if (!authState) {
      init();
    }
  }, []);

  if (!authState) {
    return null;
  }

  // returns the matches children
  if (authState === states.SIGNED_IN) {
    return <Outlet />;
  }

  const signupCode = queryString.parse(location?.search)?.r;

  return (
    <Navigate
      to={paths[Route.LOGIN]}
      state={{ r: signupCode, next: { ...location, state: { ...params, ...location?.state } } }}
    />
  );
};

export default AuthenticatedRoute;
