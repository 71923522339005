import { optionPresets } from '@f';
import OtherCoverageCard from './OtherCoverageCard';
import OtherCoverageFooter from './OtherCoverageFooter';

export const fields = [
  {
    testID: '243',
    name: 'isCoverageEnded',
    required: true,
    type: 'radio',
    options: optionPresets.YES_NO_TRINARY,
    copy: 'catch.ede.HealthEnrollment',
    dependencies: ['preliminaryCHIPStatus', 'stateCHIPWaitingPeriod', 'relation', 'givenName'],
    display: (vals) => vals.preliminaryCHIPStatus === 'YES' && vals.stateCHIPWaitingPeriod,
    label: ({ stateCHIPWaitingPeriod, relation, givenName }) => ({
      id: '243_Q',
      data: { num: stateCHIPWaitingPeriod, relation, fullName: givenName },
    }),
    help: { id: '243_T' },
  },
  {
    testID: '244',
    name: 'coverageEndedReason',
    required: true,
    type: 'radio',
    options: ({ givenName }) => [
      {
        label: { id: 'enums.END_REASON_INSURANCE_TOO_EXPENSIVE', data: { fullName: givenName } },
        value: 'INSURANCE_TOO_EXPENSIVE',
      },
      {
        label: {
          id: 'enums.END_REASON_PARENT_NO_LONGER_WORKS_FOR_THE_COMPANY',
          data: { fullName: givenName },
        },
        value: 'PARENT_NO_LONGER_WORKS_FOR_THE_COMPANY',
      },
      {
        label: {
          id: 'enums.END_REASON_COMPANY_STOPPED_OFFERING_INSURANCE',
          data: { fullName: givenName },
        },
        value: 'COMPANY_STOPPED_OFFERING_INSURANCE',
      },
      {
        label: {
          id: 'enums.END_REASON_COMPANY_STOPPED_OFFERING_INSURANCE_TO_DEPENDENTS',
          data: { fullName: givenName },
        },
        value: 'COMPANY_STOPPED_OFFERING_INSURANCE_TO_DEPENDENTS',
      },
      {
        label: { id: 'enums.END_REASON_MEDICAL_NEEDS_NOT_COVERED', data: { fullName: givenName } },
        value: 'MEDICAL_NEEDS_NOT_COVERED',
      },
      { label: { id: 'enums.END_REASON_OTHER', data: { fullName: givenName } }, value: 'OTHER' },
    ],

    copy: 'catch.ede',
    label: ({ givenName }) => ({ id: 'HealthEnrollment.244_Q', data: { fullName: givenName } }),
    help: { id: 'HealthEnrollment.244_T' },
    dependencies: [
      'preliminaryCHIPStatus',
      'stateCHIPWaitingPeriod',
      'isCoverageEnded',
      'givenName',
    ],
    display: ({ preliminaryCHIPStatus, stateCHIPWaitingPeriod, isCoverageEnded }) =>
      preliminaryCHIPStatus === 'YES' && stateCHIPWaitingPeriod && isCoverageEnded === 'YES',
  },
  {
    name: 'coverageEndedReasonText',
    required: true,
    type: 'text',
    multiline: true,
    dependencies: [
      'preliminaryCHIPStatus',
      'stateCHIPWaitingPeriod',
      'isCoverageEnded',
      'coverageEndedReason',
    ],
    placeholder: 'Please enter a brief explanation',
    display: (values) =>
      values?.preliminaryCHIPStatus === 'YES' &&
      values?.stateCHIPWaitingPeriod &&
      values?.isCoverageEnded === 'YES' &&
      values?.coverageEndedReason === 'OTHER',
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '191',
    required: true,
    name: 'isEnrolledInHealthCoverage',
    type: 'radio',
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['relation', 'givenName', 'coverageYear', 'medicaid', 'chip'],
    label: ({ relation, givenName, coverageYear }) => ({
      id: '191_Q',
      data: { relation, fullName: givenName, coverageYear },
    }),
    help: ({ medicaid, chip }) => ({
      id: '191_T',
      data: { medicaidName: medicaid, chipName: chip },
    }),
  },

  {
    copy: 'catch.ede.HealthEnrollment',
    testID: '192',
    name: 'otherCoverages',
    type: 'array',
    required: true,
    grouped: true,
    dependencies: [
      'relation',
      'givenName',
      'nextYear',
      'medicaid',
      'chip',
      'isGA',
      'isEnrolledInHealthCoverage',
      'SUBFIELD:insuranceMarketType',
    ],
    display: (values) => values.isEnrolledInHealthCoverage === 'YES',
    label: ({ relation, givenName }) => ({ id: '192_Q', data: { relation, fullName: givenName } }),
    help: ({ relation, givenName, nextYear, medicaid, chip, isGA }) => ({
      id: '192_T',
      data: {
        isGA,
        relation,
        fullName: givenName,
        nextYear,
        mc: medicaid,
        chipName: chip,
      },
    }),
    Component: OtherCoverageCard,
    Footer: OtherCoverageFooter,
    subfields: [
      {
        copy: 'catch.ede.HealthEnrollment',
        testID: '197_L',
        name: 'insurancePlanName',
        type: 'text',
        label: { id: '197_L' },
        dependencies: ['insuranceMarketType'],
        maxLength: 50,
        display: ({ insuranceMarketType }) =>
          ['OTHER_FULL_BENEFIT', 'OTHER_LIMITED_BENEFIT'].includes(insuranceMarketType),
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        testID: '193',
        name: 'insurancePolicyNumber',
        type: 'text',
        label: ({ insuranceMarketType }) => ({
          id: insuranceMarketType === 'MEDICARE' ? '194_L' : '193_L',
        }),
        dependencies: ['insuranceMarketType'],
        display: ({ insuranceMarketType }) =>
          ['MEDICARE', 'TRICARE', 'VA', 'OTHER_FULL_BENEFIT', 'OTHER_LIMITED_BENEFIT'].includes(
            insuranceMarketType,
          ),
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        testID: '195',
        name: 'insurancePolicyMemberID',
        type: 'text',
        label: { id: '195_L' },
        dependencies: ['insuranceMarketType'],
        display: ({ insuranceMarketType }) =>
          ['TRICARE', 'VA', 'OTHER_FULL_BENEFIT', 'OTHER_LIMITED_BENEFIT'].includes(
            insuranceMarketType,
          ),
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        testID: '199',
        name: 'limitedBenefit',
        type: 'checkbox',
        label: { id: '199_L' },
        dependencies: ['insuranceMarketType'],
        display: ({ insuranceMarketType }) => insuranceMarketType === 'OTHER',
      },
    ],
  },
];
