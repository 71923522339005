import gql from 'graphql-tag';

export type ChangeAliasError =
  | 'CHANGE_ALIAS_ERROR_ALIAS_IN_USE'
  | 'CHANGE_ALIAS_ERROR_SAME_ALIAS'
  | 'CHANGE_ALIAS_ERROR_MISSING_ALIAS'
  | 'CHANGE_ALIAS_ERROR_INVALID_ALIAS';

export interface ChangeEmailVars {
  email: string;
}

export interface ChangeEmailResponse {
  changeEmail: {
    error: ChangeAliasError;
    newEmail: string;
  };
}

export const ChangeEmail = gql`
  mutation ChangeEmail($email: String!) {
    changeEmail(input: { email: $email }) {
      error
      newEmail
    }
  }
`;
