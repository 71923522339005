import React, { useCallback } from 'react';
import {
  useMutation,
  mutations,
  UpsertHealthPrivacyVars,
  UpsertHealthPrivacyResponse,
  useQuery,
  HealthApplicationQuery,
} from '@data';
import { Segment, SegmentEvent, boolToTri } from '@app/utils';
import { SplitFormBlueprint } from '@app/blueprints';
import { forms } from '@app/config';
import { Route } from '@types';

const PrivacyAgreement = ({ isGA, applicationID, handleNext }) => {
  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: submitting }] = useMutation<
    UpsertHealthPrivacyResponse,
    UpsertHealthPrivacyVars
  >(mutations.HEALTH_INITIAL_AGREE, {
    onCompleted: () => {
      Segment.track(SegmentEvent.APPLICATION_PRIVACY_AGREED, {
        coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
      });

      handleNext();
    },
  });

  const onSubmit = useCallback((values) => {
    upsert({
      variables: {
        input: {
          isPrivacyDataUseAgree: boolToTri(values?.isPrivacyDataUseAgree),
          isPrivacyTruthfulAgree: boolToTri(values?.isPrivacyTruthfulAgree),
          isPrivacyAuthorizeAgree: boolToTri(values?.isPrivacyAuthorizeAgree),
        },
      },
    });
  }, []);

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={submitting}
      title="Privacy Agreement"
      formConfig={{
        initialValues: {
          isGA,
          isPrivacyDataUseAgree: data?.viewerTwo.agreements.isPrivacyDataUseAgree,
          isPrivacyTruthfulAgree: data?.viewerTwo.agreements.isPrivacyTruthfulAgree,
          isPrivacyAuthorizeAgree: data?.viewerTwo.agreements.isPrivacyAuthorizeAgree,
        },
        fields: forms.CMS_PRIVACY,
        onSubmit,
      }}
    />
  );
};

export const PrivacyAgreementView = {
  name: Route.EDE_PRIVACY,
  component: PrivacyAgreement,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Privacy Agreement',
  },
};
