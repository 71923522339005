import React, { useCallback } from 'react';

import { BasicFormBlueprint, SplitFormBlueprint } from '@blueprints';
import {
  mutations,
  useQuery,
  useMutation,
  HealthApplicationQuery,
  SubmitHealthApplicationResponse,
  SubmitHealthApplicationVars,
  updates,
} from '@data';
import { Segment, SegmentEvent, handleHealthResponse } from '@util';
import { sheets, useSheet } from '@navigate';
import { getInitialValues, formatPayload } from './agreementsUtils';
import { fields } from './agreementsFields';
import { shallow } from 'zustand/shallow';
import { loaders } from '@app/config';
import { Route } from '@types';

const Agreements = ({ layout, isResubmit, applicationID, isGA, handleNext }) => {
  const { openLoader } = useSheet(
    (state) => ({ openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT) }),
    shallow,
  );

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: submitting }] = useMutation<
    SubmitHealthApplicationResponse,
    SubmitHealthApplicationVars
  >(mutations.SUBMIT_HEALTH_APPLICATION, {
    ...handleHealthResponse({ onCompleted: handleNext }),
    update: (cache, result) =>
      updates.ELIG_REFETCHED(cache, {
        didRefetch: true,
        applicationID,
      }),
  });

  const onSubmit = useCallback(
    (values) => {
      openLoader();

      Segment.track(SegmentEvent.APPLICATION_AGREEMENTS_CONFIRMED, {
        coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
      });

      upsert(formatPayload({ applicationID, values, isResubmit }));
    },
    [applicationID, isResubmit],
  );

  // handles two different layouts
  const Blueprint = layout === 'split' ? SplitFormBlueprint : BasicFormBlueprint;

  return (
    <Blueprint
      title="Agreements"
      loading={!data}
      submitting={submitting}
      formConfig={{
        initialValues: getInitialValues(data?.viewerTwo?.health?.application, isResubmit, isGA),
        fields,
        onSubmit,
      }}
    />
  );
};

export const AgreementsView = {
  name: Route.EDE_AGREEMENTS,
  component: (props) => <Agreements {...props} layout="split" />,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Agreements',
  },
};
