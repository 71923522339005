import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from '@uikit/components/Link';

const PREFIX = 'catch.ede.HealthEnrollment';

const Message = ({ id }) => {
  return <FormattedMessage id={`${PREFIX}.${id}`} />;
};

const VALUES = {
  S2_T: {
    link1: (
      <Link testID="s2-link1" href="https://app.catch.co/auth/sign-in" key="link1">
        <Message id="S2_T_link1" />
      </Link>
    ),
    link2: (
      <Link testID="s2-link2" href="https://www.healthcare.gov/taxes/" key="link2">
        <Message id="S2_T_link2" />
      </Link>
    ),
  },
  '3A_T': {
    link1: (
      <Link testID="3a-link1" href="https://www.healthcare.gov/how-we-use-your-data/">
        <Message id="3A_T_link1" />
      </Link>
    ),
    link2: (
      <Link testID="3a-link2" href="https://www.healthcare.gov/individual-privacy-act-statement/">
        <Message id="3A_T_link2" />
      </Link>
    ),
  },
  '32_T': {
    link: (
      <Link testID="32-link" href="https://www.socialsecurity.gov/">
        <Message id="32_T_link" />
      </Link>
    ),
  },
  S9_T: {
    link1: (
      // placeholder link
      <Link testID="s9-link1" href="https://www.irs.gov/help/ita/do-i-need-to-file-a-tax-return">
        <Message id="S9_T_link1" />
      </Link>
    ),
    link2: (
      // placeholder link
      <Link
        testID="s9-link2"
        href="https://www.irs.gov/publications/p501#en_US_2014_publink1000220780"
      >
        <Message id="S9_T_link2" />
      </Link>
    ),
    link3: (
      // placeholder link
      <Link
        testID="s9-link3"
        href="https://www.healthcare.gov/income-and-household-information/household-size/"
      >
        <Message id="S9_T_link3" />
      </Link>
    ),
  },
  S14_T: {
    link1: (
      <Link
        testID="s14-link1"
        href="https://www.healthcare.gov/immigrants/us-citizens-and-nationals/"
      >
        <Message id="S14_T_link1" />
      </Link>
    ),
  },
  S15_T: {
    link1: (
      // placeholder link
      <Link testID="s15-link1" href="https://www.healthcare.gov">
        <Message id="S15_T_link1" />
      </Link>
    ),
  },
  S16_T: {
    link1: (
      // placeholder link
      <Link testID="s16-link1" href="https://www.healthcare.gov">
        <Message id="S16_T_link1" />
      </Link>
    ),
  },
  S17_T: {
    link1: (
      <Link
        testID="s17-link1"
        href="https://www.healthcare.gov/immigrants/us-citizens-and-nationals/"
      >
        <Message id="S17_T_link1" />
      </Link>
    ),
  },
  S19_T: {
    link1: (
      <Link
        testID="s19-link1"
        href="http://www.ncsl.org/research/state-tribal-institute/list-of-federal-and-state-recognized-tribes.aspx"
      >
        <Message id="S19_T_link1" />
      </Link>
    ),
    link2: (
      <Link
        testID="s19-link2"
        href="https://www.healthcare.gov/american-indians-alaska-natives/medicaid-chip/"
      >
        <Message id="S19_T_link2" />
      </Link>
    ),
  },
  '236_T': {
    link: (
      <Link testID="236-link" href="https://www.healthcare.gov/taxes-reconciling/">
        <Message id="236_T_link" />
      </Link>
    ),
  },
};

function getCopyValues(id) {
  const selected = VALUES[id];

  if (selected) {
    return selected;
  }

  return;
}

export default getCopyValues;
