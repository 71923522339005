import React from 'react';

import { formatBenefit, getEventProperties, useCopy } from '@app/utils';
import { Text, Asset, Flag, LineItem } from '@uikit';
import { SectionTitle, Page, Stack, Section } from '@layouts';

interface EventReceiptProps {
  event: any;
  eventProperties: ReturnType<typeof getEventProperties>;
  noHeader?: boolean;
  noMargin?: boolean;
}

const EventReceipt: React.FC<EventReceiptProps> = ({
  event,
  eventProperties: props,
  noHeader,
  noMargin,
}) => {
  const { c, $ } = useCopy('catch.receipts.TransferEvent.breakdown');
  const visible = (b) => /^ACTIVE/.test(b?.goalStatus) || !b?.goalStatus;
  const filtered = event?.transactionRecords?.filter(visible);

  return (
    <Page color="sheet" margins={!noMargin}>
      {!noHeader && (
        <>
          <Stack horizontal flexItem={0}>
            <>
              <Asset {...props?.asset} />
              <Asset {...props?.secondaryAssets} />
            </>
            {/* @ts-ignore */}
            <Flag>{props?.status}</Flag>
          </Stack>
        </>
      )}
      <Stack horizontal flexItem={0}>
        {/* @ts-ignore */}
        <SectionTitle>Total</SectionTitle>
        <Text tabular size="h4" weight="medium">
          {props?.totalAmount}
        </Text>
      </Stack>
      <Stack spacing="2">
        <Stack spacing="0b">
          <LineItem left={props?.dateLabel} right={props?.time} />
        </Stack>
        {!!filtered && filtered?.length > 0 && (
          <Section title={c('title') || 'Receipt'}>
            <Stack spacing="0b">
              {filtered?.map(({ id, type, amount, goal }, idx) => (
                <LineItem key={id} left={formatBenefit.name(goal)} right={$(amount)} />
              ))}
            </Stack>
          </Section>
        )}
      </Stack>
    </Page>
  );
};

export default EventReceipt;
