import gql from 'graphql-tag';
import { EXPLORER_DATA } from '../fragments';

const IMPORT_PUBLIC_EXPLORER = gql`
  mutation ImportPublicExplorer($id: ID!) {
    importExplorerFromPublic(id: $id) {
      id
      ...ExplorerData
    }
  }
  ${EXPLORER_DATA}
`;

export default {
  document: IMPORT_PUBLIC_EXPLORER,
};
