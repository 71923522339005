import { useCallback, useMemo } from 'react';
import { useQuery, HealthApplicationQuery, useMutation, mutations } from '@data';
import { navigate } from '@navigate';
import { getMembers } from '@app/data/utils';
import { Route } from '@types';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { MultiMemberSplitFormBlueprint } from '@app/blueprints';
import { workHoursFields } from './workHours/workHoursFields';
import { getInitialValues, formatPayload } from './workHours/workHoursUtils';
import { determineOtherCoverage, isMarketplacePrelim } from '@app/navigate/EDENavigationGuard';

const WorkHours = ({ applicationID, coverageYearNumber }) => {
  const { c } = useCopy('catch');

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: upserting }] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const { members, children, parents } = useMemo(() => {
    const members = getMembers(data);
    return {
      members,
      children: members?.filter((m) => m.relation === 'CHILD'),
      parents: members?.filter((m) => m?.relation === 'SELF' || m?.relation === 'SPOUSE'),
    };
  }, [data?.viewerTwo?.health?.application]);

  const handleNext = useCallback(() => {
    Segment.track(SegmentEvent.APPLICATION_WORK_HOURS_COMPLETED, {
      coverage_year: coverageYearNumber,
    });

    navigate(
      determineOtherCoverage(members)
        ? Route.EDE_OTHER_COVERAGE
        : isMarketplacePrelim(members)
        ? Route.EDE_SEP
        : Route.EDE_REVIEW,
    );
  }, [members, coverageYearNumber]);

  return (
    <MultiMemberSplitFormBlueprint
      loading={loading}
      submitting={upserting}
      title={(member) => c('ede.HealthEnrollment.242_Q', { childName: member.legalName })}
      subtitle={c('ede.HealthEnrollment.242_T')}
      members={children}
      fields={workHoursFields}
      data={{
        numChildren: children.length,
        parent1: parents?.[0]?.legalName,
        parent2: parents?.[1]?.legalName,
      }}
      getInitialValues={getInitialValues}
      onNext={async (values) => {
        const input = formatPayload({ applicationID, values });
        upsert({ variables: { input } });
      }}
      onComplete={handleNext}
    />
  );
};

export const WorkHoursView = {
  name: Route.EDE_MEMBER_WORK_HOURS,
  component: WorkHours,
  options: {
    title: 'Work hours',
  },
};
