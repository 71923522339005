import React from 'react';
import { Stack } from '@layouts';
import { FormLabel, Radio } from '@uikit';
import { Option } from '@types';
import { InputProps } from '../types';

interface RadioInputProps<T> extends InputProps<T> {
  options: Array<Option>;
  horizontal?: boolean;
}

const RadioInput = <T extends any>({
  testID,
  name,
  label,
  sublabel,
  help,
  optional,
  value,
  onChange,
  onFocus,
  onBlur,
  options,
  disabled,
  horizontal,
}: RadioInputProps<T>) => {
  return (
    <Stack spacing="0b">
      <div>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
      </div>
      <Stack horizontal={horizontal} itemStyle={horizontal ? { flex: 1 } : undefined} spacing="0b">
        {options.map((option) => (
          <Radio
            key={option.value}
            testID={`${testID || name}-${option.value}`}
            name={name}
            label={option.label}
            value={option.value}
            checked={value === option.value}
            disabled={disabled}
            onFocus={onFocus}
            onChange={(val) => {
              onChange(val);
              onBlur();
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default RadioInput;
