import gql from 'graphql-tag';

const CHANGE_EMAIL = gql`
  mutation _ChangeEmail($input: ChangeEmailInput!) {
    changeEmail(input: $input) {
      error
    }
  }
`;

export default {
  document: CHANGE_EMAIL,
};
