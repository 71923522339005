import { useQuery } from '@apollo/client';
import { HealthLinkQueryData } from './../data/queries/HealthLinkQuery';
import { useEffect, useCallback, useMemo } from 'react';

import {
  IdentityStatus,
  IDProofErrorCode,
  IDProofFinalCode,
  IDProofQuiz,
  IDProofQuizSubmission,
} from '@types';
import { createLogger } from '@app/utils';
import { useMutation, mutations, queries, DocumentUploadsQuery, DocumentUploads } from '@data';
import { isPast } from 'date-fns';
import { popToast, ToastType } from '@app/_ui-kit';

const Log = createLogger('health-identity');

/**
 * Required for PENDING_OFFLINE_VERIFICATION UI
 * @todo move this
 */
export const EXPERIAN_HOURS = {
  Weekdays: '8:30am - Midnight EST',
  Saturday: '10am - 8pm EST',
  Sunday: '11am - 7pm EST',
};

interface UseHealthIdentityReturn {
  status?: IdentityStatus;
  error?: IDProofErrorCode;
  finalCode?: IDProofFinalCode;
  referenceNumber?: string;
  loading: boolean;
  checking: boolean;
  canCheckIdentity?: boolean;
  confirmIdentity: () => any;
  answerQuestions: (response: IDProofQuizSubmission) => any;
  confirmVerifiedOffline: () => any;
  requestManualReview: () => any;
  confirming: boolean;
  answering: boolean;
  questions?: IDProofQuiz;
  retryAfter?: Date;
  shouldRetry: boolean;
  hasIdentityUploads?: boolean;
  identityUploads?: DocumentUploadsQuery['viewerTwo']['documentUploads'];
}

/**
 * @todo
 * - uses standalone identity
 * - is more agnostic to flow
 *
 * 1) PENDING_FARS (needs to call experian)
 * 2) GOOD (shows identity verified screen)
 * 2) DENIED (should redirect to hc.gov)
 *
 * - todo answer questions
 * - todo add a "lite" version for onboarding that hides certain statuses (offline, etc) and just moves on
 */
export const useHealthIdentity = (applicantID?: string): UseHealthIdentityReturn => {
  const { data, loading: checking } = useQuery<HealthLinkQueryData>(queries.HEALTH_LINK, {
    fetchPolicy: 'cache-and-network',
  });

  const docs = useQuery(DocumentUploads, { context: { failQuietly: true } });

  /**
   * 01
   * Initial IDP Check
   * @public
   */
  const [confirmIdentity, { data: idCheckData, loading: proofing }] = useMutation(
    mutations.HEALTH_IDP,
    {
      variables: applicantID ? { applicantID } : undefined,
      refetchQueries: [queries.HEALTH_LINK],
      awaitRefetchQueries: true,
      onCompleted: (res) => Log.debug(res),
    },
  );

  /** @private */
  const [_answerQuestions, { loading: answering }] = useMutation(mutations.HEALTH_IDP_QUESTIONS, {
    refetchQueries: [queries.HEALTH_LINK],
    awaitRefetchQueries: true,
  });

  /**
   * 02
   * Answer questions for NEEDS_QUIZ
   * @public
   */
  const answerQuestions = useCallback(
    (response: IDProofQuizSubmission) =>
      _answerQuestions({
        variables: {
          input: {
            response,
          },
        },
      }),
    [],
  );

  /**
   * 03
   * Confirm that user called Experian
   * @public
   */
  const [confirmVerifiedOffline, { loading: confirming }] = useMutation(mutations.HEALTH_IDP_FARS, {
    refetchQueries: [queries.HEALTH_LINK],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data?.confirmVerifiedOffline?.idStatus === 'PENDING_OFFLINE_VERIFICATION') {
        popToast({
          type: ToastType.coverage,
          title: 'Unable to verify',
          msg: "Make sure you've spoken with Experian",
        });
      }
    },
  });

  /**
   * 04
   * Request a manual review
   * @public
   */
  const [requestManualReview] = useMutation(mutations.REQUEST_MANUAL_REVIEW, {
    variables: {
      identityId: data?.viewerTwo?.identity?.id,
    },
  });

  const finalCode = idCheckData?.confirmIdentity?.finalDecisionCode;
  const referenceNumber = data?.viewerTwo?.identity?.transactionID;
  const status = data?.viewerTwo?.identity?.status;
  const questions = data?.viewerTwo.identity?.questions;
  const retryAfter = data?.viewerTwo?.identity?.retryAfter;

  // only attempt to retry if the time has passed and the user is NOT already verified manually
  const shouldRetry = retryAfter && status !== 'VERIFIED' ? isPast(new Date(retryAfter)) : false;

  /** Logging */
  useEffect(() => {
    if (!status) return;
    else if (status === 'DENIED')
      Log.warn(`IDP: status ${status}, code ${finalCode}, ref ${referenceNumber}`);
    else Log.debug(`IDP: status ${status}, code ${finalCode}, ref ${referenceNumber}`);
  }, [referenceNumber, finalCode, status]);

  const identityUploads = useMemo(() => {
    const uploads = docs?.data?.viewerTwo?.documentUploads || [];
    const acceptedTypes = ['PHOTO_ID_FRONT', 'PASSPORT', 'SOCIAL_SECURITY_CARD'];
    return uploads.filter((upload) => acceptedTypes.includes(upload.documentType));
  }, [docs]);

  return {
    status,
    error: undefined,
    finalCode,
    confirmIdentity,
    answerQuestions,
    answering,
    confirmVerifiedOffline,
    requestManualReview,
    confirming,
    referenceNumber,
    checking,
    loading: proofing || answering || checking || confirming,
    questions,
    retryAfter,
    shouldRetry,
    hasIdentityUploads: identityUploads?.length > 0,
    identityUploads,
  };
};
