import { useMemo, useState } from 'react';
import { AliasQuery, useDeprecatedMutation, useQuery } from '@data';

type Step = 'alias' | 'code';

interface UseAliasVerificationProps {
  aliasType: 'email' | 'phoneNumber';
  onSuccess: (success: { alias: string }) => void;
  onDismiss: () => void;
}

interface UseAliasVerificationReturn {
  loading: boolean;
  error: boolean;
  resent: boolean;
  step: Step;
  requestedAlias: string;
  email: string;
  phone: string;
  emailVerified: boolean;
  phoneVerified: boolean;
  submitAlias: (alias: string) => void;
  submitCode: ({ code: string }) => void;
  resendCode: () => void;
}

/**
 * useAliasVerification is hook for handling the alias verification process
 * an alias can be either an email or phone number
 */
export const useAliasVerification = ({
  aliasType,
  onSuccess,
  onDismiss,
}: UseAliasVerificationProps): UseAliasVerificationReturn => {
  const { data } = useQuery(AliasQuery);

  const { email, phone } = useMemo(() => {
    return {
      email: data?.viewer?.user?.email,
      phone: data?.viewer?.user?.phoneNumber,
    };
  }, [data]);

  const [currentStep, setCurrentStep] = useState<Step>('alias');
  const [requestedAlias, setAlias] = useState('');
  const [loading, setLoading] = useState(false);
  const [resent, setResent] = useState(false);
  const [error, setError] = useState(false);

  const errorTypes = {
    VERIFY_ALIAS_ERROR_NO_ACTIVE_CHANGE: {
      message: 'Change was never initiated',
      redirect: true,
    },
    VERIFY_ALIAS_ERROR_INVALID_MFA: {
      message: 'The code you entered is invalid',
    },
    VERIFY_ALIAS_ERROR_REQUEST_EXPIRED: {
      message: 'Change expired, request a new code',
    },
    VERIFY_ALIAS_ERROR_COGNITO: {
      message: 'Something went wrong',
    },
  };

  const handleChange = (change) => {
    setLoading(false);
    const { error: err } = change;
    if (err) {
      setError(err);
    } else {
      setCurrentStep('code');
    }
  };

  // change phone
  const [changePhone] = useDeprecatedMutation('changePhone', {
    onCompleted: ({ changePhone }) => handleChange(changePhone),
    onError: (err) => setError(err),
  });

  // change email
  const [changeEmail] = useDeprecatedMutation('changeEmail', {
    onCompleted: ({ changeEmail }) => handleChange(changeEmail),
    onError: (err) => setError(err),
  });

  // change alias
  const changeAlias = aliasType === 'email' ? changeEmail : changePhone;

  const handleVerify = (verification) => {
    setLoading(false);
    const { error: err } = verification;
    if (err) {
      if (errorTypes[err]?.redirect) setCurrentStep('alias');
      setError(errorTypes[err] ? errorTypes[err] : err);
    } else {
      const alias = requestedAlias.toLowerCase();

      onSuccess({ alias });
      setAlias('');
      setLoading(false);
      onDismiss();
    }
  };

  const [verifyEmail] = useDeprecatedMutation('verifyEmail', {
    refetchQueries: ['Alias'],
    onCompleted: ({ verifyEmail }) => handleVerify(verifyEmail),
    onError: (err) => setError(err),
  });

  const [verifyPhone] = useDeprecatedMutation('verifyPhone', {
    refetchQueries: ['Alias'],
    onCompleted: ({ verifyPhone }) => handleVerify(verifyPhone),
    onError: (err) => setError(err),
  });

  const verifyAlias = aliasType === 'email' ? verifyEmail : verifyPhone;

  /**
   * Handles submission of new email
   * - Given the new email, mark as loading, send the change email auth
   */
  const submitAlias = (alias) => {
    setError(false);
    setLoading(true);
    setAlias(alias); // allow to handle both
    const input = aliasType === 'email' ? { email: alias } : { phone: alias };
    changeAlias({ variables: { input } });
  };

  /**
   * Handles the submission of the code
   * - Given the submitted code
   * - If successful, trigger the success handler
   * - Otherwise show an error
   */
  const submitCode = ({ code }) => {
    setError(false);
    setLoading(true);
    verifyAlias({ variables: { input: { mfaCode: code } } });
  };

  /**
   * Handles a resend attempt
   * - Resends to the email we already have stored here
   */
  const resendCode = () => {
    setError(false);
    submitAlias(requestedAlias);
    setResent(true);
  };

  return {
    loading,
    error,
    resent,
    requestedAlias,
    step: currentStep,
    email,
    phone,
    emailVerified,
    phoneVerified,
    submitAlias,
    submitCode,
    resendCode,
  };
};
