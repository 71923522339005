export enum Route {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  CONFIRM_CODE = 'CONFIRM_CODE',
  CONSENT = 'CONSENT',
  DEV = 'DEV',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  HOME = 'HOME',
  COVERAGE = 'COVERAGE',
  COVERAGE_DETAIL = 'COVERAGE_DETAIL',
  WALLET_ITEM = 'WALLET_ITEM',
  ACCOUNT = 'ACCOUNT',
  ACCOUNT_INFO = 'ACCOUNT_INFO',
  ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
  CHANGE_ALIAS = 'CHANGE_ALIAS',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  EDIT_USER_DETAILS = 'EDIT_USER_DETAILS',
  STATEMENTS = 'STATEMENTS',
  EVENT_RECEIPT = 'EVENT_RECEIPT',
  HEALTH_RESUME = 'HEALTH_RESUME',
  EDE_INTRO = 'EDE_INTRO',
  EDE_COVERAGE_INFO = 'EDE_COVERAGE_INFO',
  EDE_PRIVACY = 'EDE_PRIVACY',
  EDE_HOUSEHOLD = 'EDE_HOUSEHOLD',
  EDE_SCREENING = 'EDE_SCREENING',
  EDE_PRO_ASSISTANCE = 'EDE_PRO_ASSISTANCE',
  EDE_ID_INFO = 'EDE_ID_INFO',
  EDE_IDENTITY = 'EDE_IDENTITY',
  EDE_APPLICATION_IMPORT = 'EDE_APPLICATION_IMPORT',
  EDE_CONFIRM_APPLICANTS = 'EDE_CONFIRM_APPLICANTS',
  EDE_WINDOW_SHOPPING = 'EDE_WINDOW_SHOPPING',
  EDE_MEMBER_INFO = 'EDE_MEMBER_INFO',
  EDE_MEMBER_QUESTIONS = 'EDE_MEMBER_QUESTIONS',
  EDE_IMMIGRATION_DETAILS = 'EDE_IMMIGRATION_DETAILS',
  EDE_MEDICAID_DENIAL = 'EDE_MEDICAID_DENIAL',
  EDE_MEMBER_INCOME_LIST = 'EDE_MEMBER_INCOME_LIST',
  EDE_MEMBER_DEDUCTIONS_LIST = 'EDE_MEMBER_DEDUCTIONS_LIST',
  EDE_MEMBER_INCOME_DETAILS = 'EDE_MEMBER_INCOME_DETAILS',
  EDE_MEMBER_INCOME_CONFIRM = 'EDE_MEMBER_INCOME_CONFIRM',
  EDE_MEMBER_INCOME_DISCREPANCY = 'EDE_MEMBER_INCOME_DISCREPANCY',
  EDE_SEP = 'EDE_SEP',
  EDE_MEMBER_MEDICAL_BILLS = 'EDE_MEMBER_MEDICAL_BILLS',
  EDE_DEPENDENTS_CURRENT_COVERAGE = 'EDE_DEPENDENTS_CURRENT_COVERAGE',
  EDE_OUTSIDE_HOUSEHOLD = 'EDE_OUTSIDE_HOUSEHOLD',
  EDE_MEMBER_WORK_HOURS = 'EDE_MEMBER_WORK_HOURS',
  EDE_OTHER_COVERAGE = 'EDE_OTHER_COVERAGE',
  EDE_REVIEW = 'EDE_REVIEW',
  EDE_AGREEMENTS = 'EDE_AGREEMENTS',
  EDE_RESULTS = 'EDE_RESULTS',
  EDE_SPLIT_ELIGIBILITY = 'EDE_SPLIT_ELIGIBILITY',
  EDE_PLANS = 'EDE_PLANS',
  EDE_APTC_AGREEMENT = 'EDE_APTC_AGREEMENT',
  EDE_ENROLL = 'EDE_ENROLL',
  EDE_SUCCESS = 'EDE_SUCCESS',
  EDE_HELP = 'EDE_HELP',
  EDE_SEP_OUT_OF_DATE = 'EDE_SEP_OUT_OF_DATE',
  HEALTH_DOCS = 'HEALTH_DOCS',
  HEALTH_DOCS_UPLOAD = 'HEALTH_DOCS_UPLOAD',
  HEALTH_DOCS_UPLOADED = 'HEALTH_DOCS_UPLOADED',
  HEALTH_LINK_INTRO = 'HEALTH_LINK_INTRO',
  HEALTH_LINK_AGREEMENTS = 'HEALTH_LINK_AGREEMENTS',
  HEALTH_LINK_IDENTITY = 'HEALTH_LINK_IDENTITY',
  HEALTH_LINK_IMPORT = 'HEALTH_LINK_IMPORT',
  CIC_MANAGE_COVERAGE_INTRO = 'CIC_MANAGE_COVERAGE_INTRO',
  CIC_CHANGE_SELECTION = 'CIC_CHANGE_SELECTION',
  CIC_BASICS = 'CIC_BASICS',
  CIC_ADDRESS_ENTRY = 'CIC_ADDRESS_ENTRY',
  CIC_NO_CHANGE = 'CIC_NO_CHANGE',
  CIC_STATE_HANDOFF = 'CIC_STATE_HANDOFF',
  CIC_STATE_PICKUP = 'CIC_STATE_PICKUP',
  CIC_CANCEL_POLICY = 'CIC_CANCEL_POLICY',
  CIC_GOV_HANDOFF = 'CIC_GOV_HANDOFF',
  CIC_GOV_PICKUP = 'CIC_GOV_PICKUP',
  CIC_APP_REVIEW_INTRO = 'CIC_APP_REVIEW_INTRO',
  CIC_MEMBER_MARRIAGE = 'CIC_MEMBER_MARRIAGE',
  CIC_MEMBER_CUT_INTRO = 'CIC_MEMBER_CUT_INTRO',
  CIC_MOVE_DATE_ENTRY = 'CIC_MOVE_DATE_ENTRY',
  CIC_MEMBER_DIVORCE = 'CIC_MEMBER_DIVORCE',
  CIC_MEMBER_DEATH = 'CIC_MEMBER_DEATH',
  CIC_MEMBER_CUSTODY = 'CIC_MEMBER_CUSTODY',
  CIC_MEMBER_JOB_COVERAGE = 'CIC_MEMBER_JOB_COVERAGE',
  CIC_MEMBER_STATUS_CHANGE = 'CIC_MEMBER_STATUS_CHANGE',
  CIC_REMOVING_MEMBERS = 'CIC_REMOVING_MEMBERS',
  CIC_POLICY_INVALID = 'CIC_POLICY_INVALID',
  CIC_NEW_PLANS_AVAILABLE = 'CIC_NEW_PLANS_AVAILABLE',
  CIC_NEW_PLAN_REQUIRED = 'CIC_NEW_PLAN_REQUIRED',
  CIC_PRELIM_ELIG = 'CIC_PRELIM_ELIG',
  CIC_MEMBER_CUT = 'CIC_MEMBER_CUT',
  CIC_SEP_MIXED = 'CIC_SEP_MIXED',
  CIC_SSN_ENTRY = 'CIC_SSN_ENTRY',
  CIC_INCOME_ENTRY = 'CIC_INCOME_ENTRY',
  EXPLORER_INTRO = 'EXPLORER_INTRO',
  EXPLORER_ZIPCODE = 'EXPLORER_ZIPCODE',
  EXPLORER_STATE_HANDOFF = 'EXPLORER_STATE_HANDOFF',
  EXPLORER_PARTNER_HANDOFF = 'EXPLORER_PARTNER_HANDOFF',
  EXPLORER_APPLICANTS = 'EXPLORER_APPLICANTS',
  EXPLORER_HOUSEHOLD = 'EXPLORER_HOUSEHOLD',
  EXPLORER_SAVINGS = 'EXPLORER_SAVINGS',
  EXPLORER_RISK_TOLERANCE = 'EXPLORER_RISK_TOLERANCE',
  EXPLORER_USAGE = 'EXPLORER_USAGE',
  EXPLORER_PRESCRIPTIONS = 'EXPLORER_PRESCRIPTIONS',
  EXPLORER_DOCTORS = 'EXPLORER_DOCTORS',
  EXPLORER_SEP_INELIGIBLE = 'EXPLORER_SEP_INELIGIBLE',
  EXPLORER_CONCIERGE = 'EXPLORER_CONCIERGE',
  EXPLORER_SCHEDULE = 'EXPLORER_SCHEDULE',
  EXPLORER_CHOOSE_PLAN = 'EXPLORER_CHOOSE_PLAN',
  EXPLORER_SEP = 'EXPLORER_SEP',
  EXPLORER_QLE = 'EXPLORER_QLE',
  STATE_RETURN = 'STATE_RETURN',
}
