import { create } from 'zustand';

// Subscribe to client errors from anywhere in the app

const states = {
  DOWN: 'down',
  OK: 'ok',
};

const useClientError = create((set, get) => ({
  // unless proven otherwise we're ok
  status: states.OK,
  setDown: () => set({ status: states.DOWN }),
  setOK: () => set({ status: states.OK }),
}));

export { useClientError };
