import React, { useCallback, useEffect, useMemo } from 'react';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { Link, Loading } from '@uikit';
import { useQuery, useDeprecatedMutation, getMostRecentEDN, EligibilityResultsQuery } from '@data';
import { navigate, open, exit } from '@navigate';
import { FlowLayout, Section, Split, Stack } from '@layouts';
import {
  WrongEligibilityDetermination,
  MedicaidDetermination,
  VoterRegistration,
  FileDropper,
} from '@common';
import { EligibilityGroupList, EligibilityIssuesList, EligibilityNextSteps } from '@app/partial';
import {
  getResultsForMembers,
  getEligibilityGroups,
  getApplicationIssues,
  getEligibilityNextSteps,
} from './eligibilityResultsUtils';
import { Route } from '@types';

const ApplicationResults = ({ applicationID, isGA }) => {
  const { c } = useCopy('catch');

  const { loading, data } = useQuery(EligibilityResultsQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const {
    groups,
    nextSteps,
    issuesSummary,
    asyncStatus,
    coverageYear,
    coverageState,
    stateDeterminesMedicaidEligibility,
    ineligibleForMedicaid,
    hasAvailablePlans,
    dsrsIdentifier,
    aptcHouseholdToApply,
  } = useMemo(() => {
    // pull fields from data
    const application = data?.viewerTwo?.health?.application;
    const members = application?.allMembers || [];
    const coverageYear = application?.coverageYearNumber;
    const oe = data?.reference?.health?.openEnrollmentDates;

    // during OE, if applying for oe year
    const isOEApplication = !!(oe?.isOpenEnrollment && coverageYear === oe?.oeCoverageYear);

    // computed
    const computed = getResultsForMembers(members, isOEApplication);
    const numPlans = application?.enrollmentGroups?.reduce(
      (sum, group) => sum + group.plans.total,
      0,
    );

    // gets the most recent EDN
    const mostRecentEDN = getMostRecentEDN(application?.ednDocuments);

    return {
      // computed results and next steps
      groups: getEligibilityGroups(computed),
      nextSteps: getEligibilityNextSteps(computed),
      issuesSummary: getApplicationIssues(application || { dmis: [], svis: [] }),

      // application information
      coverageYear,
      coverageState: application?.coverageState,
      isRequestingFinancialAssistance: application?.isRequestingFinancialAssistance,
      aptcHouseholdToApply: application?.aptcHouseholdToApply,
      stateDeterminesMedicaidEligibility:
        application?.stateReferenceData?.stateDeterminesMedicaidEligibility,
      hasAvailablePlans: numPlans > 0,
      ineligibleForMedicaid: members.filter((m) => m.canRequestFullMedicaidDetermination),

      // flags
      isOpenEnrollment: oe?.isOpenEnrollment,
      asyncStatus: application?.asyncStatus,

      // EDN
      dsrsIdentifier: mostRecentEDN?.dsrsIdentifier,
    };
  }, [data?.viewerTwo?.health?.application]);

  useEffect(() => {
    if (!loading) {
      Segment.track(SegmentEvent.APPLICATION_RESULTS_VIEWED, {
        can_enroll: !nextSteps.noneCanEnroll,
        coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
      });
    }
  }, [loading]);

  const [getDocument, { loading: gettingNotice }] = useDeprecatedMutation(
    'getGeneratedDocumentURL',
    {
      onCompleted: (data) => window.open(data.getGeneratedDocumentURL),
    },
  );

  const handleNext = useCallback(() => {
    nextSteps.allCanEnroll
      ? navigate(Route.EDE_PLANS)
      : nextSteps.someCanEnroll
      ? navigate(Route.EDE_SPLIT_ELIGIBILITY)
      : nextSteps.noneCanEnroll
      ? exit(Route.COVERAGE)
      : nextSteps.someNotEligibeForSEP
      ? navigate(Route.EDE_SEP_OUT_OF_DATE)
      : navigate(Route.EDE_PLANS);
  }, [nextSteps, loading]);

  const cta = useMemo(() => {
    if (nextSteps.allCanEnroll || nextSteps.someCanEnroll) {
      return c('basics.continue');
    }

    if (nextSteps.noneCanEnroll) {
      return c('basics.exit');
    }

    return c('basics.continue');
  }, [nextSteps, loading]);

  return (
    <FlowLayout
      nextButtonText={cta}
      onNext={handleNext}
      canClickNext={!loading}
      title={c('ede.EligibilityResults.title')}
      subtitle={c(`ede.EligibilityResults.async.${asyncStatus}`)}
    >
      {loading ? (
        <Loading accentColor="coverage" />
      ) : (
        <Stack separatorComponent spacing="8">
          <Split>
            <EligibilityGroupList
              applicationID={applicationID}
              coverageYear={coverageYear}
              groups={groups}
              appliedAmount={aptcHouseholdToApply}
              determinationType={
                stateDeterminesMedicaidEligibility ? 'DETERMINATION' : 'ASSESSMENT'
              }
            />
            <Stack spacing="2">
              <EligibilityNextSteps
                numCanEnroll={nextSteps.numCanEnroll}
                hasAvailablePlans={hasAvailablePlans}
                allMedicaidOrChip={nextSteps.allMedicaidOrChip}
                makeChanges={() => open(Route.EDE_HOUSEHOLD, { applicationID })}
                getNotice={() => getDocument({ variables: { input: { dsrsIdentifier } } })}
                gettingNotice={gettingNotice}
                handleNext={handleNext}
              />
              <EligibilityIssuesList issues={issuesSummary} />
            </Stack>
          </Split>

          {isGA && (
            <Split>
              <Section
                title={c('ede.EligibilityResults.gaAppeal.title')}
                subtitle={c('ede.EligibilityResults.gaAppeal.text', {
                  link: (
                    <Link testID="ga-appeals-form" href={c('gaAppeal.formLink', {}) || undefined}>
                      {c('ede.EligibilityResults.gaAppeal.formTitle')}
                    </Link>
                  ),
                })}
              >
                <Link
                  testID="ga-appeals-info"
                  href={c('ede.EligibilityResults.gaAppeal.infoLink', {}) || undefined}
                >
                  {c('ede.EligibilityResults.gaAppeal.infoTitle')}
                </Link>
              </Section>

              <FileDropper
                name="HEALTH_DMI"
                handleDrop={async ({ fileKey, fileType }) => {
                  // @TODO - determine how to handle georgia appeal form
                }}
              />
            </Split>
          )}

          {ineligibleForMedicaid?.length > 0 && (
            <MedicaidDetermination
              stateDeterminesMedicaidEligibility={stateDeterminesMedicaidEligibility}
              members={ineligibleForMedicaid}
              state={coverageState}
              applicationID={applicationID}
            />
          )}

          <WrongEligibilityDetermination />

          <VoterRegistration />
        </Stack>
      )}
    </FlowLayout>
  );
};

export const EligibilityResultsView = {
  name: Route.EDE_RESULTS,
  component: ApplicationResults,
  options: {
    title: 'Results',
  },
};
