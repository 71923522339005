import React, { useMemo } from 'react';

import { useCopy, handleHealthErrors } from '@app/utils';
import {
  useDeprecatedMutation,
  useMutation,
  mutations,
  useQuery,
  HealthApplicationQuery,
  getMembers,
} from '@data';
import { open } from '@navigate';
import { Button, Banner } from '@uikit';
import { Toolbar, BasicLayout } from '@layouts';
import { Route } from '@types';

const PREFIX = 'catch.ede.CIC.appReviewIntro';

const CICAppReviewIntro = ({ applicationID, change }) => {
  const { c } = useCopy(PREFIX);
  const message = ['BIRTH', 'ADOPTION', 'COVERAGE_GAIN', 'COVERAGE_LOSS'].includes(change);
  const childAddition = /BIRTH|ADOPTION/.test(change);

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const numTaxDependents = useMemo(() => {
    return getMembers(data)?.reduce((count, m) => count + (m?.relation === 'CHILD' ? 1 : 0), 0);
  }, [data]);

  const [report, { loading: reporting }] = useMutation(mutations.REPORT_HEALTH_ENROLLMENT_CHANGES, {
    variables: {
      input: {
        applicationInput: {
          id: applicationID,
          numTaxDependents: numTaxDependents + 1,
        },
        memberInputs: [{ applicationMember: { relation: 'CHILD' } }],
      },
    },
    onCompleted: (data) => {
      const { applicationID } = data?.reportHealthEnrollmentChanges?.insuranceEnrollment || {};
      open(Route.EDE_HOUSEHOLD, { applicationID });
    },
  });

  const [prefill, { loading: prefilling }] = useDeprecatedMutation('prefillHealthEnrollment', {
    onCompleted: (data) => {
      const enrollment = data?.prefillHealthInsuranceEnrollment?.insuranceEnrollment;
      open(Route.EDE_HOUSEHOLD, { applicationID: enrollment?.applicationID });
    },
    ...handleHealthErrors,
  });

  return (
    <BasicLayout
      loading={!data || reporting || prefilling}
      title={c('title')}
      subtitles={[c('subtitle1'), c('subtitle2')]}
      toolbar={
        <Toolbar>
          <Button
            disabled={reporting || prefilling}
            onPress={() => {
              childAddition ? report() : prefill();
            }}
          >
            {c('next')}
          </Button>
        </Toolbar>
      }
    >
      {message && <Banner title={c('message')} bg="skeleton" />}
    </BasicLayout>
  );
};

export const CICAppReviewIntroView = {
  name: 'CIC_APP_REVIEW_INTRO',
  component: CICAppReviewIntro,
  options: {},
};
