import React, { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { Button } from '@uikit';
import { useCopy } from '@app/utils';
import { useAuth } from '@client';
import { navigate } from '@navigate';
import { AuthBlueprint } from '@app/blueprints';
import { Route } from '@types';

export const ConfirmPassword = () => {
  const { c } = useCopy('catch.module.login');

  const { alias, confirmNewPassword, submitting, error } = useAuth(
    (state) => ({
      alias: state.temporaryCredentials.alias,
      submitting: state.loading,
      confirmNewPassword: state.confirmNewPassword,
      error: state.error,
    }),
    shallow,
  );

  useEffect(() => {
    if (!alias) navigate(Route.FORGOT_PASSWORD);
  }, [alias]);

  return (
    <AuthBlueprint
      loading={false}
      submitting={submitting}
      error={error}
      title={c('ConfirmNewPasswordView.title')}
      subtitle={c('ConfirmNewPasswordView.p', {
        emailAddress: alias,
      })}
      formConfig={{
        initialValues: {},
        fields: [
          {
            type: 'code',
            name: 'code',
            required: true,
          },
          {
            label: 'New password',
            type: 'password',
            name: 'newPassword',
            passwordType: 'new',
            required: true,
          },
        ],
        onSubmit: confirmNewPassword,
      }}
      actions={({ handleSubmit, disabled }) => (
        <Button testID="confirm-password" disabled={disabled} onPress={handleSubmit}>
          {c('PasswordResetConfirmationForm.submitButton')}
        </Button>
      )}
    />
  );
};

export const ConfirmPasswordView = {
  name: Route.CONFIRM_PASSWORD,
  component: ConfirmPassword,
  guest: true,
  options: {},
};
