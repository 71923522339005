import { generatePath } from 'react-router';
import { createLogger } from '@app/utils/logger';
import { ensnake } from '@app/utils/format_old/strings';
import { paths, Route } from '../paths';
import { history } from '../history';
import { store } from '../store/store';

const log = createLogger('navigate');

/**
 * Navigates as push
 */
export const open = (screenName: Route, data?: object) => {
  const options = store.getState().getOptions(screenName);

  if (!paths[screenName] || typeof paths[screenName] === 'undefined')
    log.error(`Route ${screenName} might not exist.`, [
      'is the route defined in @navigate/routes?',
      'is the component exported in the @features array?',
      'are the page options defined?',
    ]);

  // if there is already a flow open, continue in flow
  if (options?.layout === 'sheet') {
    // @ts-ignore
    const path = `${history.location.pathname}?flow=${ensnake(screenName)}`;

    // maintain current data so that screen underneath doesnt lose itself
    history.push(path, {
      ...store.getState().matchParams,
      ...history.location.state,
      ...data,
      canGoBack: false,
    });
  } else {
    // otherwise, push the new url to the stack
    const path = generatePath(paths[screenName], data);
    // @ts-ignore
    history.push(path, data);
  }
};
