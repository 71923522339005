import React from 'react';

import { ComplexRow, Text } from '@uikit';
import { getEventProperties } from '@app/utils';
import { useDocument } from '@hooks';

/**
 * tertiary: !!props?.time && /Failed|Pending/.test(props?.status) ? props?.status : props?.time,
 */
const ActivityEvent = ({ loading, event, actions, accentColor }) => {
  const { downloadDocument } = useDocument(event);
  const props = loading
    ? null
    : getEventProperties(
        event,
        {
          ...actions,
          downloadDocument,
        },
        { accentColor },
      );

  return !loading && !props ? null : (
    <ComplexRow
      onPress={props?.onPress}
      asset={{ ...props?.asset, shape: 'circle' }}
      label={props?.title}
      sublabel={props?.subtitle}
      sublabelColor={props.status === 'Failed' ? 'credit' : undefined}
      color={props?.color}
      accessory={
        <Text size="sm" color="subtle">
          {props?.time}
        </Text>
      }
    />
  );
};

export default ActivityEvent;
