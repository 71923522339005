import React from 'react';
import { shallow } from 'zustand/shallow';
import { useAuth, useAuthEvents } from '@client/Auth';
import { createLogger, Segment, SegmentEvent } from '@app/utils';
import { HealthPathway, OnboardingBenefit } from '@app/types';
import { getSignupContextOptions } from './util/context';
import { AuthBlueprint } from '@app/blueprints';
import { Button } from '@uikit';
import { Route } from '@types';
import { mutations, useMutation } from '@app/data';

const Log = createLogger('signup:view');

// needs to be shared with signin
interface RegisterComponentParams {
  initialVals?: any;
  benefit?: OnboardingBenefit;
  vertical?: OnboardingBenefit;
  r?: string;
  hr?: boolean;
  hid?: string;
  pathway?: HealthPathway;
  phone?: string;
  email?: string;
  zip?: string;
  partner?: string;
  next?: string;
  income?: string;
}

const ConfirmCode = ({
  initialVals,
  r: signupCode,
  hr: hideSignupCode,
  pathway,
  phone: initialPhone,
  email: initialEmail,
  zip,
  vertical,
  benefit,
  partner,
  income,
  next, // otherwise, this navigation prop gets sent on signup params and fails
  importing,
  ...additionalSignupParams
}: RegisterComponentParams) => {
  const [upsertHealthPrivacy] = useMutation(mutations.HEALTH_INITIAL_AGREE);

  const { confirmedExpressBenefit, requestedBenefit, signupContext, onboardingType } =
    getSignupContextOptions({ benefit, healthID: additionalSignupParams.hid, pathway });

  const { confirmCode, temporaryAlias, error, loading } = useAuth(
    (state) => ({
      temporaryAlias: state.temporaryCredentials.alias,
      loading: state.loading,
      step: state.authState,
      steps: state.states,
      signUp: state.signUp,
      confirmCode: state.confirmCode,
      error: state.error,
    }),
    shallow,
  );

  useAuthEvents({
    onPendingConfirmation: ({ temporaryAlias }) => {
      Segment.identifyUser(null, {
        alias: temporaryAlias,
        referral_link: document.referrer,
        initial_user: true,
      });
      Segment.pageView();

      Segment.track(SegmentEvent.REGISTRATION_SUBMITTED, { alias: temporaryAlias });
    },
    onSignedIn: ({ user, attributes }) => {
      if (!!user) {
        Segment.track(SegmentEvent.USER_CREATED, {
          alias: user.email || user.phoneNumber,
          signup_context: signupContext,
        });

        if (attributes?.params?.brokerConsent) {
          upsertHealthPrivacy({ variables: { input: { isPrivacyAuthorizeAgree: 'YES' } } });
        }
      }
    },
  });

  Log.debug({
    signupContext: {
      signupCode,
      hideSignupCode,
      pathway,
      initialPhone,
      initialEmail,
      zip,
      vertical,
      benefit,
      requestedBenefit,
      confirmedExpressBenefit,
      onboardingType,
      partner,
      ...additionalSignupParams,
    },
  });

  return (
    <AuthBlueprint<{}>
      loading={false}
      submitting={loading || importing}
      title={`Enter the code we sent to ${temporaryAlias}`}
      error={error}
      formConfig={{
        initialValues: {},
        fields: [{ type: 'code', name: 'code', alias: temporaryAlias, required: true }],
        onSubmit: confirmCode,
      }}
      actions={({ handleSubmit, disabled }) => (
        <Button testID="confirm-code" onPress={handleSubmit} disabled={disabled}>
          Next
        </Button>
      )}
    />
  );
};

export const ConfirmCodeView = {
  name: Route.CONFIRM_CODE,
  component: ConfirmCode,
  guest: true,
  options: {
    title: 'Enter the code',
    largeTitle: true,
    drawBehindNav: true,
  },
};
