import gql from 'graphql-tag';
import { default as client } from './Apollo';
import { toGQLDate } from '@app/utils/time';
import { formatPhone } from '@app/utils/format_old/misc';

const AUTH_USER = gql`
  query AuthUser {
    viewer {
      id
      user {
        id
        dob
        givenName
        familyName
        nickname
        phoneNumber
        email
      }
    }
  }
`;

export function fetchUser() {
  return client
    .query({
      query: AUTH_USER,
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => data?.viewer?.user);
}

const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      givenName
      familyName
      phoneNumber
      email
      dob
      workType
      legalAddress {
        zip
      }
      # Platform inconsistency: input is signupCode
      referralCode
    }
  }
`;

export function createUser({
  nickname,
  phoneNumber,
  email,
  givenName,
  familyName,
  signupCode,
  userScope,
  dob,
  zip,
  signupParams,
  signupContext,
}) {
  return client
    .mutate({
      mutation: CREATE_USER,
      variables: {
        input: {
          nickname,
          givenName,
          familyName,
          phoneNumber: phoneNumber ? formatPhone(phoneNumber.substring(2)) : undefined,
          email,
          signupCode,
          userScope,
          dob: toGQLDate(dob),
          legalAddress: {
            zip,
          },
          signupParams,
          signupContext,
        },
      },
    })
    .then(({ data }) => data.createUser);
}

const BLACKLIST_JWT = gql`
  mutation BlacklistJWT {
    logout
  }
`;

export function signOut() {
  return client
    .mutate({
      mutation: BLACKLIST_JWT,
    })
    .then(() => client.stop())
    .then(() => client.clearStore());
}
