import React, { useCallback, useMemo } from 'react';
import { MultiQuestionSplitFormBlueprint } from '@app/blueprints';
import { getMembers, HealthApplicationQuery, mutations, useMutation, useQuery } from '@data';
import {
  determineCoveredNonApplicantChild,
  determineDeprivationNavigation,
  determineOtherCoverage,
  determineOutsideHousehold,
  isMarketplacePrelim,
} from '@app/navigate/EDENavigationGuard';
import { navigate } from '@navigate';
import { questions } from './medicalBillsFields';
import { getInitialValues, formatPayload } from './medicalBillsUtils';
import { Route } from '@types';
import { Segment, SegmentEvent } from '@app/utils';

const MedicalBills = ({ applicationID }) => {
  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: upserting }] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const {
    members,
    stateDeprivationRequirementRetained,
    isRequestingFinancialAssistance,
    parsedQuestions,
  } = useMemo(() => {
    return {
      members: getMembers(data),
      parsedQuestions: data?.viewerTwo?.health?.application?.uiQuestionsToDisplay,
      isRequestingFinancialAssistance:
        data?.viewerTwo?.health?.application?.screening?.isRequestingFinancialAssistance,
      stateDeprivationRequirementRetained:
        data?.viewerTwo?.health?.application?.stateReferenceData?.deprivationRequirementRetained,
    };
  }, [data]);

  const handleNext = useCallback(() => {
    Segment.track(SegmentEvent.APPLICATION_MEDICAL_BILLS_COMPLETED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    if (determineCoveredNonApplicantChild(members, isRequestingFinancialAssistance)) {
      return navigate(Route.EDE_DEPENDENTS_CURRENT_COVERAGE);
    } else if (determineOutsideHousehold(members)) {
      return navigate(Route.EDE_OUTSIDE_HOUSEHOLD);
    } else if (determineDeprivationNavigation(parsedQuestions)) {
      return navigate(Route.EDE_MEMBER_WORK_HOURS);
    } else if (determineOtherCoverage(members)) {
      return navigate(Route.EDE_OTHER_COVERAGE);
    } else if (isMarketplacePrelim(members)) {
      return navigate(Route.EDE_SEP);
    } else {
      return navigate(Route.EDE_REVIEW);
    }
  }, [isRequestingFinancialAssistance, members, stateDeprivationRequirementRetained]);

  return (
    <MultiQuestionSplitFormBlueprint
      loading={loading}
      submitting={upserting}
      questions={questions}
      members={members}
      data={{ uiQuestionsToDisplay: parsedQuestions }}
      getInitialValues={(val) => getInitialValues(val)}
      onNext={(values) => upsert(formatPayload({ values, applicationID }))}
      onComplete={handleNext}
    />
  );
};

export const MedicalBillsView = {
  name: Route.EDE_MEMBER_MEDICAL_BILLS,
  component: MedicalBills,
  options: {
    ...MultiQuestionSplitFormBlueprint.options,
    title: 'Medical bills',
  },
};
