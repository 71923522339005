import { getCarrierLogo } from '@catch-co/health-utils';
import { AssetProps, AssetSize, PolicyStatusType } from '@types';

const statuses: Record<PolicyStatusType, string> = {
  ACTIVE: 'Active',
  PENDING_START_DATE: 'Upcoming',
  EXPIRED: 'Expired',
  INITIAL_ENROLLMENT: 'Initial',
  PENDING: 'Pending',
  IMPORT_PENDING: 'Pending',
  ACTIVE_PENDING_PAYMENT: 'Pending',
  TERMINATED: 'Cancelled',
  CANCELLED: 'Cancelled',
};

export const formatStatus = (status: PolicyStatusType) => {
  return statuses[status];
};

/**
 * Maps issure name to asset props
 * Returns object with either uri or icon to use in asset
 */
type CarrierAssetSize = 'md' | 'lg';

const innerSizeMap: Record<CarrierAssetSize, AssetSize> = {
  md: 'sm',
  lg: 'md',
};

export const getCarrierAsset = (
  issuer: string,
  enrollmentType: string = 'HEALTH_INSURANCE',
  size: CarrierAssetSize = 'lg',
): AssetProps => {
  const name = getCarrierLogo(issuer);

  const isDental =
    enrollmentType === 'DENTAL_INSURANCE' ||
    enrollmentType === 'Dental' ||
    enrollmentType === 'PLANTYPE_DENTAL';

  if (name && !/default|tommy|NOT AVAILABLE/.test(name)) {
    return { shape: 'circle', uri: `https://s.catch.co/img/carriers/${name}.png`, size };
  } else {
    return {
      shape: 'circle',
      render: isDental ? 'dental' : 'coverage',
      bg: 'coverageLight',
      containerSize: size,
      size: innerSizeMap[size],
    };
  }
};
