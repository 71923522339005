import gql from 'graphql-tag';

const paymentEvent = gql`
  fragment PaymentEvent on PaymentEvent {
    id
    payeeName
    amount
    deliveryDate
    goalType
    fee
    paymentStatus: status
  }
`;

export default {
  paymentEvent,
};
