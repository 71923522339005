import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';
import { navigate, ScreenDefinition } from '@navigate';
import { useAuth } from '@client';
import { FontSize, HealthPathway, OnboardingBenefit } from '@types';
import { AuthBlueprint } from '@app/blueprints';
import { Button, Link, Text } from '@uikit';
import { useCopy } from '@app/utils';
import { Stack } from '@app/layouts';
import { Route } from '@types';
import { Color } from '@app/styles';

interface LoginState {
  alias?: string;
  password?: string;
}

const LoginComponent: React.FC<{
  initialAlias: string;
  initialPassword: string;
  benefit?: OnboardingBenefit;
  vertical?: OnboardingBenefit;
  hid?: string;
  pathway?: HealthPathway;
  phone?: string;
  email?: string;
  next?: string;
  state?: string;
  income?: string;
  zip?: string;
}> = ({
  initialAlias,
  initialPassword,
  next,
  hid: healthID,
  email: initialEmail,
  phone: initialPhone,
  benefit,
  pathway,
  state,
  income,
  zip,
  importing,
}) => {
  const { c } = useCopy('catch.module.login.SignInForm');

  const [initialValues] = useState<LoginState>({
    alias: initialAlias || initialPhone || initialEmail,
    password: initialPassword,
  });

  const { signIn, submitting, error } = useAuth(
    (state) => ({
      signIn: state.signIn,
      submitting: state.loading,
      error: state.error,
    }),
    shallow,
  );

  const handleSubmit = ({ alias, password }) => {
    signIn({ alias, password });
  };

  return (
    <AuthBlueprint
      loading={false}
      submitting={submitting || importing}
      error={error}
      title="Welcome back"
      subtitle="Sign in to Catch"
      actions={({ handleSubmit, submitting, disabled }) => (
        <Button
          testID="sign-in"
          disabled={disabled}
          onPress={handleSubmit}
          loading={submitting}
          full
        >
          {c('submitButton')}
        </Button>
      )}
      formConfig={{
        initialValues,
        fields: [
          { type: 'alias', name: 'alias', required: true },
          {
            type: 'password',
            name: 'password',
            label: 'Password',
            passwordType: 'existing',
            requirements: false,
            required: true,
          },
        ],
        onSubmit: handleSubmit,
      }}
      postContent={
        <Stack spacing="1">
          <Link
            testID="forgot-password"
            size={FontSize.fp}
            color={Color.text}
            onPress={() => navigate(Route.FORGOT_PASSWORD)}
          >
            {c('passwordLink')}
          </Link>
          <Text color="subtle" size="fp">
            {c('signupLabel')}{' '}
            <Link
              testID="sign-up"
              size={FontSize.fp}
              color={Color.text}
              onPress={() =>
                navigate(Route.REGISTER, {
                  hid: healthID,
                  benefit,
                  zip,
                  income,
                  state,
                  pathway,
                  email: initialEmail,
                })
              }
            >
              {c('signupLink')}
            </Link>
          </Text>
        </Stack>
      }
    />
  );
};

export const LoginView: ScreenDefinition = {
  name: 'LOGIN',
  component: LoginComponent,
  guest: true,
  options: {
    title: 'Sign In',
    largeTitle: true,
    drawBehindNav: true,
  },
};
