import { FontSize, FontWeight } from '@app/types';
import { Theme, Color, ColorScheme } from '../types';

const theme: Omit<Theme, 'colors'> = {
  fonts: {
    sizes: {
      [FontSize.h1]: 32,
      [FontSize.h2]: 24,
      [FontSize.h3]: 20,
      [FontSize.h4]: 20,
      [FontSize.h5]: 16,
      [FontSize.h6]: 13,
      [FontSize.p]: 16,
      [FontSize.label]: 16,
      [FontSize.fp]: 15,
      [FontSize.btn]: 16,
      [FontSize.xs]: 11,
      [FontSize.sm]: 13,
      [FontSize.lg]: 16,
      [FontSize.xl]: 48,
    },
    weights: {
      [FontWeight.light]: 300,
      [FontWeight.regular]: 400,
      [FontWeight.medium]: 500,
      [FontWeight.semibold]: 600,
      [FontWeight.bold]: 700,
    },
  },
  // these are all min widths
  breakpoints: {
    s: 500,
    m: 600,
    l: 720,
    xl: 840,
  },
  containers: {
    dialog: 360,
    actionSheet: 360,
    formSheet: 480,
    pageSheet: 640,
    largeSheet: 640,
    miniPage: 640,
    basic: 800,
    page: 1080,
  },
  constants: {
    blur: 24,
    buttonHeight: 46,
    borderWidth: 1.5,
    checkRadioSize: 18,
    modalBlur: 16,
    disabledOpacity: 0.35,
    promptWidthSm: 280,
    promptWidthLg: 320,
    rootHeaderHeight: 320,
  },
};

export const themes: Record<ColorScheme, Theme> = {
  [ColorScheme.light]: {
    ...theme,
    colors: {
      [Color.text]: '#100F13',
      [Color.subtle]: '#100F13A6',
      [Color.fg]: '#100F13',
      [Color.fg2]: '#110E144C',
      [Color.fg3]: '#00000088',
      [Color.bg]: '#FFFFFF',
      [Color.bgTransparent]: 'transparent',
      [Color.bgSheet]: '#FFFFFF',
      [Color.bgCard]: '#FFFFFF',
      [Color.border]: '#100F1311',
      [Color.bg2]: '#100F1316',
      [Color.bg3]: '#100F1308',
      [Color.fade]: '#FFFFFFF2',
      [Color.bgGray]: '#F5F3F6',
      [Color.void]: '#EEEEEE',
      [Color.veil]: '#00000088',
      [Color.acrylic]: '#FFFFFFAA',
      [Color.brand]: '#9B1DFF',
      [Color.brandLight]: '#EBD2FF',
      [Color.coverage]: '#9B1DFF',
      [Color.coverageLight]: '#EBD2FF',
      [Color.positive]: '#01C187',
      [Color.positiveHover]: '#01C18750',
      [Color.positiveLight]: '#01C18733',
      [Color.medium]: '#FFAD05',
      [Color.mediumLight]: '#FFE8C9',
      [Color.negative]: '#F91515',
      [Color.negativeLight]: '#F9151533',
      [Color.inputBg]: '#F9F8FA',
      [Color.snow]: '#FFFFFF',
      [Color.system]: '#007AFF',
    },
    gradients: {
      brand: ['#9D11D9', '#7F09DB'],
      brandLight: ['#F7D7FF', '#FADDF4', '#FFE8E0'],
      brandSmall: ['#9C11D9', '#8516DB'],
      coverage: ['#EE4B7B', '#D83177'],
      coverageLight: ['#F7D7FF', '#FADDF4', '#FFE8E0'],
      coverageSmall: ['#9C11D9', '#8516DB'],
      income: ['#9D11D9', '#7F09DB'],
      incomeLight: ['#F9E2FF', '#FFF9FC', '#FFE8E0'],
      incomeSmall: ['#9C11D9', '#8516DB'],
      lobby: ['#F7E0FC', '#E0E3FF'],
      retirement: ['#17CFDC', '#17B9D7'],
      retirementLight: ['#DEFFF9', '#EBFEFF', '#E1F8FF'],
      retirementSmall: ['#17CFDC', '#17B9D7'],
      saved: ['#D7FA2C', '#7ACB17'],
      savedLight: ['#F7FFD4', '#F8FFF2', '#E5FFDC'],
      savedSmall: ['#99DA0B', '#76CB10'],
      taxes: ['#FFAD05', '#F36602'],
      taxesLight: ['#FFE8E1', '#FFF9F3', '#FFF3CA'],
      taxesSmall: ['#FA8E04', '#F36803'],
      positive: ['#01C187', '#01C187'],
      positiveLight: ['#01C18733', '#01C18744'],
      medium: ['#FFAD05', '#FFAD05'],
      mediumLight: ['#FFAD0533', '#FFAD0533'],
      negative: ['#F91515', '#F91515'],
      negativeLight: ['#F9151533', '#F9151544'],
    },
  },
  [ColorScheme.dark]: {
    ...theme,
    colors: {
      [Color.text]: '#FFFFFF',
      [Color.subtle]: '#FFFFFF99',
      [Color.fg]: '#FFFFFF',
      [Color.fg2]: '#FFFFFFCC',
      [Color.fg3]: '#FFFFFF73',
      [Color.bg]: '#111012',
      [Color.bgTransparent]: 'transparent',
      [Color.bgSheet]: '#262527',
      [Color.bgCard]: '#FFFFFF19',
      [Color.bg2]: '#FFFFFF19',
      [Color.bg3]: '#FFFFFF08',
      [Color.fade]: '#00000010',
      [Color.border]: '#FFFFFF19',
      [Color.bgGray]: '#333234',
      [Color.void]: '#EEEEEE',
      [Color.veil]: '#00000088',
      [Color.acrylic]: '#3A3B3FAA',
      [Color.brand]: '#A540F7',
      [Color.brandLight]: '#7F11D942',
      [Color.coverage]: '#A540F7',
      [Color.coverageLight]: '#7F11D942',
      [Color.positive]: '#00C78B',
      [Color.positiveHover]: '#01C18766',
      [Color.positiveLight]: '#01C18733',
      [Color.medium]: '#FF8800',
      [Color.mediumLight]: '#FF880033',
      [Color.negative]: '#CB484A',
      [Color.negativeLight]: '#CB484A33',
      [Color.inputBg]: '#FFFFFF0D',
      [Color.snow]: '#FFFFFF',
      [Color.system]: '#007AFF',
    },
    gradients: {
      lobby: ['#2B1433', '#141836'],
      brand: ['#9D11D9', '#7F09DB'],
      brandSmall: ['#9C11D9', '#8516DB'],
      brandLight: ['#E16BFF26', '#F07EB71A', '#FF916B26'],
      coverage: ['#EE4B7B', '#D83177'],
      coverageSmall: ['#9C11D9', '#8516DB'],
      coverageLight: ['#E16BFF26', '#F07EB71A', '#FF916B26'],
      income: ['#9D11D9', '#7F09DB'],
      incomeLight: ['#E16BFF26', '#F07EB71A', '#FF916B26'],
      incomeSmall: ['#9C11D9', '#8516DB'],
      retirement: ['#17CFDC', '#17B9D7'],
      retirementLight: ['#4BF3F61A', '#4BF3F61A', '#68DBFF26'],
      retirementSmall: ['#50ABB6', '#45959E'],
      saved: ['#D7FA2C', '#7ACB17'],
      savedLight: ['#E5FF6826', '#BBFF681A', '#8FFF6826'],
      savedSmall: ['#99DA0B', '#76CB10'],
      taxes: ['#FFAD05', '#F36602'],
      taxesLight: ['#FF7E5226', '#FFAB521A', '#FFD95226'],
      taxesSmall: ['#FA8E04', '#F36803'],
      positive: ['#00C78B', '#00C78B'],
      positiveLight: ['#00C78B33', '#00C78B44'],
      medium: ['#FFAD05', '#FFAD05'],
      mediumLight: ['#FFAD0533', '#FFAD0533'],
      negative: ['#CB0F0F', '#CB0F0F'],
      negativeLight: ['#CB0F0F33', '#CB0F0F44'],
    },
  },
};
