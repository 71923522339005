import React, { useCallback, useMemo } from 'react';

import {
  getMembers,
  HealthApplicationQuery,
  mutations,
  useDeprecatedMutation,
  useMutation,
  useQuery,
} from '@data';
import { navigate, sheets, useSheet } from '@navigate';
import { Currency, handleHealthResponse, Segment, SegmentEvent, useCopy } from '@util';
import {
  determineCoveredNonApplicantChild,
  determineDeprivationNavigation,
  determineOtherCoverage,
  determineOutsideHousehold,
  isMarketplacePrelim,
} from '@navigate/EDENavigationGuard';
import { formatPayload, getInitialValues, getTotalIncomeForMember } from './incomeConfirmUtils';
import { fields } from './incomeConfirmFields';
import { Stack } from '@layouts';
import { LineItem } from '@uikit';
import { HealthMemberIncomeRow } from '@common';
import { MultiMemberSplitFormBlueprint } from '@blueprints';
import { shallow } from 'zustand/shallow';
import { loaders } from '@app/config';
import { Route } from '@app/types';

const PREFIX = 'catch.ede.IncomeConfirm';

const IncomeConfirm = ({ applicationID, enrollId }) => {
  if (!enrollId) {
    navigate(Route.HEALTH_RESUME);
  }

  const { c } = useCopy(PREFIX);

  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { coverageYear, isRequestingFinancialAssistance, parsedQuestions, members } =
    useMemo(() => {
      return {
        coverageYear: data?.viewerTwo?.health?.application?.coverageYearNumber,
        isRequestingFinancialAssistance:
          data?.viewerTwo?.health?.application?.screening?.isRequestingFinancialAssistance,
        parsedQuestions: data?.viewerTwo?.health?.application?.uiQuestionsToDisplay,
        members: getMembers(data),
      };
    }, [data]);

  const { openSheet, closeAll } = useSheet(
    (state) => ({
      openSheet: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT),
      closeAll: state.closeAll,
    }),
    shallow,
  );

  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS, handleHealthResponse({}));

  const [ensure, { loading: ensuring }] = useDeprecatedMutation('ensureHealthApplication', {
    onCompleted: ({ isIncomeDifferent, members }) => {
      const isMedicaidPrelim = members.some((d) => d.preliminaryMedicaidStatus === 'YES');

      Segment.track(SegmentEvent.APPLICATION_INCOME_CONFIRMED, {
        coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
      });

      if (isIncomeDifferent) {
        navigate(Route.EDE_MEMBER_INCOME_DISCREPANCY);
      } else if (isMedicaidPrelim) {
        navigate(Route.EDE_MEMBER_MEDICAL_BILLS);
      } else if (determineCoveredNonApplicantChild(members, isRequestingFinancialAssistance)) {
        navigate(Route.EDE_DEPENDENTS_CURRENT_COVERAGE);
      } else if (determineOutsideHousehold(members)) {
        navigate(Route.EDE_OUTSIDE_HOUSEHOLD);
      } else if (determineDeprivationNavigation(parsedQuestions)) {
        navigate(Route.EDE_MEMBER_WORK_HOURS);
      } else if (determineOtherCoverage(members)) {
        navigate(Route.EDE_OTHER_COVERAGE);
      } else if (isMarketplacePrelim(members)) {
        navigate(Route.EDE_SEP);
      } else {
        navigate(Route.EDE_REVIEW);
      }
    },
  });

  const saveMember = useCallback(
    (values) => {
      return upsert({
        variables: {
          input: {
            applicationID,
            applicationMembersInput: formatPayload({ values }),
          },
        },
      });
    },
    [applicationID],
  );

  const getTitle = useCallback(
    (member) => {
      const givenName = member?.givenName;
      return c('loadingTitle', { givenName, coverageYear, loading });
    },
    [coverageYear, loading],
  );
  const getSubTitle = useCallback(
    (member) => {
      const givenName = member?.givenName;
      return c('subtitle', { givenName, coverageYear });
    },
    [coverageYear],
  );

  const onSubmit = useCallback(async () => {
    openSheet(sheets.LOADER);
    await ensure({ variables: { applicationID } });
    closeAll();
  }, [ensure]);

  return (
    <MultiMemberSplitFormBlueprint
      members={members}
      loading={loading}
      submitting={ensuring}
      title={getTitle}
      subtitle={getSubTitle}
      getInitialValues={getInitialValues}
      data={{ coverageYear }}
      fields={fields}
      onNext={saveMember}
      onComplete={onSubmit}
      precontent={({ member }) => (
        <Stack spacing="3" style={{ marginBottom: 60 }}>
          <Stack spacing="1">
            <LineItem
              noSpace
              prominent={true}
              left={c('sectionTitle')}
              right={<Currency whole>{getTotalIncomeForMember(member) || 0}</Currency>}
            />
            <>
              {member?.incomeSources?.map((i, index) => (
                <HealthMemberIncomeRow key={index} incomeSource={i} />
              ))}
              {member?.deductions?.map((d, index) => (
                <HealthMemberIncomeRow key={index} incomeSource={d} expense={true} />
              ))}
            </>
          </Stack>
        </Stack>
      )}
    />
  );
};

export const IncomeConfirmView = {
  name: Route.EDE_MEMBER_INCOME_CONFIRM,
  component: IncomeConfirm,
  options: {
    ...MultiMemberSplitFormBlueprint.options,
    title: 'Income',
  },
};
