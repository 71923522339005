import { Asset } from '@uikit';
import { TransferStatusBadgeType } from '@app/types';

import { date as fDate } from '../format/date';
import { lowercase, capitalize } from '../format_old/strings';
import { formatBenefit } from '../format_old/benefit';
import { fromTimestamp } from '../format_old/toDate';
import { accountRef } from '../format_old/bank';

import Deposit from '@svg/arrow-down-right.svg';
import Payment from '@svg/arrow-up-right.svg';
import Plus from '@svg/plus.svg';
import Transfer from '@svg/swap.svg';
import Withdraw from '@svg/arrow-up-left.svg';
import Percent from '@svg/percentage.svg';
import Pause from '@svg/pause.svg';
import Star from '@svg/star.svg';
import Heart from '@svg/heart.svg';
import Never from '@svg/minus-circle.svg';
import Roll from '@svg/catch-roll.svg';
import File from '@svg/file.svg';
import Shield from '@svg/shield.svg';
import Play from '@svg/play.svg';
import Chart from '@svg/chart.svg';
import Check from '@svg/check.svg';
import List from '@svg/unordered-list.svg';
import Close from '@svg/close.svg';

/**
 * Mapping of icons for activity events
 */
const activityEventIcons = {
  AUTOBADGE: Roll,
  NEVERBADGE: Never,
  DEPOSIT: Deposit,
  WITHDRAW: Withdraw,
  TRANSFER: Transfer,
  PAYMENT: Payment,
  CHART: Chart,
  HEART: Heart,
  STAR: Star,
  PLAY: Play,
  PAUSE: Pause,
  PERCENT: Percent,
  ADD: Plus,
  REMOVE: Never,
  LIST: List,
  PAID: Plus,
  SHIELD: Shield,
  DOCUMENT: File,
  DEFAULT: List,
  CHECK: Check,
  CLOSE: Close,
};

const ASSET_SIZE = 'sm';

/**
 * TABLE OF CONTENTS
 * Helpers for formatting rows and receipts for activity events
 *
 * Assets:
 * - formatIcon
 * - formatSource
 *
 * Titles:
 * - formatGoalEvent: "Tax Withholding updated"
 * - formatHealthEvent: "Health application submitted"
 * - formatAutopilotAction: "Ingored source rule paused"
 * - formatUnknownEvent: "Some new thing happened"
 *
 * Subtitles:
 * - formatGoalPercentage: "10% per paycheck"
 * - formatPaycheckAmount: "from $100 paycheck"
 * - formatFeeType: "Autopilot"
 * - formatGoalForGenericContext: "Emergency Savings" (but only on plan page!)
 * - formatAutopilotSource: "for Airbnb"
 *
 * Colors:
 * - formatHealthColor: color based on type
 * - formatAutopilotColor: color based on rule automation type
 *
 * Amounts:
 * - formatContributionAmount: total on plan, but goal amount on plan detail
 * - formatAmount: general formatting for the amount
 *
 * Date:
 * - formatDate: checks status for status flag, otherwise returns formatted date
 *
 * Actions:
 * - showReceiptAction: returns invocation for showReceipt on actions
 *
 * Status:
 * - formatStatus: maps statuses to one of FAILED, PENDING, COMPLETED
 *
 * Helpers:
 * - matchByTransferDirection: for goal-to-goal transfers, matches
 *   whether we're looking generally, withdrawing from, or depositing to
 *
 */

const pendingStyle = {
  opacity: 0.25,
  borderWidth: 1,
  borderStyle: 'dashed',
};
const failedStyle = { filter: 'saturate(0)', opacity: 0.25 };
const skippedStyle = { filter: 'saturate(0)' };

export const formatIcon = (icon, { color, fullWidth, bold, pending, failed }) => ({
  svg: activityEventIcons[icon || 'DEFAULT'],
  size: !fullWidth ? 'xs' : ASSET_SIZE,
  containerSize: ASSET_SIZE,
  color: bold ? 'bg' : 'text',
  bg: color && color !== 'text' ? (bold ? color : `${color}Light`) : 'grayBg',
  shape: 'circle',
  style: failed ? failedStyle : pending ? pendingStyle : {},
  borderColor: pending ? 'textColor' : undefined,
});

// allows fallback type for deposit source
export const formatSource = (event) => {
  const src = Asset.configureSource(event?.source);

  // checks badge types..
  const badge = formatBadge(event);

  return {
    ...src,
    size: ASSET_SIZE,
    shape: 'circle',
    border: true,
    shadow: event?.isSkipped || event?.status === 'SKIPPED' ? undefined : 'major',
    badge,
    style:
      event?.status === 'CANCELED' || event?.transferStatus === 'FAILED'
        ? failedStyle
        : event?.isSkipped
        ? skippedStyle
        : event?.transferStatus === 'PROCESSING' || event?.status === 'TRANSACTION_STATUS_PENDING'
        ? pendingStyle
        : {},
  };
};

export const formatBadge = ({
  type,
  isAutopilot,
  automationType,
  status,
  __typename,
}): TransferStatusBadgeType => {
  // for contributions:
  if (__typename === 'TransferEvent' || __typename === 'IncomeTransaction') {
    if (status === 'DELAYED') return 'scheduled';
    if (status === 'SKIPPED') return 'skipped';
    if (status === 'NOT_INCOME') return 'never';
    if (status === 'FAILED') return 'failed';
    if (status === 'TRANSACTION_STATUS_PENDING') return 'pending';
    if (status === 'TRANSACTION_STATUS_SETTLED') return 'completed';
    if (status === 'TRANSACTION_STATUS_CANCELED') return 'failed';
    // return isAutopilot ? 'auto' : 'deposit';
  }

  // for autopilot rules that have been added, use the automation type
  // to show either auto or never badge
  if (/AutopilotRule/.test(__typename) && autopilotRuleDirection({ __typename }) > 0) {
    return automationType === 'ALWAYS' ? 'auto' : 'never';
  }

  // otherwise, don't show a badge
  return null;
};

export const formatAutoIcon = ({ type, automationType, __typename, source }) => {
  if (automationType === 'ALWAYS') {
    return {
      uri: source?.taggedSource?.logo?.url,
      size: ASSET_SIZE,
      containerSize: ASSET_SIZE,
      shape: 'circle',
      disabled: autopilotRuleDirection({ __typename }) < 0,
    };
  } else {
    return {
      uri: source?.taggedSource?.logo?.url,
      size: ASSET_SIZE,
      containerSize: ASSET_SIZE,
      shape: 'circle',
      disabled: autopilotRuleDirection({ __typename }) < 0,
    };
  }
};

/**
 * Given an event with event type and the goal,
 * uses the typename to generate readable event title
 *
 * e.g. GoalAddedEvent -> Retirement added
 */
export const formatGoalEvent = ({ goal, __typename }) => {
  const verb = __typename.replace('Event', '').replace('Goal', '');
  return `${formatBenefit.name(goal)} ${lowercase(verb)}`;
};

/**
 * Converts something separated by _ to readable
 */
export const normalizeType = ({ type }) => {
  if (!type) return '';
  const words = type?.split('_');
  return lowercase(words?.join(' '));
};

/**
 * Given an event for a health application or enrollment,
 * uses the subtype to generate a readable event title
 *
 * e.g. APPLICATION_SUBMITTED -> Health application submitted
 */
export const formatHealthEvent = ({ type }) => {
  return `Health ${lowercase(normalizeType({ type }))}`;
};

export const formatDentalEvent = ({ type }) => {
  return `Dental ${lowercase(normalizeType({ type }))}`;
};

/**
 * Given autopilot rule event along with a type,
 * returns the title by mapping automation type to name and using verb from event
 *
 * e.g. AutopilotRuleAddedEvent for ALWAYS rule -> Autopilot rule added
 */
export const formatAutopilotAction = ({ automationType, __typename, source }) => {
  const verb = autopilotRuleDirection({ __typename }) > 0 ? 'added' : 'changed';
  return `${source?.text} ${lowercase(verb)}`;
};

/**
 * Checks the direction of autopilot rule movement
 * i.e. was the rule added or removed
 *
 * returns 1 for adding a rule, -1 for removing the rule, 0 if unknown
 */
export const autopilotRuleDirection = ({ __typename }) => {
  if (/Added|Resumed/.test(__typename)) return 1;
  if (/Removed|Paused/.test(__typename)) return -1;
};

/**
 * Given only a typename, returns a default title
 * e.g. SomethingHappenedEvent -> Something happened
 */
export const formatUnknownEvent = ({ __typename }) => {
  const stripEvent = __typename.replace('Event', '');
  const words = stripEvent.split(/(?=[A-Z])/);
  return capitalize(words.join(' '));
};

/**
 * Given a goal paycheck percentage,
 * returns subtitle copy for X% per paycheck
 */
export const formatGoalPercentage = ({ percentage }) => {
  if (!percentage) return '';
  return `${Math.round(percentage * 100)}% per paycheck`;
};

/**
 * Given the amount for a contributed paycheck,
 * returns subtitle copy for "from $543.40 paycheck"
 */
export const formatPaycheckAmount = ({ incomeAmount }) => {
  if (!incomeAmount) return 'from paycheck';
  return `from $${incomeAmount?.toFixed(2)} paycheck`;
};

/**
 * Given the Catch fee type on the event,
 * returns readable subtitle copy for fee
 *
 * e.g. VIDEO_ONBOARDING_FEE -> Video Onboarding
 */
export const formatFeeType = ({ type }) => type;

/**
 * Given the goal and context,
 * returns the formatted goal name
 * BUT only when in the generic context
 *
 * e.g. "Family Leave" on plan detail, but "" on plan
 */
export const formatGoalForGenericContext = ({ context, goal }) => {
  if (!!context?.goalID) return '';
  return formatBenefit.name(goal);
};

/**
 * Given the source for rule,
 * returns formatted autopilot source
 */
export const formatAutopilotSource = ({ source }) => {
  return !!source?.text ? `for ${source?.text}` : '';
};

/**
 * Given the health enrollment/application event types,
 * for canceled/ended events, show in red
 * for submitted/enrolled events, show in green
 */
export const formatHealthColor = ({ type }) => {
  if (/SUBMIT|ENROLL/.test(type)) return 'debit';
  if (/CANCEL|END/.test(type)) return 'credit';
  return 'text';
};

/**
 * Given the rule automation type,
 * returns algae color for ALWAYS and default text for all else
 */
export const formatAutopilotColor = ({ automationType }) => {
  return automationType === 'ALWAYS' ? 'text' : 'text';
};

/**
 * Given context, transaction records, and total amount
 * if goal-specific context, returns the amount contributed to that goal
 * otherwise, returns the total amount contributed
 */
export const formatContributionAmount = ({
  context,
  incomeAmount,
  amount,
  transactionRecords,
  status,
}) => {
  if (/SKIPPED|NOT_INCOME/.test(status)) return null;
  if (!context?.goalID) return amount;

  // otherwise, return amount from record for goal in current context
  return transactionRecords.reduce((found, rec) => {
    return rec?.goal?.id === context?.goalID ? rec?.amount : found;
  }, amount);
};

/**
 * Given the amount and the color to display in, formats the amount
 * - plus/minus sign is showed based on the color
 * - for text colors, we dont show any sign
 */
export const formatAmount = (amount, color) => {
  // use the absolute value in case amount was negative
  const number = Math.abs(amount).toFixed(2);

  // don't show any sign unlless the color matches below
  let sign = '';
  if (/debit|success/.test(color)) sign = '+ ';
  if (/credit|error/.test(color)) sign = '- ';

  return `${sign}$${number}`;
};

/**
 * Given the timestamp and the event status,
 * - checks first for pending/failed statuses (and shows that)
 * - otherwise, formats the date
 */
export const formatDate = ({ time, status }) => {
  // for upcoming dates, show slightly differently...
  if (status === 'DELAYED') return fromTimestamp(time);

  // returns formatted time
  return !!time ? fDate(time, 'RELATIVE')?.replace('today', 'Today') : '';
};

/**
 * Given the set of possible actions,
 * returns a fn to invoke show receipt w/the event
 */
export const showReceiptAction = (event, actions) => () => actions?.showReceipt(event);

export const showScheduled = (event, actions) => () => actions?.showScheduled(event);

/**
 * Given the event status,
 * maps it to one of COMPLETED, FAILED, or PENDING
 */
export const formatStatus = ({ status }) => {
  if (/SUCCESS|COMPLETED|SETTLED/.test(status)) return 'COMPLETED';
  if (/FAILED/.test(status)) return 'FAILED';
  if (/UNKNOWN|INITIAL|SENT|PROCESSING|EXECUTED/.test(status)) return 'PENDING';
  if (/CANCELED|CANCELLED/.test(status)) return 'CANCELED';
  if (/SKIPPED/.test(status)) return 'SKIPPED';
  return '';
};

export const formatAccount = ({ account }) => {
  return accountRef({
    bankName: account?.bankLink?.bankName,
    accountName: account?.name,
    accountNumber: account?.accountNumber,
  });
};

/**
 * Given the current context and list of transaction records
 * and a results mapping (for source, destination, and default),
 * checks the context to see which
 *
 * e.g. for a transfer from TAX to RETIREMENT, we would return:
 * - the default result while on the plan page
 * - the source result while on the TAX page
 * - the destination result while on the RETIREMENT page
 */
export const matchByTransferDirection = ({ context, transactionRecords }, results) => {
  // catches the case where we don't have two goals
  if (transactionRecords?.length !== 2) return results?.default;

  // specifies the destination and source goals
  const destination = transactionRecords[0]?.goal;
  const source = transactionRecords[1]?.goal;

  // matches the contet and returns corresponding result
  if (context?.goalID === source.id) return results?.source;
  if (context?.goalID === destination.id) return results?.destination;
  return results?.default;
};
