import { gql } from '../generated';

export const EligibilityResultsQuery = gql(`
  query EligibilityResults($applicationID: ID!) {
    viewerTwo {
      id
      health {
        application(id: $applicationID) {
          id
          lastUsedRoute
          coverageYearNumber
          coverageState
          isRequestingFinancialAssistance
          asyncStatus
          aptcHouseholdToApply

          stateReferenceData {
            stateDeterminesMedicaidEligibility
          }

          enrollmentGroups {
            id
            plans {
              total
            }
          }

          dmis {
            id
            dependentID
            status
            resolveBy
            subType
          }
          svis {
            id
            dependentIDs
            statusCode
            status
            resolveBy
          }
          ednDocuments {
            id
            ...EDNDocument
          }

          allMembers {
            id
            givenName
            familyName
            isRequestingCoverage
            canRequestFullMedicaidDetermination
            metalLevelOption {
              currentPlanOnlyIndicator
            }
            exchangeEligibility {
              startDate
              endDate
            }
            aptcEligibility {
              startDate
              endDate
              amount
              change
            }
            csrEligibility {
              startDate
              endDate
              csrLevel
              change
            }
            sepEligibility {
              startDate
              endDate
              sepReason
            }
            chipEligibility {
              startDate
              endDate
            }
            medicaidEligibility {
              startDate
              endDate
            }
            initialEnrollmentEligibility {
              startDate
              endDate
            }
          }
        }
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
`);
