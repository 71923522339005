import { Route } from '@types';

/**
 * Mapping for internal navigation
 * - Keys are internal paths like PLAN
 * - Values are url endpoints like /plan
 * - The endpoint is used for urls on web and for screen IDs with RNN
 */
export const paths: Record<Route, string> = {
  //login
  [Route.CONSENT]: '/auth/consent',
  [Route.LOGIN]: '/auth/sign-in',
  [Route.REGISTER]: '/auth/sign-up',
  [Route.CONFIRM_CODE]: '/auth/confirm',
  [Route.FORGOT_PASSWORD]: '/auth/forgot-password',
  [Route.CONFIRM_PASSWORD]: '/auth/forgot-password/confirm',

  // home
  [Route.HOME]: '/',
  [Route.DEV]: '/dev',

  [Route.EVENT_RECEIPT]: '/activity/receipt',

  // coverage
  [Route.COVERAGE]: '/coverage',
  [Route.COVERAGE_DETAIL]: '/coverage/:type/overview',
  [Route.WALLET_ITEM]: '/wallet',

  // profile
  [Route.ACCOUNT]: '/me',
  [Route.ACCOUNT_INFO]: '/me/info',
  [Route.ACCOUNT_SETTINGS]: '/me/settings',
  [Route.STATEMENTS]: '/me/statements',

  [Route.CHANGE_ALIAS]: '/update-email',
  [Route.CHANGE_PASSWORD]: '/update-password',
  [Route.EDIT_USER_DETAILS]: '/edit-details',

  // standalone health pages
  [Route.HEALTH_RESUME]: '/health/resume',

  // health application flow
  [Route.EDE_INTRO]: '/health/application/intro',
  [Route.EDE_COVERAGE_INFO]: '/health/application/coverage-info',
  [Route.EDE_PRIVACY]: '/health/application/privacy',
  [Route.EDE_HOUSEHOLD]: '/health/application/household',
  [Route.EDE_SCREENING]: '/health/application/screening',
  [Route.EDE_PRO_ASSISTANCE]: '/health/application/pro-assistance',
  [Route.EDE_ID_INFO]: '/health/application/id-info',
  [Route.EDE_IDENTITY]: '/health/application/identity',
  [Route.EDE_APPLICATION_IMPORT]: '/health/application/application-import',
  [Route.EDE_CONFIRM_APPLICANTS]: '/health/application/confirm-applicants',
  [Route.EDE_WINDOW_SHOPPING]: '/health/application/window-shopping',
  [Route.EDE_MEMBER_INFO]: '/health/application/member-info',
  [Route.EDE_MEMBER_QUESTIONS]: '/health/application/member-questions',
  [Route.EDE_IMMIGRATION_DETAILS]: '/health/application/immigration-details',
  [Route.EDE_MEDICAID_DENIAL]: '/health/application/medicaid-denial',
  [Route.EDE_MEMBER_INCOME_LIST]: '/health/application/member-income-list',
  [Route.EDE_MEMBER_DEDUCTIONS_LIST]: '/health/application/member-deductions-list',
  [Route.EDE_MEMBER_INCOME_DETAILS]: '/health/application/member-income-details',
  [Route.EDE_MEMBER_INCOME_CONFIRM]: '/health/application/member-income-confirm',
  [Route.EDE_MEMBER_INCOME_DISCREPANCY]: '/health/application/member-income-discrepancy',
  [Route.EDE_SEP]: '/health/application/sep',
  [Route.EDE_OUTSIDE_HOUSEHOLD]: '/health/application/outside-household',
  [Route.EDE_DEPENDENTS_CURRENT_COVERAGE]: '/health/application/dependents-current-coverage',
  [Route.EDE_MEMBER_MEDICAL_BILLS]: '/health/application/member-medical-bills',
  [Route.EDE_MEMBER_WORK_HOURS]: '/health/application/member-work-hours',
  [Route.EDE_OTHER_COVERAGE]: '/health/application/other-coverage',
  [Route.EDE_REVIEW]: '/health/application/review',
  [Route.EDE_AGREEMENTS]: '/health/application/agreements',
  [Route.EDE_RESULTS]: '/health/application/results',
  [Route.HEALTH_DOCS]: '/health/application/docs',
  [Route.HEALTH_DOCS_UPLOAD]: '/health/docs/upload',
  [Route.HEALTH_DOCS_UPLOADED]: '/health/docs/success',
  [Route.EDE_SPLIT_ELIGIBILITY]: '/health/application/split-eligibility',
  [Route.EDE_PLANS]: '/health/application/plans',
  [Route.EDE_APTC_AGREEMENT]: '/health/application/aptc-agreement',
  [Route.EDE_ENROLL]: '/health/application/enroll',
  [Route.EDE_SUCCESS]: '/health/application/success',
  [Route.EDE_SEP_OUT_OF_DATE]: '/health/application/sep-out-of-date',
  [Route.EDE_HELP]: '/health/application/contact-support',

  // CiC: Change in Circumstances
  [Route.CIC_MANAGE_COVERAGE_INTRO]: '/coverage-change/:type/cic-manage-coverage',
  [Route.CIC_CHANGE_SELECTION]: '/coverage-change/:type/cic-change-selection',
  [Route.CIC_BASICS]: '/coverage-change/:type/cic-basics',
  [Route.CIC_ADDRESS_ENTRY]: '/coverage-change/:type/cic-address-entry',
  [Route.CIC_NO_CHANGE]: '/coverage-change/:type/cic-no-change',
  [Route.CIC_STATE_HANDOFF]: '/coverage-change/:type/cic-state-handoff',
  [Route.CIC_STATE_PICKUP]: '/coverage-change/:type/cic-state-pickup',
  [Route.CIC_CANCEL_POLICY]: '/coverage-change/:type/cic-cancel-policy',
  [Route.CIC_GOV_HANDOFF]: '/coverage-change/:type/cic-gov-handoff',
  [Route.CIC_GOV_PICKUP]: '/coverage-change/:type/cic-gov-pickup',
  [Route.CIC_APP_REVIEW_INTRO]: '/coverage-change/:type/cic-app-review-intro',
  [Route.CIC_MEMBER_MARRIAGE]: '/coverage-change/:type/cic-member-marriage',
  [Route.CIC_MEMBER_CUT_INTRO]: '/coverage-change/:type/cic-member-cut-intro',
  [Route.CIC_MOVE_DATE_ENTRY]: '/coverage-change/:type/cic-move-date-entry',
  [Route.CIC_MEMBER_DIVORCE]: '/coverage-change/:type/cic-member-divorce',
  [Route.CIC_MEMBER_DEATH]: '/coverage-change/:type/cic-member-death',
  [Route.CIC_MEMBER_CUSTODY]: '/coverage-change/:type/cic-member-custody',
  [Route.CIC_MEMBER_JOB_COVERAGE]: '/coverage-change/:type/cic-member-job-coverage',
  [Route.CIC_MEMBER_STATUS_CHANGE]: '/coverage-change/:type/cic-member-status-change',
  [Route.CIC_REMOVING_MEMBERS]: '/coverage-change/:type/cic-removing-members',
  [Route.CIC_POLICY_INVALID]: '/coverage-change/:type/cic-policy-invalid',
  [Route.CIC_NEW_PLANS_AVAILABLE]: '/coverage-change/:type/cic-new-plans-available',
  [Route.CIC_NEW_PLAN_REQUIRED]: '/coverage-change/:type/cic-new-plan-required',
  [Route.CIC_PRELIM_ELIG]: '/coverage-change/:type/cic-prelim-eligible',
  [Route.CIC_MEMBER_CUT]: '/coverage-change/:type/cic-member-cut',
  [Route.CIC_SEP_MIXED]: '/coverage-change/:type/cic-sep-mixed',
  [Route.CIC_SSN_ENTRY]: '/coverage-change/:type/cic-ssn-entry',
  [Route.CIC_INCOME_ENTRY]: '/coverage-change/:type/cic-income-entry',

  // health link
  [Route.HEALTH_LINK_INTRO]: '/health/link/intro',
  [Route.HEALTH_LINK_AGREEMENTS]: '/health/link/agreements',
  [Route.HEALTH_LINK_IDENTITY]: '/health/link/identity',
  [Route.HEALTH_LINK_IMPORT]: '/health/link/import',

  // explorer
  [Route.EXPLORER_INTRO]: '/health/explorer/intro',
  [Route.EXPLORER_ZIPCODE]: '/health/explorer/zip',
  [Route.EXPLORER_APPLICANTS]: '/health/explorer/applicants',
  [Route.EXPLORER_STATE_HANDOFF]: '/health/explorer/state',
  [Route.EXPLORER_PARTNER_HANDOFF]: '/health/explorer/handoff',
  [Route.EXPLORER_HOUSEHOLD]: '/health/explorer/household',
  [Route.EXPLORER_SAVINGS]: '/health/explorer/savings',
  [Route.EXPLORER_RISK_TOLERANCE]: '/health/explorer/risk',
  [Route.EXPLORER_USAGE]: '/health/explorer/usage',
  [Route.EXPLORER_PRESCRIPTIONS]: '/health/explorer/prescriptions',
  [Route.EXPLORER_DOCTORS]: '/health/explorer/doctors',
  [Route.EXPLORER_CONCIERGE]: '/health/explorer/concierge',
  [Route.EXPLORER_SCHEDULE]: '/health/explorer/schedule',
  [Route.EXPLORER_CHOOSE_PLAN]: '/health/explorer/plan',
  [Route.EXPLORER_SEP]: '/health/explorer/sep',
  [Route.EXPLORER_SEP_INELIGIBLE]: '/health/explorer/sep-ineligible',
  [Route.EXPLORER_QLE]: '/health/explorer/qle',
  [Route.STATE_RETURN]: '/health/explorer/state-return',
};
