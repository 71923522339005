import React from 'react';
import { View } from 'react-native';
import { Copy } from '@types';
import { Layout, useLayoutContext } from '@layouts';
import { Spinner } from './Spinner';
import { useTheme } from '../hooks/useTheme';
import { UIAccentColor } from '../types';

interface LoadingProps<T extends string> {
  accentColor?: UIAccentColor;
  status?: T;
  titles?: Record<T, Copy>;
  subtitles?: Record<T, Copy>;
  full?: boolean;
  page?: boolean;
  style?: object;
}

export const Loading = <T extends string>({
  status,
  titles,
  subtitles,
  style,
  full,
  page,
  accentColor,
}: LoadingProps<T>) => {
  const { theme } = useTheme();
  const context = useLayoutContext();
  const title = status && titles ? titles[status] : undefined;
  const subtitle = status && subtitles ? subtitles[status] : undefined;

  return (
    <Layout
      margins
      style={[
        !!page && theme.pageBg,
        styles.container,
        full && styles.fullHeight,
        theme.bottomSpace6,

        style,
      ]}
    >
      <View style={theme.yGutter3}>
        <Spinner
          large
          color={accentColor || context?.accentColor}
          done={status === 'done'}
          error={status === 'error'}
        />
      </View>
      {(!!title || !!subtitle) && (
        <Layout.Header light title={title} subtitle={subtitle} align="center" />
      )}
    </Layout>
  );
};

const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullHeight: {
    height: '100%',
  },
};

export default Loading;
