import { Benefit, OnboardingBenefit, OnboardingType } from '@types';
import { SignupContext } from '@app/types';
import { BENEFITS, uppercase } from '@util';
import { stacks } from '@navigate';
import { StartOptions } from '@navigate/types';
import { Route } from '@types';

const signupContextMap: Record<OnboardingBenefit, SignupContext> = {
  HEALTH: 'HEALTH_APPLICATION',
  HEALTH_EXPRESS: 'HEALTH_APPLICATION',
  HEALTH_LINK: 'HEALTH_LINK',
  HEALTH_RENEW: 'HEALTH_RENEW',
  HEALTH_SLASH: 'PREMIUM_SLASHER',
  TAX: 'TAX',
  RETIREMENT: 'RETIREMENT',
};

export const getSignupContextOptions = ({
  healthID,
  benefit,
}): {
  usedPublicExplorer: boolean;
  requestedBenefit: string;
  properBenefit: Benefit;
  confirmedExpressBenefit: OnboardingBenefit;
  signupContext: SignupContext;
  onboardingType: OnboardingType;
} => {
  const usedPublicExplorer = !!healthID;
  const requestedBenefit = signupContextMap[uppercase(benefit)];
  const isHealthExpress = requestedBenefit === 'HEALTH_APPLICATION';
  const isPremiumSlasher = requestedBenefit === 'PREMIUM_SLASHER';
  const isHealthRenew = requestedBenefit === 'HEALTH_RENEW';

  return {
    usedPublicExplorer,
    signupContext: requestedBenefit,
    requestedBenefit: requestedBenefit,
    confirmedExpressBenefit: requestedBenefit,
    properBenefit: BENEFITS[requestedBenefit],
    onboardingType: isHealthRenew
      ? 'HealthRenew'
      : isPremiumSlasher
      ? 'PremiumSlasher'
      : usedPublicExplorer || isHealthExpress
      ? 'HealthApplication'
      : // : isHealthExpress
      // ? 'HealthExplorer'
      requestedBenefit
      ? 'GoalSetup'
      : 'Full',
  };
};

export const getExitOptions = ({
  confirmedExpressBenefit,
  onboardingType,
}): StartOptions | undefined => {
  switch (onboardingType) {
    case 'HealthApplication':
      return {
        initialTab: stacks.COVERAGE_STACK,
        open: Route.EDE_INTRO,
      };
    default:
      return {
        initialTab: stacks.COVERAGE_STACK,
        open: Route.EXPLORER_ZIPCODE,
      };
  }
};
