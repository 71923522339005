import gql from 'graphql-tag';

const VERIFY_EMAIL = gql`
  mutation _VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      error
      personId
    }
  }
`;

export default {
  document: VERIFY_EMAIL,
};
