import gql from 'graphql-tag';
import { HealthApplicationStatus, HealthPathwayType, HealthApplicationContext } from '@types';
import { ApolloOpenEnrollmentDates, OpenEnrollmentFragment } from '../fragments';

export interface HealthProgressLookupApplicationData {
  id: string;
  applicationContext: HealthApplicationContext;
  status: HealthApplicationStatus;
  coverageYearNumber: number;
  coverageState: string;
  pathwayType: HealthPathwayType;
  lastUsedRoute: string;
  isGeorgiaAccess: boolean;
  enrollment: {
    id: string;
  };
}

export interface HealthProgressLookupQueryData {
  viewerTwo: {
    health: {
      applications: Array<HealthProgressLookupApplicationData>;
    };
    healthExplorerData: {
      id: string;
      canCreateEnrollment?: boolean;
      state?: string;
      pathwayType?: HealthPathwayType;
    };
    agreements: {
      id: string;
      isPrivacyTruthfulAgree?: boolean;
      isPrivacyAuthorizeAgree?: boolean;
      isPrivacyDataUseAgree?: boolean;
    };
  };
  reference: {
    health: {
      openEnrollmentDates: ApolloOpenEnrollmentDates;
    };
  };
}

export const HealthProgressLookupQuery = gql`
  query HealthProgressLookupQuery($coverageYears: [Int!]!) {
    viewerTwo {
      id
      health {
        applications(
          input: {
            coverageYearNumber: $coverageYears
            statuses: [IN_PROGRESS, DRAFT, SUBMITTED, ENROLLMENT_STUCK]
          }
        ) {
          id
          status
          coverageYearNumber
          coverageState
          isGeorgiaAccess @client
          pathwayType
          applicationContext
          lastUsedRoute
          enrollment {
            id
          }
        }
      }
      healthExplorerData {
        id
        canCreateEnrollment
        state
        pathwayType
      }
      agreements {
        id
        isPrivacyTruthfulAgree
        isPrivacyAuthorizeAgree
        isPrivacyDataUseAgree
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
  ${OpenEnrollmentFragment}
`;
