import React, { useMemo } from 'react';
import { navigate } from '@navigate';
import { useMutation, mutations, useQuery, HealthApplicationQuery, getMembers } from '@data';
import { MultiQuestionSplitFormBlueprint } from '@app/blueprints';
import { Route } from '@types';
import { questions } from './memberQuestionsFields';
import { formatPayload, getInitialValues } from './memberQuestionsUtils';
import { Segment, SegmentEvent, constants, renderQuestions } from '@app/utils';

/**
 * MemberDetailsView
 * Page where users add information for Phase 2 questions
 */
const MemberQuestions = ({ applicationID, coverageYear, coverageState }) => {
  const { loading, data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { members, parsedQuestions, renderImmigrationQuestions, isRequestingFinancialAssistance } =
    useMemo(() => {
      const application = data?.viewerTwo?.health?.application;
      const uiQuestionsToDisplay = application?.uiQuestionsToDisplay || {};
      const { renderImmigrationQuestions } = renderQuestions({ uiQuestionsToDisplay });

      return {
        isRequestingFinancialAssistance: application?.screening?.isRequestingFinancialAssistance,
        members: getMembers(data),
        parsedQuestions: uiQuestionsToDisplay,
        renderImmigrationQuestions,
      };
    }, [data]);

  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const handleNext = () => {
    Segment.track(SegmentEvent.APPLICATION_MEMBER_QUESTIONS_COMPLETED, {
      coverage_year: coverageYear,
    });

    if (renderImmigrationQuestions) {
      navigate(Route.EDE_IMMIGRATION_DETAILS);
    } else if (isRequestingFinancialAssistance) {
      navigate(Route.EDE_MEDICAID_DENIAL);
    } else {
      navigate(Route.EDE_SEP);
    }
  };

  return (
    <MultiQuestionSplitFormBlueprint
      loading={loading}
      submitting={false}
      questions={questions}
      members={members}
      data={{
        uiQuestionsToDisplay: parsedQuestions,
        medicaid: constants?.medicaidCHIPNames?.[coverageState]?.medicaid,
      }}
      getInitialValues={(val) => getInitialValues(val)}
      onNext={(values, question) => {
        upsert(
          formatPayload({
            applicationID,
            questionID: question?.testID,
            members: values.members,
          }),
        );
      }}
      onComplete={handleNext}
    />
  );
};

export const MemberQuestionsView = {
  name: Route.EDE_MEMBER_QUESTIONS,
  component: MemberQuestions,
  options: {
    ...MultiQuestionSplitFormBlueprint.options,
    title: 'Personal details',
  },
};
