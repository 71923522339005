import React from 'react';
import { useCopy } from '@app/utils';
import { Bullet, Link, Text, Accordion } from '@uikit';
import { Stack } from '@app/layouts';

const WrongEligibilityDetermination = () => {
  const { c } = useCopy('catch.ede.EligibilityResults');

  return (
    <Accordion title={c('whatIfWrong')}>
      <Stack spacing="1">
        <Text fluid>{c('wrong.p1')}</Text>
        <Text fluid>{c('wrong.p2')}</Text>
        <Text fluid>{c('wrong.p3')}</Text>
        <Text fluid>{c('wrong.p4')}</Text>
        <Bullet text={c('wrong.bullet1')} />
        <Bullet text={c('wrong.bullet2')} />
        <Bullet text={c('wrong.bullet3')} />
        <Bullet text={c('wrong.bullet4')} />
        <Link testID="appeal" href="https://healthcare.gov/marketplace-appeals/">
          {c('learnMoreAppeal')}
        </Link>
        <Text fluid>{c('mailingInstructions')}</Text>
      </Stack>
    </Accordion>
  );
};

export default WrongEligibilityDetermination;
