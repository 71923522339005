import React from 'react';
import {
  useMutation,
  mutations,
  UpsertExplorerResponse,
  UpsertExplorerVars,
  useQuery,
  updates,
  HealthExplorerQuery,
} from '@data';
import { Segment, SegmentEvent, useCopy } from '@app/utils';
import { ScreenDefinition } from '@navigate';
import { HealthZipTriage } from '@partial';
import { useHealthStore } from '@app/client/useHealthStore';
import { Route } from '@types';

const ExplorerZipCode = ({ handleNext }) => {
  const { c } = useCopy('catch');
  const { setHealthContext } = useHealthStore();
  const { data } = useQuery(HealthExplorerQuery);

  const [upsert, { loading: upserting }] = useMutation<UpsertExplorerResponse, UpsertExplorerVars>(
    mutations.UPSERT_EXPLORER,
    { update: updates.UPDATE_EXPLORER },
  );

  const onSubmit = ({ coverageYear, location }) => {
    upsert({
      variables: {
        input: {
          coverageYear,
          countyfips: location.fips,
          state: location.state,
          zipcode: location.zip,
          pathwayType: location.pathway,
        },
      },
    });

    if (location.state === 'GA') {
      setHealthContext('GA');
    }

    Segment.track(SegmentEvent.HX_ZIP_COMPLETED, {
      coverage_year: coverageYear,
      fips: location.fips,
      state: location.state,
      zip: location.zip,
      pathway: location.pathway,
    });

    handleNext({ coverageYear });
  };

  return (
    <HealthZipTriage
      loading={!data}
      submitting={upserting}
      title={c('healthExplorer.explorerZip.OEPtitle')}
      subtitle={c('healthExplorer.explorerZip.subtitle')}
      oeDates={data?.reference?.health?.openEnrollmentDates}
      onSubmit={onSubmit}
    />
  );
};

export const ExplorerZipCodeView: ScreenDefinition = {
  name: Route.EXPLORER_ZIPCODE,
  component: ExplorerZipCode,
  options: {},
};
