import React from 'react';
import { View } from 'react-native';
import { Copy } from '@types';
import { ResponsiveStyleShape, useResponsive, CatchTheme, Loading, Modal } from '@uikit';
import { Layout } from './Layout';
import Page from './Page';
import HeaderGradient from './HeaderGradient';

interface SplitLayoutProps {
  scrollRef: any;
  loading: boolean;
  title: Copy;
  subtitle: Copy;

  /**
   * @TODO
   * since the split layout only works on larger screens,
   * this prop will decide how to handle secondary content on mobile screens
   */
  handleContent: 'HIDDEN' | 'SHEET' | 'STACK';

  // for handling a sheet case
  isOpen?: boolean;
  onRequestClose?: () => void;

  // either one or two children (if the second is defined, goes to left hand side)
  children?: React.ReactElement | [React.ReactElement, React.ReactElement];
  toolbar?: React.ReactElement;
  sideToolbar?: React.ReactElement;
}

const SplitLayout: React.FC<SplitLayoutProps> = ({
  scrollRef,
  loading,
  title,
  subtitle,
  handleContent,
  toolbar,
  sideToolbar,
  isOpen,
  onRequestClose,
  children,
}) => {
  // splits into left and right based on children (max 2 children can be passed!)
  const { isMobile, res } = useResponsive(responsive);

  const [content, side] = React.Children.toArray(children);
  const header = <Layout.Header bottomSpace title={title} subtitle={subtitle} />;

  if (isMobile) {
    return (
      <>
        <Layout ref={scrollRef} margins bottomSpace hasToolbar={!!toolbar}>
          {header}
          {loading ? (
            <Loading />
          ) : (
            <>
              {content}
              {handleContent === 'STACK' ? side : null}
            </>
          )}
        </Layout>
        {React.cloneElement(toolbar, { fixed: true })}
        {handleContent === 'SHEET' && (
          <Modal isOpen={!!isOpen} onRequestClose={onRequestClose || (() => {})} close>
            <Page>
              <Layout.Scroll margins>{side}</Layout.Scroll>
              {sideToolbar}
            </Page>
          </Modal>
        )}
      </>
    );
  }

  return (
    <>
      <View ref={scrollRef} style={styles.container}>
        <View style={styles.split}>
          <View style={[styles.leftPane, res.gutters]}>
            <View style={styles.content}>
              {header}
              {loading ? <Loading /> : content}
            </View>
          </View>
          <View style={[styles.rightPane]}>
            <View style={styles.help}>{loading ? null : side}</View>
          </View>
        </View>
      </View>
      <View style={styles.toolbarContainer}>
        <View style={styles.toolbarGutters}>
          <View style={styles.toolbarAlignment}>{toolbar}</View>
        </View>
      </View>
      <HeaderGradient inherit hidden={isMobile} style={styles.gradientContainer} />
    </>
  );
};

const responsive: ResponsiveStyleShape<'gutters'> = {
  gutters: {
    browser: {
      paddingHorizontal: 64, // @todo pull from theme?
    },
    default: {
      paddingHorizontal: CatchTheme.spacing.mobile.margins,
    },
  },
};

const styles = {
  container: {
    overflow: 'scroll',
    marginTop: -64,
    paddingTop: 64,
    flexShrink: 'revert',
  },
  split: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '100%',
    flex: 1,
  },
  help: {
    position: 'fixed',
    height: 'min-content',
    paddingLeft: 64,
    paddingRight: 64,
    overflow: 'scroll',
    width: '-webkit-fill-available',
    maxHeight: 'calc(100vh - 64px)',
  },
  toolbarContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '60%',
    flexShrink: 'revert',
  },
  toolbarGutters: {
    paddingLeft: 64,
    paddingRight: 64,
    display: 'block',
  },
  toolbarAlignment: {
    maxWidth: CatchTheme.containers.formSheet,
    marginLeft: 'auto',
  },
  gradientContainer: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '40%',
    height: 'calc(100% + 64px)',
    zIndex: -1,
  },
  pane: {
    display: 'flex',
  },
  content: {
    maxWidth: CatchTheme.containers.formSheet,
    width: '100%',
    paddingBottom: 96 + 16,
  },
  leftPane: {
    width: '60%',
    alignItems: 'flex-end',
  },
  rightPane: {
    flex: '40%',
    alignItems: 'flex-start',
    width: '100%',
  },
};

// @ts-ignore
SplitLayout.options = {
  drawBehindNav: true,
  navMode: 'split',
};

export default SplitLayout;
