import { gql } from '../generated';

export const UserQuery = gql(`
  query User {
    viewer {
      id
      user {
        id
        nickname
        legalName @client
        givenName
        familyName
        email
        phoneNumber
        avatarImage
        dob
        jobTitle
        workType
        filingStatus
        estimated1099Income
        estimatedW2Income
        estimatedIncome @client
        workState
        spouseIncome
        numTaxDependents
        signupContext
        referralCode
        dba
        ein
        ssn
        legalAddress {
          street1
          street2
          city
          state
          zip
        }
        createdOn
      }
    }
  }
`);
