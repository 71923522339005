import React from 'react';
import { Dropzone, DropzoneProps, useResponsive } from '@uikit';
import { useCopy } from '@app/utils';
import { useUpload } from '@client';

import UploadIcon from '@svg/upload-cloud.svg';
import { UploadData } from '@app/client/useUpload';

const PREFIX = 'catch.FileDropper';

export interface FileDropperProps {
  loading?: boolean;
  disabled?: boolean;
  name: string;
  label: string;
  sublabel?: string;
  filePrefix: string;
  copyPrefix?: string;
  display?: DropzoneProps['display'];
  icon?: string;
  handleDrop: (data: UploadData) => void;
}

const FileDropper = ({
  loading,
  disabled,
  handleDrop,
  filePrefix,
  copyPrefix,
  name,
  display,
  icon,
}: FileDropperProps) => {
  const { c } = useCopy(copyPrefix || PREFIX);

  const { upload } = useUpload({
    filePrefix,
    onSuccess: handleDrop,
  });

  const { isDesktop } = useResponsive();

  return (
    <Dropzone
      name={name}
      onUpload={upload}
      label={isDesktop ? c('plhTitle.desktop') : c('plhTitle.mobile')}
      sublabel={isDesktop ? c('plhCaption.desktop') : undefined}
      disabled={disabled}
      display={display}
      svg={icon || UploadIcon}
    />
  );
};
export default FileDropper;
