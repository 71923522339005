import React, { useState } from 'react';
import { View } from 'react-native';
import { SheetBlueprint } from '@app/blueprints';
import { Stack, useLayoutContext } from '@app/layouts';
import { Text } from './Text';
import { useTheme } from '../hooks/useTheme';
import { sheets, useSheet } from '@app/navigate';
import { Icon } from './Icon';
import { Dialog } from './Dialog';
import { UIAccentColor } from '../types';

interface HelpTextProps {
  content: string | React.ReactNode[];
  offset?: number;
}

export const HelpTextInline: React.FC<HelpTextProps> = ({ content }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { themeColors } = useTheme();
  const { accentColor } = useLayoutContext();

  if (!content) return null;

  return (
    <>
      <View
        style={{
          borderLeftColor: themeColors[`${accentColor}Color`],
          borderLeftWidth: 2,
          padding: 24,
          maxHeight: `calc(100vh - 32px)`,
          overflow: 'scroll',
        }}
      >
        <Stack spacing="1">
          <Text size="fp" color="subtle">
            {content}
          </Text>
        </Stack>
      </View>
      <Dialog
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        accentColor={accentColor as UIAccentColor}
      >
        <SheetBlueprint>
          <Text>{content}</Text>
        </SheetBlueprint>
      </Dialog>
    </>
  );
};

export const HelpText: React.FC<HelpTextProps> = ({ content }) => {
  const { accentColor } = useLayoutContext();
  const { open } = useSheet();

  const handlePress = () => {
    open(sheets.HELP_TEXT, { subtitle: content, accentColor });
  };

  return <Icon contained={false} type="Info" onPress={handlePress} />;
};

export default HelpText;
