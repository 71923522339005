import React from 'react';
import { Stack } from '../../layouts';
import { TextAlign, AssetProps } from '@types';
import FormLabel from './FormLabel';
import Option from './Option';

type PossibleValue = string | number;

type OptionProps = {
  key: string;
  value: PossibleValue;
  label: string;
  description?: string;
  asset?: AssetProps;
  tooltip?: boolean;
  align?: TextAlign;
  onHelp?: any;
};

interface Props {
  onPress?: (value?: PossibleValue) => void;
  onFocus?: () => void;
  input: any;
  options: OptionProps[];
  horizontal?: boolean;
  fluidText?: boolean;
  multi?: boolean;
  selectedOption?: PossibleValue;
  selectedOptions?: Array<PossibleValue>;
  label?: string;
  sublabel?: string;
  help?: string;
  disabled?: boolean;
  optional: boolean;
  small?: boolean;
}

/**
 * OptionGroup
 * shows a set of options in a group
 *
 * @todo display component should be separate from input handling
 */
export const OptionGroup = ({
  testID,
  onPress,
  onFocus,
  options,
  input,
  horizontal,
  fluidText,
  selectedOption,
  selectedOptions,
  multi,
  disabled,
  label,
  sublabel,
  help,
  optional,
  children,
  error,
  small,
  ...props
}: Props) => {
  const handlePress = (value) => {
    if (input) input.onChange(value); // updates the input value with input/form
    if (onPress) onPress(value); // if additional handler is set, use it
  };

  return (
    // @ts-ignore
    <Stack spacing="1">
      {!!label && (
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
      )}
      <Stack
        spacing={small ? '+gap' : '+gap'}
        horizontal={horizontal}
        itemStyle={horizontal && { flex: 1 }}
      >
        {(options || [])?.map((option) => {
          return (
            <Option
              small={small}
              key={option.key || option?.value}
              testID={`${testID || input?.name}-${option?.value}`}
              disabled={disabled || option.disabled}
              align={horizontal ? 'center' : undefined}
              onPress={() => handlePress(option?.value)}
              onFocus={onFocus}
              label={option?.label}
              asset={option?.asset}
              tooltip={option?.tooltip}
              onHelp={option?.onHelp}
              description={option?.description}
              showCheckmark={multi}
              error={error}
              {...props}
              selected={
                input?.value === option?.value ||
                selectedOption === option?.value ||
                selectedOptions?.includes(option?.value)
              }
            />
          );
        })}
      </Stack>
      {children}
    </Stack>
  );
};

export default OptionGroup;
