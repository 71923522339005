import gql from 'graphql-tag';

/**
 * Plan issuer
 * @type {[type]}
 */
export const ISSUER = gql`
  fragment Issuer on Issuer {
    id
    name
  }
`;

export const ELIGIBILITY = gql`
  fragment Eligibility on EligibilitySummary {
    totalAptc
    csrLevel
  }
`;
/**
 * Details for health plan benefits
 */
export const BENEFITS = gql`
  fragment Benefits on Benefits {
    name
    covered
    costSharings {
      coinsuranceOptions
      coinsuranceRate
      copayAmount
      copayOptions
      networkTier
      csr
      displayString
    }
    explanation
    hasLimits
    limitUnit
    limitQuantity
  }
`;

/**
 * Details for health plans
 */
export const HEALTH_PLAN = gql`
  fragment HealthPlan on HealthPlan {
    planId: id
    name
    type
    metalLevel
    premium
    premiumWithCredit
    aptcEligiblePremium
    ehbPremium
    hsaEligible
    brochureURL
    issuer {
      id
      ...Issuer
    }
    benefits {
      ...Benefits
    }
    moops {
      amount
      familyCost
      type
    }
    deductibles {
      amount
      familyCost
      type
    }
  }
  ${ISSUER}
  ${BENEFITS}
`;

/**
 * Selected health providers
 */
export const PROVIDER = gql`
  fragment Provider on HealthProvider {
    npi
    name
    isCovered
  }
`;

/**
 * Selected prescriptions
 */
export const DRUG = gql`
  fragment Drug on Drug {
    rxcui
    name
    isCovered
    isGenericCovered
  }
`;

/**
 * Scored health plans
 * - Includes the plan, along with selected provider/drugs
 */
export const SCORED_HEALTH_PLAN = gql`
  fragment ScoredHealthPlan on ScoredHealthPlan {
    planId: id
    healthPlan {
      planId: id
      ...HealthPlan
    }
    isCatchRecommended
    isBudgetPick
    isComprehensive
    providerCoverage {
      ...Provider
    }
    drugCoverage {
      ...Drug
    }
  }
  ${HEALTH_PLAN}
  ${PROVIDER}
  ${DRUG}
`;

/**
 * The recommended plans
 * - Highest recommendation from catch
 * - Budget plan
 * - Comprehensive plan
 */
export const TOP_PLANS = gql`
  fragment TopPlans on TopPlans {
    catchRecommended {
      planID: id
      ...ScoredHealthPlan
    }
    budgetPlan {
      planID: id
      ...ScoredHealthPlan
    }
    comprehensivePlan {
      planID: id
      ...ScoredHealthPlan
    }
  }
  ${SCORED_HEALTH_PLAN}
`;
