/**
 * index.js is our main(). It brings together all required libraries and
 * polyfills to render our React application
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import Env from '@app/utils/env';

// Setup
import AppProvider from './providers/AppProvider';
import App from './App';
import '@radix-ui/themes/styles.css';
import './index.css';

import { default as apolloClient } from '@client/Apollo';
import { translationMessages, init as initLogger, Log, initAnalytics } from '@app/utils';

// Logger will log messages in dev and prod or send
// them to Sentry in production
initLogger();
Log.info(Env, 'env');

function setup() {
  if (!Env.isLocal) {
    initAnalytics(Env.analytics.segmentKey, Env.isDevLike);
  }

  const root = createRoot(document.getElementById('app') as HTMLElement);

  root.render(
    <AppProvider client={apolloClient} messages={translationMessages}>
      <App />
    </AppProvider>,
  );
}

setup();
