import React from 'react';

import { s } from '@app/_ui-kit/theme/static';
import Stack from './Stack';
import { Text } from '@app/_ui-kit';

interface Props {
  children?: any;
  alt?: boolean;
  textLeft?: any;
  textRight?: any;
  loading?: boolean;
}

const ButtonGroup = ({ alt, children, textLeft, textRight, loading }: Props) => {
  const buttons = React.Children.toArray(children);
  const buttonProps = !!alt ? { alt: true } : {};

  return (
    <Stack
      spacing="1"
      horizontal
      wide={buttons.length === 1}
      full={buttons.length > 1}
      itemStyle={[s.flex1, s?.centered]}
    >
      {buttons.map((button: any, i) => (
        <React.Fragment key={i}>
          {(!!textLeft || !!textRight) && (
            <Text
              key={`btn-${button.key || i}`}
              align="center"
              size="h2"
              weight="medium"
              loading={loading}
            >
              {i === 0 ? textLeft : textRight}
            </Text>
          )}
          {React.cloneElement(button, { key: button.key, full: true, ...buttonProps })}
        </React.Fragment>
      ))}
    </Stack>
  );
};

export default ButtonGroup;
