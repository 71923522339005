import React from 'react';
import { View } from 'react-native';

import { useTheme } from '../hooks/useTheme';
import { Text } from './Text';
import { Stack } from '@app/layouts';

interface Props {
  thin?: boolean;
  right?: any;
  left?: any;
  prominent?: boolean;
  loading?: boolean;
  testID?: string;
  mono?: boolean;
}

export const LineItem = ({ prominent, loading, left, right, testID, mono }: Props) => {
  const { theme } = useTheme();

  return (
    <Stack horizontal flexItem={0} testID={testID}>
      <View>
        <Text
          mono={mono}
          weight={prominent ? 'semibold' : 'medium'}
          color={prominent ? 'text' : 'secondary'}
          size={prominent ? 'p' : 'fp'}
        >
          {left}
        </Text>
      </View>
      <View style={[theme.flex1, theme.alignRight]}>
        <Text
          style={(theme.row, { display: 'flex', alignItems: 'center', alignSelf: 'stretch' })}
          mono={mono}
          loading={loading}
          tabular
          weight={prominent ? 'semibold' : 'medium'}
          color={prominent ? 'text' : 'secondary'}
          size={prominent ? 'p' : 'fp'}
          align="right"
        >
          {right}
        </Text>
      </View>
    </Stack>
  );
};

export default LineItem;
