import { NON_USA_COUNTRIES, STATES } from '@app/utils';
import {
  Option,
  AutomationType,
  IncomeWorkType,
  SexAssignedAtBirth,
  Gender,
  SexualOrientation,
} from '@types';

export const automationTypeOptions: Record<AutomationType, Option> = {
  ALWAYS: {
    label: 'Autoroll',
    description: 'Automatically withhold',
    value: 'ALWAYS',
  },
  SOMETIMES: {
    label: 'Ask every time',
    description: 'Always ask me to withhold or skip',
    value: 'SOMETIMES',
  },
  NEVER: {
    label: 'Ignore',
    description: 'Never withhold or ask me to',
    value: 'NEVER',
  },
};

export const workTypeOptions: Record<IncomeWorkType, Option> = {
  WORK_TYPE_1099: {
    label: 'Contract work (1099)',
    description: 'I need to set aside for taxes',
    value: 'WORK_TYPE_1099',
  },
  WORK_TYPE_W2: {
    label: 'Full-time work (W2)',
    description: 'Taxes have already been withheld',
    value: 'WORK_TYPE_W2',
  },
};

type RetirementAccountPreset = 'UNDER_21' | 'OVER_21';

export const retirementAccountPresets: Record<RetirementAccountPreset, Array<Option>> = {
  UNDER_21: [
    {
      label: 'Roth IRA',
      description: 'Contributions taxed when deposited',
      value: 'ROTH_IRA',
    },
    {
      label: 'Traditional IRA',
      description: 'Contributions taxed when withdrawn',
      value: 'IRA',
    },
  ],
  OVER_21: [
    {
      label: 'SEP IRA',
      description: 'Higher risk, larger growth potential',
      value: 'SEP_IRA',
    },
    {
      label: 'Roth IRA',
      description: 'Contributions taxed when deposited',
      value: 'ROTH_IRA',
    },
    {
      label: 'Traditional IRA',
      description: 'Contributions taxed when withdrawn',
      value: 'IRA',
    },
  ],
};

type OptionPreset =
  | 'YES_NO_BOOLEAN'
  | 'YES_NO_TRINARY'
  | 'AGREE_DISAGREE_BOOLEAN'
  | 'AGREE_DISAGREE_TRINARY'
  | 'RENEWAL_YEARS'
  | 'WORK_TYPE'
  | 'AUTOMATION_TYPE'
  | 'MARITAL_STATUS'
  | 'LANGUAGES'
  | 'SUFFIXES'
  | 'CHILD_RELATION'
  | 'SEX'
  | 'SEX_ASSIGNED_AT_BIRTH'
  | 'GENDER'
  | 'SEXUAL_ORIENTATION'
  | 'STATES'
  | 'ETHNICITY'
  | 'RACE'
  | 'PHONE_TYPE'
  | 'LAWFUL_PRESENCE_DOCUMENT_TYPE'
  | 'LAWFUL_PRESENCE_ALTERNATION_DOCUMENTATION'
  | 'NON_USA_COUNTRIES'
  | 'IMMIGRATION_DOCUMENTS'
  | 'FREQUENCY_TYPES'
  | 'INCOME_TYPES'
  | 'DEDUCTION_TYPES';

export const optionPresets: Record<OptionPreset, Array<Option>> = {
  WORK_TYPE: Object.values(workTypeOptions),
  AUTOMATION_TYPE: Object.values(automationTypeOptions),
  YES_NO_BOOLEAN: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  YES_NO_TRINARY: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  AGREE_DISAGREE_BOOLEAN: [
    {
      label: 'Agree',
      value: true,
    },
    {
      label: 'Disagree',
      value: false,
    },
  ],
  AGREE_DISAGREE_TRINARY: [
    {
      label: 'Agree',
      value: 'YES',
    },
    {
      label: 'Disagree',
      value: 'NO',
    },
  ],
  RENEWAL_YEARS: [
    { value: 0, label: "Don't renew eligibility" },
    { value: 1, label: '1 year' },
    { value: 2, label: '2 years' },
    { value: 3, label: '3 years' },
    { value: 4, label: '4 years' },
    { value: 5, label: '5 years' },
  ],
  MARITAL_STATUS: [
    { label: 'Single', value: 'SINGLE' },
    { label: 'Married', value: 'MARRIED' },
  ],
  STATES: Object.keys(STATES).map((state) => {
    return { label: STATES[state], value: state };
  }),
  LANGUAGES: [
    {
      value: 'ENGLISH',
      label: 'English',
    },
    {
      value: 'SPANISH',
      label: 'Espanol',
    },
    {
      value: 'VIETNAMESE',
      label: 'Vietnamese',
    },
    {
      value: 'TAGALOG',
      label: 'Tagalog',
    },
    {
      value: 'RUSSIAN',
      label: 'Russian',
    },
    {
      value: 'PORTUGUESE',
      label: 'Portuguese',
    },
    {
      value: 'ARABIC',
      label: 'Arabic',
    },
    {
      value: 'CHINESE',
      label: 'Chinese',
    },
    {
      value: 'FRENCH',
      label: 'French',
    },
    {
      value: 'FRENCH_CREOLE',
      label: 'French Creole',
    },
    {
      value: 'GERMAN',
      label: 'German',
    },
    {
      value: 'GUJARATI',
      label: 'Gujarati',
    },
    {
      value: 'HINDI',
      label: 'Hindi',
    },
    {
      value: 'KOREAN',
      label: 'Korean',
    },
    {
      value: 'POLISH',
      label: 'Polish',
    },
    {
      value: 'URDU',
      label: 'Urdu',
    },
    {
      value: 'OTHER',
      label: 'Other',
    },
  ],
  SUFFIXES: [
    { value: 'JR', label: 'Jr.' },
    { value: 'SR', label: 'Sr.' },
    { value: 'II', label: 'II' },
    { value: 'III', label: 'III' },
    { value: 'IV', label: 'IV' },
    { value: 'V', label: 'V' },
  ],
  CHILD_RELATION: [
    { label: 'Child', value: 'CHILD' },
    { label: 'Stepchild', value: 'STEPCHILD' },
  ],
  SEX: [
    { label: 'Female', value: 'FEMALE' },
    { label: 'Male', value: 'MALE' },
  ],
  SEX_ASSIGNED_AT_BIRTH: [
    {
      label: { id: `enums.${SexAssignedAtBirth.FEMALE}` },
      value: SexAssignedAtBirth.FEMALE,
    },
    {
      label: { id: `enums.${SexAssignedAtBirth.MALE}` },
      value: SexAssignedAtBirth.MALE,
    },
    {
      label: { id: 'enums.OTHER_SEX' },
      value: SexAssignedAtBirth.OTHER,
    },
    {
      label: { id: 'enums.NOT_SURE' },
      value: SexAssignedAtBirth.DONT_KNOW,
    },
    {
      label: { id: `enums.${SexAssignedAtBirth.PREFER_NOT_TO_ANSWER}` },
      value: SexAssignedAtBirth.PREFER_NOT_TO_ANSWER,
    },
  ],
  GENDER: [
    {
      label: { id: `enums.${Gender.FEMALE}` },
      value: Gender.FEMALE,
    },
    {
      label: { id: `enums.${Gender.MALE}` },
      value: Gender.MALE,
    },
    {
      label: { id: `enums.${Gender.TRANSGENDER_FEMALE}` },
      value: Gender.TRANSGENDER_FEMALE,
    },
    {
      label: { id: `enums.${Gender.TRANSGENDER_MALE}` },
      value: Gender.TRANSGENDER_MALE,
    },
    {
      label: { id: 'enums.DIFFERENT_GENDER' },
      value: Gender.DIFFERENT_TERM,
    },
    {
      label: { id: 'enums.NOT_SURE' },
      value: Gender.DONT_KNOW,
    },
    {
      label: { id: `enums.${Gender.PREFER_NOT_TO_ANSWER}` },
      value: Gender.PREFER_NOT_TO_ANSWER,
    },
  ],
  SEXUAL_ORIENTATION: [
    {
      label: { id: `enums.${SexualOrientation.LESBIAN_OR_GAY}` },
      value: SexualOrientation.LESBIAN_OR_GAY,
    },
    {
      label: { id: `enums.${SexualOrientation.STRAIGHT}` },
      value: SexualOrientation.STRAIGHT,
    },
    {
      label: { id: `enums.${SexualOrientation.BISEXUAL}` },
      value: SexualOrientation.BISEXUAL,
    },
    {
      label: { id: 'enums.DIFFERENT_SEXUAL_ORIENTATION' },
      value: SexualOrientation.DIFFERENT_TERM,
    },
    {
      label: { id: 'enums.NOT_SURE' },
      value: SexualOrientation.DONT_KNOW,
    },
    {
      label: { id: `enums.${SexualOrientation.PREFER_NOT_TO_ANSWER}` },
      value: SexualOrientation.PREFER_NOT_TO_ANSWER,
    },
  ],
  // GENDER: Object.keys(Gender).map(assignedSex => ({
  //   label: { id: `enums.${assignedSex}` },
  //   value: assignedSex
  // })),
  // SEXUAL_ORIENTATION: Object.keys(SexualOrientation).map(assignedSex => ({
  //   label: { id: `enums.${assignedSex}` },
  //   value: assignedSex
  // })),
  ETHNICITY: [
    { label: 'Cuban', value: 'CUBAN' },
    { label: 'Mexican, Mexican American, or Chicano/a', value: 'MEXICAN' },
    { label: 'Puerto Rican', value: 'PUERTO_RICAN' },
    { label: 'Other', value: 'OTHER' },
  ],
  RACE: [
    {
      label: 'American Indian or Alaska Native',
      value: 'AMERICAN_INDIAN_OR_ALASKAN_NATIVE',
    },
    { label: 'Asian Indian', value: 'ASIAN_INDIAN' },
    { label: 'Black or African American', value: 'BLACK_OR_AFRICAN_AMERICAN' },
    { label: 'Chinese', value: 'CHINESE' },
    { label: 'Filipino', value: 'FILIPINO' },
    { label: 'Guamanian or Chamorro', value: 'GUAMANIAN_OR_CHAMORRO' },
    { label: 'Japanese ', value: 'JAPANESE' },
    { label: 'Korean', value: 'KOREAN' },
    { label: 'Native Hawaiian', value: 'NATIVE_HAWAIIAN' },
    { label: 'Samoan', value: 'SAMOAN' },
    { label: 'Vietnamese ', value: 'VIETNAMESE' },
    { label: 'White', value: 'WHITE' },
    { label: 'An Asian race not listed above', value: 'OTHER_ASIAN' },
    {
      label: 'A Pacific Islander race not listed above',
      value: 'OTHER_PACIFIC_ISLANDER',
    },
    { label: 'Another race not listed here', value: 'OTHER' },
  ],
  PHONE_TYPE: [
    {
      value: 'MOBILE',
      label: 'Mobile',
    },
    {
      value: 'HOME',
      label: 'Home',
    },
    {
      value: 'OFFICE',
      label: 'Office',
    },
  ],
  LAWFUL_PRESENCE_DOCUMENT_TYPE: [
    {
      label: { id: 'enums.PERMANENT_RESIDENT_CARD_I_551' },
      value: 'PERMANENT_RESIDENT_CARD_I_551',
    },
    {
      label: { id: 'enums.TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A' },
      value: 'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A',
    },
    { label: { id: 'enums.REENTRY_PERMIT' }, value: 'REENTRY_PERMIT' },
    {
      label: { id: 'enums.ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94' },
      value: 'ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94',
    },
    {
      label: { id: 'enums.ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94' },
      value: 'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94',
    },
    {
      label: { id: 'enums.CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019' },
      value: 'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019',
    },
    {
      label: { id: 'enums.CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20' },
      value: 'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20',
    },
    {
      label: { id: 'enums.MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE' },
      value: 'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE',
    },
    {
      label: { id: 'enums.EMPLOYMENT_AUTHORIZATION_CARD_I_766' },
      value: 'EMPLOYMENT_AUTHORIZATION_CARD_I_766',
    },
    {
      label: { id: 'enums.REFUGEE_TRAVEL_DOCUMENT_I_571' },
      value: 'REFUGEE_TRAVEL_DOCUMENT',
    },
    { label: { id: 'enums.NOTICE_OF_ACTION_I_797' }, value: 'NOTICE_OF_ACTION_I_797' },
    { label: { id: 'enums.FOREIGN_PASSPORT' }, value: 'FOREIGN_PASSPORT' },
    { label: 'Another document or alien number/I-94 number', value: 'OTHER' },
    { label: { id: 'enums.NONE_OF_THESE' }, value: 'NONE_OF_THESE' },
    { label: { id: 'enums.PREFER_NOT_TO_ANSWER' }, value: 'PREFER_NOT_TO_ANSWER' },
  ],
  LAWFUL_PRESENCE_ALTERNATION_DOCUMENTATION: [
    {
      value: 'NS1_MEMBERS_OF_A_FEDERALLY_RECOGNIZED_INDIAN_TRIBE',
      label:
        'Document indicating members Of A Federally Recognized Indian Tribe or American Indian born in Canada',
    },
    {
      value: 'CERTIFICATION_FROM_HHS_ORR',
      label:
        'Certification from U.S. Department of Health and Human Services (HHS) Office of Refugee Resettlement (ORR)',
    },
    {
      value: 'ORR_ELIGIBILITY_LETTER',
      label: 'Office of Refugee Resettlement (ORR) eligibility letter (if under 18)',
    },
    {
      value: 'CUBAN_HAITAN_ENTRANT',
      label: 'Cuban/Haitian Entrant',
    },
    {
      value: 'NS4_NON_CITIZEN_WHO_IS_LAWFULLY_PRESENT_IN_AMERICAN_SAMOA',
      label: 'Resident of American Samoa',
    },
    {
      value: 'VAWA_SELF_PETITIONER',
      label: 'Battered spouse, child, or parent under the Violence Against Women Act',
    },
    {
      value: 'ALIEN_NUM',
      label: 'Another document or alien number/I-94 number',
    },
  ],
  NON_USA_COUNTRIES: NON_USA_COUNTRIES.map((country) => ({
    label: country.name,
    value: country['alpha-3'],
  })),
  IMMIGRATION_DOCUMENTS: [
    {
      label: { id: 'enums.CERTIFICATE_OF_NATURALIZATION' },
      value: 'CERTIFICATE_OF_NATURALIZATION',
    },
    { label: { id: 'enums.CERTIFICATE_OF_CITIZENSHIP' }, value: 'CERTIFICATE_OF_CITIZENSHIP' },
    { label: { id: 'enums.NONE_OF_THESE' }, value: 'NONE_OF_THESE' },
  ],
  FREQUENCY_TYPES: [
    { value: 'ANNUALLY', label: { id: 'enums.ANNUALLY' } },
    { value: 'ONE_TIME', label: { id: 'enums.ONE_TIME' } },
    { value: 'QUARTERLY', label: { id: 'enums.QUARTERLY' } },
    { value: 'SEMI_MONTHLY', label: { id: 'enums.SEMI_MONTHLY' } },
    { value: 'MONTHLY', label: { id: 'enums.MONTHLY' } },
    { value: 'BI_WEEKLY', label: { id: 'enums.BI_WEEKLY' } },
    { value: 'WEEKLY', label: { id: 'enums.WEEKLY' } },
  ],
  INCOME_TYPES: [
    {
      label: 'Self-employment (like your own business, consulting, or freelance work)',
      value: 'SELF_EMPLOYMENT',
    },
    { label: 'Job (like salary, wages, commissions, or tips)', value: 'JOB' },

    { label: 'Farming or fishing', value: 'FARMING_OR_FISHING_INCOME' },
    { label: 'Unemployment', value: 'UNEMPLOYMENT' },
    {
      label: 'Social Security benefits (retirement and disability)',
      value: 'SOCIAL_SECURITY_BENEFIT',
    },
    {
      label: 'Retirement (like IRA and 401(k) withdrawals)',
      value: 'RETIREMENT',
    },
    { label: 'Pension benefits', value: 'PENSION' },
    {
      label: 'Investment (including interest and dividend income)',
      value: 'INVESTMENT_INCOME',
    },
    { label: 'Capital gains', value: 'CAPITAL_GAINS' },
    { label: 'Rental or royalty', value: 'RENTAL_OR_ROYALTY_INCOME' },
    { label: 'Alimony received', value: 'ALIMONY_RECEIVED' },
    { label: 'Scholarship', value: 'SCHOLARSHIP' },
    { label: 'Canceled debt', value: 'CANCELED_DEBT' },
    { label: 'Court awards', value: 'COURT_AWARDS' },
    {
      label: 'Gambling, prizes, or awards',
      value: 'PRIZES_AWARDS_GAMBLING_WINNINGS',
    },
    { label: 'Jury duty pay', value: 'JURY_DUTY_PAY' },
    { label: 'Other income', value: 'OTHER_INCOME' },
  ],
  DEDUCTION_TYPES: [
    { label: 'Alimony', value: 'ALIMONY_PAYMENT' },
    {
      label: 'Student loan interest',
      value: 'STUDENT_LOAN_INTEREST',
    },
    {
      label: 'Other deduction',
      value: 'OTHER_DEDUCTION',
    },
  ],
};
