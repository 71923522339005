import gql from 'graphql-tag';

export type VerifyAliasError =
  | 'VERIFY_ALIAS_ERROR_INVALID_MFA'
  | 'VERIFY_ALIAS_ERROR_NO_ACTIVE_CHANGE'
  | 'VERIFY_ALIAS_ERROR_REQUEST_EXPIRED'
  | 'VERIFY_ALIAS_ERROR_REQUEST_NO_MORE_RETRIES'
  | 'VERIFY_ALIAS_ERROR_COGNITO';

export interface VerifyEmailVars {
  confirmationCode: string;
}

export interface VerifyEmailResponse {
  verifyEmail: {
    error: VerifyAliasError;
    newEmail: string;
  };
}

export const VerifyEmail = gql`
  mutation VerifyEmail($confirmationCode: String!) {
    verifyEmail(input: { mfaCode: $confirmationCode }) {
      error
      newEmail
    }
  }
`;
