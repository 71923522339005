import { useMemo } from 'react';
import { useQuery, queries, HealthProgressLookupQueryData, useDeprecatedMutation } from '@data';
import { navigate } from '@navigate';
import { chooseHealthApp, currentYear, goToHealthApp, hasAgreedToPrivacy } from '@app/utils';
import { Route } from '@types';

export const useHealthStart = ({ defaultRoute }: { defaultRoute: Route }) => {
  const lookup = useQuery<HealthProgressLookupQueryData>(queries.HEALTH_PROGRESS_LOOKUP, {
    variables: { coverageYears: [currentYear, currentYear + 1] },
  });

  const hasAgreed = useMemo(() => {
    return hasAgreedToPrivacy(lookup?.data?.viewerTwo?.agreements);
  }, [lookup?.data?.viewerTwo?.agreements]);

  const [createEnrollment, { loading: creating }] = useDeprecatedMutation(
    'upsertEnrollmentFromExplorer',
    {
      onCompleted: (enrollment) => {
        goToHealthApp(enrollment.healthApplication, { hasAgreed });
      },
    },
  );

  const healthApplications = useMemo(() => {
    const { isOpenEnrollment, oeCoverageYear } =
      lookup?.data?.reference?.health?.openEnrollmentDates || {};
    const apps = lookup?.data?.viewerTwo?.health?.applications || [];

    if (isOpenEnrollment) {
      // only OE apps are eligible for auto resuming
      return apps.filter((app) => app.coverageYearNumber === oeCoverageYear);
    } else {
      return apps;
    }
  }, [lookup?.data?.viewerTwo?.health?.applications]);

  /**
   * - If app ID is set, go to the next screen of application
   * - Otherwise, default to a new application from list or create new
   */
  const handleNext = () => {
    const { healthExplorerData } = lookup?.data?.viewerTwo || {};
    const bestApp = chooseHealthApp(healthApplications);

    if (bestApp) {
      goToHealthApp(bestApp);
    } else if (healthExplorerData?.canCreateEnrollment) {
      // @ts-ignore
      createEnrollment();
    } else if (healthExplorerData?.pathwayType === 'STATE_EXCHANGE') {
      navigate(Route.EXPLORER_ZIPCODE);
    } else {
      // navigate to the intro screen unless we're already there
      navigate(defaultRoute || Route.EDE_INTRO);
    }
  };

  return {
    loading: lookup.loading,
    creating,
    handleNext,
    foundApp: healthApplications?.length > 0,
  };
};
