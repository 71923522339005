import gql from 'graphql-tag';
import { CoverageEndedReason, InsuranceMarketType, Trinary } from '@types';

export interface ApolloApplicationMemberInput {
  id?: string;
  relation?: 'SELF' | 'SPOUSE' | 'CHILD';
  isRequestingCoverage?: Trinary;
  isTaxDependent?: Trinary;
  isTaxFiler?: Trinary;
  isEnrolledInIchra?: Trinary;
  isOfferedIchra?: Trinary;
  maritalStatus?: 'SINGLE' | 'MARRIED';
  taxReturnFilingStatusType?:
    | 'SINGLE_FILER'
    | 'MARRIED_FILING_JOINTLY'
    | 'MARRIED_FILING_SEPARATELY'
    | 'HEAD_OF_HOUSEHOLD';
  isLostCoverageLast60Days?: Trinary;
  coverageLostDate?: Date;
  planName?: string;
  coverageLostDateFuture?: Date;
  isMarriedLast60Days?: Trinary;
  whenMarried?: Date;
  hasCoverage60DaysBeforeMarriage?: Trinary;
  hasLivedOutsideUSLast60DaysBeforeMarriage?: Trinary;
  whenLawfulPresence?: Date;
  isGainedLawfulPresence?: Trinary;
  isMoved?: Trinary;
  whenRelocated?: Date;
  hasCoverageLast60DaysBeforeRelocation?: Trinary;
  isMovedFromOutsideUs?: Trinary;
  previousZipcode?: string;
  previousCountyLivedName?: string;
  isReleasedFromIncarceration?: Trinary;
  whenReleasedFromIncarceration?: Date;
  isGainDependent?: Trinary;
  whenBecomeADependent?: Date;

  // OTHER_COVERAGE
  isCoverageEnded?: Trinary;
  coverageEndedReason?: CoverageEndedReason | null;
  coverageEndedReasonText?: string | null;
  isEnrolledInHealthCoverage?: Trinary;
  otherCoverages?: Array<{
    insuranceMarketType: InsuranceMarketType;
    insurancePolicyNumber?: string;
    insurancePolicyMemberID?: string;
    insurancePlanName?: string;
  }>;
}

interface ApplicationMember {
  id?: string;
  applicationID?: string;
  relation?: 'SELF' | 'SPOUSE' | 'CHILD';
  isRequestingCoverage?: boolean;
  isTaxDependent?: boolean;
  isTaxFiler?: boolean;
  isEnrolledInIchra?: boolean;
  isOfferedIchra?: boolean;
  maritalStatus: 'SINGLE' | 'MARRIED';
  taxReturnFilingStatusType?:
    | 'SINGLE_FILER'
    | 'MARRIED_FILING_JOINTLY'
    | 'MARRIED_FILING_SEPARATELY'
    | 'HEAD_OF_HOUSEHOLD';
  isLostCoverageLast60Days?: boolean;
  coverageLostDate?: Date;
  planName?: string;
  coverageLostDateFuture?: Date;
  isMarriedLast60Days?: boolean;
  whenMarried?: Date;
  hasCoverage60DaysBeforeMarriage?: boolean;
  hasLivedOutsideUSLast60DaysBeforeMarriage?: boolean;
  whenLawfulPresence?: Date;
  isGainedLawfulPresence?: boolean;
  isMoved?: boolean;
  whenRelocated?: Date;
  hasCoverageLast60DaysBeforeRelocation?: boolean;
  isMovedFromOutsideUs?: boolean;
  previousZipcode?: string;
  previousCountyLivedName?: string;
  isReleasedFromIncarceration?: boolean;
  whenReleasedFromIncarceration?: Date;
  isGainDependent?: boolean;
  whenBecomeADependent?: Date;
  isUnpaidBill?: boolean;
  isCoveredDependentChild?: boolean;
}

export interface UpsertApplicationMembersVars {
  input: {
    applicationID: string;
    applicationMembersInput: Array<ApolloApplicationMemberInput>;
  };
}

export interface UpsertApplicationMembersResponse {
  upsertApplicationMembers: Array<ApplicationMember>;
}

export const UpsertApplicationMembers = gql`
  mutation UpsertApplicationMembers($input: UpsertApplicationMembersInput!) {
    upsertApplicationMembers(input: $input) {
      id
      givenName
      middleName
      familyName
      applicationID
      relation
      isRequestingCoverage
      isTaxDependent
      isTaxFiler
      isClaimsDependent
      isEnrolledInIchra
      isOfferedIchra
      maritalStatus
      taxReturnFilingStatusType
      isLostCoverageLast60Days
      coverageLostDate
      planName
      coverageLostDateFuture
      isMarriedLast60Days
      whenMarried
      hasCoverage60DaysBeforeMarriage
      hasLivedOutsideUSLast60DaysBeforeMarriage
      whenLawfulPresence
      isGainedLawfulPresence
      isMoved
      whenRelocated
      hasCoverageLast60DaysBeforeRelocation
      isMovedFromOutsideUs
      previousZipcode
      previousCountyLivedName
      isReleasedFromIncarceration
      whenReleasedFromIncarceration
      isGainDependent
      whenBecomeADependent
      isUnpaidBill
      isCoveredDependentChild
      sexAssignedAtBirth
      sexAssignedAtBirthOtherText
      gender
      genderDifferentTermText
      sexualOrientation
      sexualOrientationDifferentTermText
      contactMethod
      mobileNotificationPhoneNumber
    }
  }
`;
