import gql from 'graphql-tag';

export type ChangeAliasError =
  | 'CHANGE_ALIAS_ERROR_ALIAS_IN_USE'
  | 'CHANGE_ALIAS_ERROR_SAME_ALIAS'
  | 'CHANGE_ALIAS_ERROR_MISSING_ALIAS'
  | 'CHANGE_ALIAS_ERROR_INVALID_ALIAS';

export interface ChangePhoneVars {
  phoneNumber: string;
}

export interface ChangePhoneResponse {
  changePhone: {
    error: ChangeAliasError;
    newPhone: string;
  };
}

export const ChangePhone = gql`
  mutation ChangePhone($phoneNumber: String!) {
    changePhone(input: { phone: $phoneNumber }) {
      error
      newPhone
    }
  }
`;
