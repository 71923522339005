import React from 'react';
import styled from 'styled-components';
import { Action } from '@types';
import { handleAction } from '@navigate';
import { Text, Pressable, useTheme, CatchTheme } from '@uikit';

interface NavLinkProps {
  active: boolean;
  action: Action;
  stacked?: boolean;
  hide?: boolean;
  children: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  margin: 0 16px;
  transition: ${CatchTheme.animation.duration}ms ${CatchTheme.animation.curve};
`;

const NavLink: React.FC<NavLinkProps> = ({ action, stacked, active, hide, children }) => {
  const { theme } = useTheme();

  if (hide) return null;

  return (
    <Container>
      <Pressable
        handleOnPress={() => handleAction(action)}
        hoveredStyle={[theme.skeletonBg]}
        focusedStyle={{ boxShadow: `0 0 0 2px ${theme.focusBorderColor.borderColor || '#007AFF'}` }}
        style={[styles.link, stacked && styles.linkStacked, active && theme.skeletonBg]}
      >
        <Text weight="medium" color={active ? 'text' : 'subtle'} size="fp">
          {children}
        </Text>
      </Pressable>
    </Container>
  );
};

const styles = {
  link: {
    display: 'flex',
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginHorizontal: -16,
    borderRadius: 99,
    lineHeight: 1,
    flexDirection: 'row',
    alignItems: 'center',
    outline: 'none',
  },
  linkStacked: {
    borderRadius: 0,
    flex: 1,
    opacity: 1,
    paddingVertical: 8,
    paddingHorizontal: 24,
    marginHorizontal: -40,
  },
};

export default NavLink;
