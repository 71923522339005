import { isPast } from 'date-fns';
import {
  ApplicationIssuesFragment,
  ConsolidatedIssueStatus,
  DmiDocumentUpload,
  DmiFragment,
  DmiSubType,
  DocNoticeFragment,
  EdnDocumentFragment,
  HealthApplicationQuery,
  SviDocumentUpload,
  SviFragment,
  SviStatusCode,
  TransferQueryData,
  WalletItemFragment,
} from './types';

/**
 * Helper that pulls the application members off of the health app query
 */
export const getMembers = (data?: HealthApplicationQuery) => {
  const applicant = data?.viewerTwo?.health?.application?.applicant;
  const otherMembers = data?.viewerTwo?.health?.application?.members || [];
  const sortedMembers = [...otherMembers].sort((a, b) =>
    (a?.relation || '') < (b?.relation || '') ? 1 : -1,
  );
  return applicant ? [applicant, ...sortedMembers] : sortedMembers;
};

export enum HealthDocType {
  DMI = 'DMI',
  SVI = 'SVI',
}

export interface DMI extends DmiFragment {
  status: ConsolidatedIssueStatus;
  docType: HealthDocType.DMI;
  resolveBy: Date;
  copyType?: DmiSubType | null;
  members: ApplicationIssuesFragment['allMembers'];
  notices: Array<DocNoticeFragment>;
  uploads: Array<DmiDocumentUpload>;
}

export interface SVI extends SviFragment {
  status: ConsolidatedIssueStatus;
  docType: HealthDocType.SVI;
  resolveBy: Date;
  copyType: SviStatusCode;
  members: ApplicationIssuesFragment['allMembers'];
  notices: Array<DocNoticeFragment>;
  uploads: Array<SviDocumentUpload>;
}

export interface ApplicationIssues {
  dmis: Array<DMI>;
  svis: Array<SVI>;
  healthDocs: Array<DMI | SVI>;
  issuesByStatus: Record<ConsolidatedIssueStatus, Array<DMI | SVI>>;
}

const overwriteStatus = (
  issue: DmiFragment | SviFragment,
  uploads: Array<DmiDocumentUpload | SviDocumentUpload>,
): ConsolidatedIssueStatus => {
  const isCompleted = issue.status === ConsolidatedIssueStatus.Complete;
  const isExpired =
    issue.status === ConsolidatedIssueStatus.Expired || isPast(new Date(issue?.resolveBy));
  const isProcessing = issue.status === ConsolidatedIssueStatus.Processing || uploads?.length > 0;

  return isCompleted
    ? ConsolidatedIssueStatus.Complete
    : isExpired
    ? ConsolidatedIssueStatus.Expired
    : isProcessing
    ? ConsolidatedIssueStatus.Processing
    : ConsolidatedIssueStatus.ActionNeeded;
};

/**
 * Helper that figures out which DMI/SVIs have certain statuses
 */
export const getApplicationIssues = (
  application?: ApplicationIssuesFragment,
): ApplicationIssues => {
  const allMembers = application?.allMembers || [];
  const dmiUploads = application?.dmiDocumentUploads || [];
  const sviUploads = application?.sviDocumentUploads || [];
  const dmiNotices = application?.dmiNotices || [];
  const sviNotices = application?.sviNotices || [];

  const dmis: Array<DMI> = (application?.dmis || [])?.map((dmi) => {
    const uploads = dmiUploads?.filter((upload) => upload.issueID === dmi.id);
    const members = allMembers?.filter((dep) => dep?.id === dmi?.dependentID);
    const notices = dmiNotices?.filter(() => true);

    return {
      ...dmi,
      status: overwriteStatus(dmi, uploads),

      docType: HealthDocType.DMI,
      resolveBy: new Date(dmi?.resolveBy), // cast as date
      copyType: dmi?.subType,

      notices,
      members,
      uploads,
    };
  });

  const svis: Array<SVI> = (application?.svis || [])?.map((svi) => {
    const uploads = sviUploads?.filter((upload) => upload.issueID === svi.id);
    const members = allMembers?.filter((dep) => svi?.dependentIDs?.includes(dep?.id));
    const notices = sviNotices?.filter(() => true);

    return {
      ...svi,
      status: overwriteStatus(svi, uploads),

      docType: HealthDocType.SVI,
      resolveBy: new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000), // new Date(svi?.resolveBy), // cast as date
      copyType: svi?.statusCode, // MOVED_TO_NEW_SERVICE_AREA, MEC_LOSS, etc.

      members,
      notices,
      uploads,
    };
  });

  // all documents should be sorted by when they are due by
  const healthDocs = [...dmis, ...svis]?.sort((a, b) => (a?.resolveBy > b?.resolveBy ? 1 : -1));

  const issuesByStatus: Record<ConsolidatedIssueStatus, Array<DMI | SVI>> = {
    [ConsolidatedIssueStatus.ActionNeeded]: [],
    [ConsolidatedIssueStatus.Processing]: [],
    [ConsolidatedIssueStatus.Complete]: [],
    [ConsolidatedIssueStatus.Expired]: [],
  };

  healthDocs.forEach((doc) => {
    issuesByStatus[doc.status] = [...issuesByStatus[doc.status], doc];
  });

  return {
    dmis: dmis.filter((dmi) => dmi.status === ConsolidatedIssueStatus.ActionNeeded),
    svis: svis.filter((svi) => svi.status === ConsolidatedIssueStatus.ActionNeeded),
    healthDocs,
    issuesByStatus,
  };
};

/**
 * Helper function to retrieve the most recent EDN
 */

export const getMostRecentEDN = (
  ednDocuments?: Array<EdnDocumentFragment>,
): EdnDocumentFragment | undefined => {
  const EDNs = ednDocuments ? [...ednDocuments] : [];
  return EDNs.sort((a, b) => (a.documentCreationDate < b.documentCreationDate ? 1 : -1))?.[0];
};

/**
 * Returns list of wallet types that do NOT already have an existing item
 */
export const getRemainingWalletTypes = (
  items?: Array<WalletItemFragment> | null,
): Array<string> => {
  const found = (items || []).reduce((acc, item) => ({ ...acc, [item.vertical]: true }), {});
  return ['HEALTH', 'DENTAL', 'VISION', 'LIFE'].filter((type) => !found[type]);
};

/**
 * Gets list of bank accounts
 */

export const getBankAccounts = (data?: TransferQueryData): Array<object> => {
  return (data?.viewer?.bankLinks || []).reduce((acc, link) => {
    link?.accounts?.forEach((account) => {
      acc.push({
        logo: link.logo,
        bankName: link.bankName,
        accountName: account.name,
        ...account,
      });
    });

    return acc;
  }, []);
};
