import gql from 'graphql-tag';

export type VerifyAliasError =
  | 'VERIFY_ALIAS_ERROR_INVALID_MFA'
  | 'VERIFY_ALIAS_ERROR_NO_ACTIVE_CHANGE'
  | 'VERIFY_ALIAS_ERROR_REQUEST_EXPIRED'
  | 'VERIFY_ALIAS_ERROR_REQUEST_NO_MORE_RETRIES'
  | 'VERIFY_ALIAS_ERROR_COGNITO';

export interface VerifyPhoneVars {
  confirmationCode: string;
}

export interface VerifyPhoneResponse {
  verifyPhone: {
    error: VerifyAliasError;
    newPhone: string;
  };
}

export const VerifyPhone = gql`
  mutation VerifyPhone($confirmationCode: String!) {
    verifyPhone(input: { mfaCode: $confirmationCode }) {
      error
      newPhone
    }
  }
`;
