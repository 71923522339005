import React, { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AutoSignOut } from '@app/_common/utility/AutoSignOut';
import { Loading } from '@uikit/components/Loading';
import { SheetsProvider } from '@sheets';
import { getRouteFromURL } from '@navigate';
import AuthenticatedSupport from './AuthenticatedSupport';
import MagicModals from './MagicModals';
import MagicNav from './MagicNav';
import { ErrorBoundary } from '@app/_common';
import { ReviewPrompt } from '@app/components/review';

const MainLayout = () => {
  const location = useLocation();

  return (
    <>
      <MagicNav layout="page" route={getRouteFromURL(location)} />
      <Suspense fallback={<Loading full page />}>
        <ErrorBoundary route={getRouteFromURL(location)}>
          <Outlet />
        </ErrorBoundary>
      </Suspense>
      <MagicModals />
      <SheetsProvider />
      <AuthenticatedSupport />
      <ReviewPrompt />
      <AutoSignOut />
    </>
  );
};
export default MainLayout;
