import { onlyNums } from './misc';

type AliasType = 'phone' | 'email';

/**
 * Determines alias type
 *
 * - If the entry is entirely numbers or dashes, we assume that its a phone number
 * - As soon as there's a non-numerical char, its an email
 * - If we go beyond 10 digits, assume its an email as well
 */
export const checkAliasType = (str: string): AliasType | false => {
  // if plus sign, digit, parens, or dash and not 10 numbers yet
  if (/^[0-9\-+()]+$/i.test(str) && onlyNums(str).length <= 11) {
    return 'phone';
  }

  return 'email';
};

/**
 * Formats alias (either as phone or email)
 * Any valid phone numbers are formatted as +1XXXXXXXXXX
 * Otherwise, return as is
 */
export const formatAliasForCognito = (alias: string): string => {
  const aliasType = checkAliasType(alias);

  // todo: update this so that it is idempotent with respect to phone numbers
  return aliasType === 'phone' ? `+1${onlyNums(alias)}` : alias;
};
