import { optionPresets } from '@app/forms';
import ExplorerApplicantCard from './ExplorerApplicantCard';
import ExplorerApplicantsFooter from './ExplorerApplicantsFooter';

export const fields = [
  {
    name: 'applicants',
    type: 'array',
    grouped: true,
    Component: ExplorerApplicantCard,
    Footer: ExplorerApplicantsFooter,
    dependencies: ['applicants', 'SUBFIELD:id', 'SUBFIELD:relation'],
    subfields: [
      {
        copy: 'catch.healthExplorer.explorerApplicants',
        name: 'age',
        bare: true,
        type: 'number',
        label: { id: 'age' },
        required: true,
      },
      {
        copy: 'catch.ede.HealthEnrollment',
        name: 'sex',
        type: 'option',
        bare: true,
        label: { id: '31_Q' },
        options: optionPresets.SEX,
        small: true,
        required: true,
      },
      {
        copy: 'catch.healthExplorer.explorerApplicants',
        name: 'isSmoker',
        plain: true,
        type: 'checkbox',
        label: { id: 'isSmoker' },
      },
    ],
  },
];
