import React from 'react';

import { Modal, PageError } from '@uikit';
import { useClientError } from '@client';
import { Env, useCopy } from '@app/utils';
import { SheetBlueprint } from '@app/blueprints';

const PREFIX = 'catch.errors.SystemError';

// Prob could use an update but at least we can show something
// if the platform is down
export const SystemError = () => {
  const status = useClientError((state) => state.status);
  const { c } = useCopy(PREFIX);
  const dev = Env.isDevLike;

  return status === 'down' ? (
    <Modal onRequestClose={() => {}} presentation="actionSheet">
      {dev ? (
        <SheetBlueprint
          titleColor="credit"
          titleSize="form"
          title={'Dev is down'}
          subtitle={"If this outage isn't already known, please post in #team-engineering"}
          asset={{ icon: PageError }}
        />
      ) : (
        <SheetBlueprint
          titleSize="block"
          title={c('title')}
          subtitle={c('p1')}
          asset={{ icon: PageError }}
        />
      )}
    </Modal>
  ) : null;
};

export default SystemError;
