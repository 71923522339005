import React, { useState } from 'react';

import { Stack } from '@app/layouts';
import Activity from '../utility/Activity';
import { LineItem, SegmentedControl } from '@app/_ui-kit';
import { useCopy } from '@app/utils';

/**
 * renders detailed information about a Benefit, including
 * - Details (such as percentage allocated or insurance provider)
 * - Activity, and Costs (for insurance cost breakdown)
 * used by both goals and insurances
 */
const PlanDetailTabs = ({
  details,
  additionalDetailTab,
  enrollmentID,
  benefit,
  tabKey = 'default',
  loading,
  accentColor,
}) => {
  const { $ } = useCopy();
  const [index, setIndex] = useState(0);
  const TABS = {
    health: ['Cost', 'Details', 'Activity'],
    default: ['Activity', 'Details'],
  };

  return (
    <Stack spacing="2">
      <SegmentedControl
        index={index}
        setIndex={setIndex}
        controls={TABS[tabKey]?.map((item) => ({
          title: item,
        }))}
      />

      <>
        {TABS[tabKey][index] === 'Details' && details}
        {TABS[tabKey][index] === 'Cost' && additionalDetailTab}
        {TABS[tabKey][index] === 'Activity' && (
          <Stack spacing="2" separatorComponent>
            {benefit?.pendingBalance > 0 && (
              <LineItem left="Pending" right={$(benefit?.pendingBalance)} />
            )}
            <Activity
              goalID={benefit?.id}
              enrollmentID={enrollmentID}
              loading={loading}
              accentColor={accentColor}
            />
          </Stack>
        )}
      </>
    </Stack>
  );
};

export default PlanDetailTabs;
