import { Route } from '@types';

export const healthRouteMap = {
  intro: Route.EDE_INTRO,
  'coverage-info': Route.EDE_COVERAGE_INFO,
  privacy: Route.EDE_PRIVACY, // @todo check if the user already did this
  household: Route.EDE_HOUSEHOLD,
  screening: Route.EDE_SCREENING,
  'pro-assistance': Route.EDE_PRO_ASSISTANCE,
  'id-info': Route.EDE_ID_INFO, // @todo check if the user already did this
  'id-questions': Route.EDE_IDENTITY, // @todo check if the user already did this
  'id-verify': Route.EDE_IDENTITY, // @todo check if the user already did this
  'id-denied': Route.EDE_IDENTITY, // @todo check if the user already did this
  identity: Route.EDE_IDENTITY,
  'application-import': Route.EDE_APPLICATION_IMPORT,
  'confirm-applicants': Route.EDE_CONFIRM_APPLICANTS,
  'member-info': Route.EDE_MEMBER_INFO,
  'member-questions': Route.EDE_MEMBER_QUESTIONS,
  'immigration-details': Route.EDE_IMMIGRATION_DETAILS,
  'medicaid-denial': Route.EDE_MEDICAID_DENIAL,
  'member-income-list': Route.EDE_MEMBER_INCOME_LIST,
  'member-deductions-list': Route.EDE_MEMBER_INCOME_DETAILS,
  'member-income-details': Route.EDE_MEMBER_INCOME_DETAILS,
  'member-income-confirm': Route.EDE_MEMBER_INCOME_CONFIRM,
  'member-income-discrepancy': Route.EDE_MEMBER_INCOME_DISCREPANCY,
  sep: Route.EDE_SEP,
  'outside-household': Route.EDE_OUTSIDE_HOUSEHOLD,
  'dependents-current-coverage': Route.EDE_DEPENDENTS_CURRENT_COVERAGE,
  'member-medical-bills': Route.EDE_MEMBER_MEDICAL_BILLS,
  'member-work-hours': Route.EDE_MEMBER_WORK_HOURS,
  'other-coverage': Route.EDE_OTHER_COVERAGE,
  review: Route.EDE_REVIEW,
  agreements: Route.EDE_AGREEMENTS,
  results: Route.EDE_RESULTS,
  docs: Route.HEALTH_DOCS,
  dmi: Route.HEALTH_DOCS,
  'split-eligibility': Route.EDE_SPLIT_ELIGIBILITY,
  plans: Route.EDE_PLANS,
  'aptc-agreement': Route.EDE_APTC_AGREEMENT,
  enroll: Route.EDE_ENROLL,
  success: Route.EDE_SUCCESS,
  'sep-out-of-date': Route.EDE_SEP_OUT_OF_DATE,
};
