import { FieldsConfig } from '@app/types';

export const formatPayload = (
  values: object,
  fields: FieldsConfig,
  initialPayload = {},
): object => {
  return fields.reduce((acc, field) => {
    // handles for legal name case where subfields should be sent
    if (field.type === 'legalName') {
      const updates = {};

      Object.keys(field.subfields).forEach((name) => {
        updates[name] = values[name];
      });

      return { ...acc, ...updates };
    }

    const { __typename, ...data } = values[field.name];

    return {
      ...acc,
      [field.name]: data,
    };
  }, initialPayload);
};
