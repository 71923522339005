import React from 'react';
import { Stack } from '@app/layouts';

import { Text } from './Text';

export const FinePrint = ({ items, align }) => {
  return (
    <Stack spacing="0b" topSpace>
      {items?.map((item, idx) => (
        <Text size="sm" color="subtler" align={align} key={idx}>
          {item}
        </Text>
      ))}
    </Stack>
  );
};

export default FinePrint;
