import React, { useMemo } from 'react';

import { Segment, SegmentEvent, handleHealthResponse, useCopy } from '@app/utils';
import { useQuery, HealthApplicationQuery, useMutation, mutations } from '@data';
import { navigate } from '@navigate';
import {
  determineCoveredNonApplicantChild,
  determineDeprivationNavigation,
  determineOtherCoverage,
  determineOutsideHousehold,
  isMarketplacePrelim,
} from '@navigate/EDENavigationGuard';
import { getMembers } from '@app/data/utils';
import { SplitFormBlueprint } from '@app/blueprints';
import { formatPayload } from './incomeDiscrepancyUtils';
import { fields } from './incomeDiscrepancyFields';
import { Route } from '@app/types';

const IncomeDiscrepancy = ({ applicationID }) => {
  const { c } = useCopy('catch.ede.IncomeDiscrepancy');

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const {
    coverageYear,
    isRequestingFinancialAssistance,
    members,
    hasDiscrepancy,
    self,
    spouse,
    memberNeedsAnnualIncomeExplanation,
    householdNeedsAnnualIncomeExplanation,
    isMedicaidPrelim,
    parsedQuestions,
    incomeSources,
  } = useMemo(() => {
    const app = data?.viewerTwo?.health?.application;
    const members = getMembers(data);
    const msWithIncome = members?.filter((m) => m?.incomeThisMonth);

    // creates a list of any income sources with job income explanation required
    const incomeSources = members.reduce((list, member) => {
      const sources = member?.incomeSources || [];
      const filtered = sources.filter((s) => s?.jobIncomeExplanationRequired);
      return [...list, ...filtered];
    }, []);

    // if any member has annualIncomeExplanationRequired
    const memberNeedsAnnualIncomeExplanation = members?.some((m) => {
      return m?.annualTaxIncome?.annualIncomeExplanationRequired;
    });

    const householdNeedsAnnualIncomeExplanation =
      app?.incomeExplanationRequiredIndicator &&
      app?.incomeExplanationRequiredReason === 'LOWER_THAN_SOURCE';

    return {
      coverageYear: app?.coverageYearNumber,
      isRequestingFinancialAssistance: app?.screening?.isRequestingFinancialAssistance,
      parsedQuestions: app?.uiQuestionsToDisplay,
      members,
      msWithIncome,
      incomeSources,
      memberNeedsAnnualIncomeExplanation,
      householdNeedsAnnualIncomeExplanation,
      hasDiscrepancy: memberNeedsAnnualIncomeExplanation || householdNeedsAnnualIncomeExplanation,
      self: members?.find((m) => m?.relation === 'SELF'),
      spouse: members?.find((m) => m?.relation === 'SPOUSE'),
      isMedicaidPrelim: members?.some((m) => m?.preliminaryMedicaidStatus === 'YES'),
    };
  }, [data?.viewerTwo?.health?.application]);

  const [upsert, { loading: upserting }] = useMutation(
    mutations.UPSERT_APPLICATION_MEMBERS,
    handleHealthResponse({}),
  );

  const handleSubmit = (values) => {
    upsert({ variables: { input: formatPayload({ applicationID, values }) } });

    Segment.track(SegmentEvent.APPLICATION_INCOME_DISCREPANCY_ANSWERED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    if (isMedicaidPrelim) {
      navigate(Route.EDE_MEMBER_MEDICAL_BILLS);
    } else if (determineCoveredNonApplicantChild(members, isRequestingFinancialAssistance)) {
      navigate(Route.EDE_DEPENDENTS_CURRENT_COVERAGE);
    } else if (determineOutsideHousehold(members)) {
      navigate(Route.EDE_OUTSIDE_HOUSEHOLD);
    } else if (determineDeprivationNavigation(parsedQuestions)) {
      navigate(Route.EDE_MEMBER_WORK_HOURS);
    } else if (determineOtherCoverage(members)) {
      navigate(Route.EDE_OTHER_COVERAGE);
    } else if (isMarketplacePrelim(members)) {
      navigate(Route.EDE_SEP);
    } else {
      navigate(Route.EDE_REVIEW);
    }
  };

  return (
    <SplitFormBlueprint
      title={c('title')}
      subtitle={c('subtitle')}
      loading={!data}
      submitting={upserting}
      formConfig={{
        initialValues: {
          self,
          spouse,
          coverageYear,
          incomeSources,
          hasDiscrepancy,
          members,
          memberNeedsAnnualIncomeExplanation,
          householdNeedsAnnualIncomeExplanation,
        },
        fields,
        onSubmit: handleSubmit,
      }}
    />
  );
};

export const IncomeDiscrepancyView = {
  name: Route.EDE_MEMBER_INCOME_DISCREPANCY,
  component: IncomeDiscrepancy,
  options: {
    title: 'Income',
  },
};
