import React, { useMemo, useState } from 'react';
import { Copy } from '@types';
import { ApolloScoredHealthPlan } from '@data';
import { Stack, Carousel, Layout } from '@layouts';
import { Dialog, SegmentedControl, Button, EmptyState, Banner } from '@uikit';
import { useCopy } from '@app/utils';
import { SheetBlueprint } from '@app/blueprints';
import {
  useHealthPlansSortAndFilter,
  applyHealthPlanFilters,
  sortHealthPlans,
} from '@hooks/useHealthPlansSortAndFilter';
import PlanShoppingCard from '@app/partial/coverage/PlanShoppingCard';
import ShieldWarning from '@svg/warning-triangle.svg';

interface HealthPlansProps {
  disabled: boolean;
  previouslySelectedID?: string; // for showing a prior selected plan
  selectedID: string;
  selectPlan: (id: string) => void;
  enrollmentType?: 'HEALTH_INSURANCE' | 'DENTAL_INSURANCE';
  planSearch?: {
    plans?: Array<ApolloScoredHealthPlan>;
    topPlans: {
      catchRecommended?: ApolloScoredHealthPlan;
      budgetPlan?: ApolloScoredHealthPlan;
      comprehensivePlan?: ApolloScoredHealthPlan;
    };
  };
  planLookup?: { [id: string]: ApolloScoredHealthPlan };
  plans?: Array<ApolloScoredHealthPlan>;
  isWindowShopping?: boolean;
}

const HealthPlans: React.FC<HealthPlansProps> = ({
  disabled,
  previouslySelectedID,
  enrollmentType,
  planLookup,
  selectedID,
  selectPlan,
  planSearch,
  plans,
  isWindowShopping,
}) => {
  const { c, locale } = useCopy('catch');
  const [index, setIndex] = useState<number>(0);

  const {
    sheet,
    openSheet,
    closeSheet,
    sortMethod,
    metalLevels,
    planTypes,
    other,
    actions,
    resetFilters,
  } = useHealthPlansSortAndFilter();

  // we memoize the sort responses for easy switching
  const sortedPlans = useMemo(() => {
    return {
      recommended: sortHealthPlans(plans, 'recommended'),
      lowest_deductible: sortHealthPlans(plans, 'lowest_deductible'),
      lowest_premium: sortHealthPlans(plans, 'lowest_premium'),
    };
  }, [plans]);

  const filteredPlans = useMemo(() => {
    return sortedPlans[sortMethod].filter((plan) =>
      applyHealthPlanFilters(plan, { metalLevels, planTypes, other }),
    );
  }, [sortedPlans, sortMethod, metalLevels, planTypes, other]);

  const recommendedSections = useMemo(() => {
    let sections: Array<{ title: Copy; subtitle?: Copy; plan: ApolloScoredHealthPlan }> = [];

    // keep track of already showcased plans so that we don't show the same plan more than once
    const showcasedPlans: Array<string> = [];

    const addPlan = ({ title, subtitle, planID }) => {
      if (!showcasedPlans.includes(planID) && planLookup) {
        showcasedPlans.push(planID);
        sections.push({ title, subtitle, plan: planLookup[planID] });
      }
    };

    if (previouslySelectedID && planLookup && previouslySelectedID in planLookup) {
      addPlan({
        title: 'Previously chosen',
        subtitle: undefined,
        planID: previouslySelectedID,
      });
    }

    if (planSearch?.topPlans.catchRecommended) {
      addPlan({
        title: c('healthExplorer.healthPlansList.catchRecommended.title'),
        subtitle: c('healthExplorer.healthPlansList.catchRecommended.subtitle'),
        planID: planSearch?.topPlans.catchRecommended?.planID,
      });
    }

    if (planSearch?.topPlans.budgetPlan) {
      addPlan({
        title: c('healthExplorer.healthPlansList.budgetPlan.title'),
        subtitle: c('healthExplorer.healthPlansList.budgetPlan.subtitle'),
        planID: planSearch?.topPlans.budgetPlan?.planID,
      });
    }

    if (planSearch?.topPlans.comprehensivePlan) {
      addPlan({
        title: c('healthExplorer.healthPlansList.comprehensivePlan.title'),
        subtitle: c('healthExplorer.healthPlansList.comprehensivePlan.subtitle'),
        planID: planSearch?.topPlans.comprehensivePlan?.planID,
      });
    }

    return sections;
  }, [planSearch?.topPlans, planLookup, locale]);

  const recommendedPlans =
    recommendedSections.length === 0 ? (
      <Stack>
        {isWindowShopping && (
          <Banner
            title="More plans to come, check back soon"
            subtitle="Your state hasn't released all of their health plans yet"
            bg="negativeLight"
            asset={{ svg: ShieldWarning }}
          />
        )}
        <EmptyState
          title={c('healthExplorer.healthPlansList.noRecs.title')}
          subtitle={c('healthExplorer.healthPlansList.noRecs.subtitle')}
        />
      </Stack>
    ) : (
      recommendedSections.map((section) => (
        <Stack key={section.title} spacing="0b">
          <Layout.Header size="smol" title={section.title} subtitle={section.subtitle} />
          <PlanShoppingCard
            selectedID={selectedID}
            selectPlan={selectPlan}
            enrollmentType={enrollmentType}
            plan={section.plan}
            disabled={disabled}
          />
        </Stack>
      ))
    );

  const allPlans = (
    <Stack spacing="1">
      <Carousel spacing="0b">
        <Button
          disabled={disabled}
          testID="sort-plans"
          alt
          icon="Sort"
          size="mini"
          onPress={() => openSheet('sort')}
        >
          {c('health.sortPlansActionSheet.sort.buttonHeader')}
        </Button>
        <Button
          disabled={disabled}
          testID="filter-plan-type"
          alt
          size="mini"
          onPress={() => openSheet('planType')}
        >
          {c('health.sortPlansActionSheet.planType.buttonHeader')}
        </Button>
        <Button
          disabled={disabled}
          testID="filter-metal-level"
          alt
          size="mini"
          onPress={() => openSheet('metalLevel')}
        >
          {c('health.sortPlansActionSheet.metalLevel.buttonHeader')}
        </Button>
        <Button
          disabled={disabled}
          testID="filter-other"
          alt
          size="mini"
          onPress={() => openSheet('other')}
        >
          More
        </Button>
      </Carousel>
      {filteredPlans.length === 0 ? (
        <EmptyState
          title={c('healthExplorer.healthPlansList.noResults.title')}
          subtitle={c('healthExplorer.healthPlansList.noResults.subtitle')}
          onPress={() => resetFilters()}
        />
      ) : (
        filteredPlans.map((plan) => (
          <PlanShoppingCard
            disabled={disabled}
            key={plan.healthPlan.planID}
            selectedID={selectedID}
            selectPlan={selectPlan}
            plan={plan}
            enrollmentType={enrollmentType}
          />
        ))
      )}
      <Dialog accentColor="coverage" isOpen={!!sheet} onRequestClose={closeSheet}>
        <SheetBlueprint
          title={c(`health.sortPlansActionSheet.${sheet}.title`)}
          hasDivider
          actions={sheet ? actions[sheet] : []}
        />
      </Dialog>
    </Stack>
  );

  return (
    <Stack spacing="1">
      <SegmentedControl
        index={index}
        setIndex={setIndex}
        controls={[{ title: c('recommended') }, { title: c('all-plans', { num: plans?.length }) }]}
      />
      {index === 0 ? recommendedPlans : allPlans}
    </Stack>
  );
};

export default HealthPlans;
