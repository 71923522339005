import { gql } from '../generated';

export const UpdateUser = gql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUserNew(input: $input) {
      user {
        id
        nickname
        givenName
        familyName
        dob
        ssn
        jobTitle
        workType
        filingStatus
        estimated1099Income
        estimatedW2Income
        numTaxDependents
        workState
        spouseIncome
        legalAddress {
          street1
          street2
          city
          state
          zip
        }
        isControlPerson
        isFirmAffiliated
        isPoliticallyExposed
        subjectBackupWithholding
        dba
        ein
      }
      error {
        code
        field
        message
      }
    }
  }
`);
