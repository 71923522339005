import React, { useMemo } from 'react';
import { SheetComponentProps } from '@navigate';
import { SheetBlueprint } from '@app/blueprints';
import { getEventProperties } from '@app/utils';
import EventReceipt from '@common/components/EventReceipt';

const EventReceiptSheet: React.FC<SheetComponentProps['EVENT_RECEIPT']> = ({ event }) => {
  const props = useMemo(() => getEventProperties(event), [event]);

  return (
    <SheetBlueprint
      title={props?.receiptTitle}
      subtitle={`${props?.status}${props?.returnCode ? ' (' + props?.returnCode + ')' : ''}`}
      asset={props?.asset}
    >
      <EventReceipt noHeader noMargin event={event} eventProperties={props} />
    </SheetBlueprint>
  );
};

export default EventReceiptSheet;
