import gql from 'graphql-tag';

const CHANGE_PHONE = gql`
  mutation _ChangePhone($input: ChangePhoneInput!) {
    changePhone(input: $input) {
      error
    }
  }
`;

export default {
  document: CHANGE_PHONE,
};
