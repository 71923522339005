import styled from 'styled-components';
import { useRef, useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { FontSize, FontWeight } from '@app/types';
import { Text } from './Text';
import { IconSvg } from './Asset';
import Arrow from '@svg/chevron-down.svg';

const Pressable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;

  &:hover {
    cursor: pointer;
  }
`;

export interface AccordionProps {
  title: string;
  initOpen?: boolean;
  small?: boolean;
  divider?: boolean;
  children: React.ReactNode;
}

export const Accordion = ({ title, initOpen, small, children }: AccordionProps) => {
  // uses control if passed, otherwise use state
  const [isOpen, setOpen] = useState(!!initOpen);

  // tracks the content height to animate properly
  const contentHeight = useRef<HTMLDivElement | null>(null);
  const [initialHeight, setHeight] = useState(0);
  const height = contentHeight?.current?.scrollHeight || initialHeight || 0;

  useEffect(() => {
    if (contentHeight?.current) {
      setHeight(contentHeight?.current?.scrollHeight);
    }
  }, []);

  const [containerStyle] = useSpring(
    () => ({
      height: isOpen ? height + 61 + 16 : 61,
      overflow: isOpen ? undefined : 'hidden',
    }),
    [isOpen, height],
  );

  const [contentStyle] = useSpring(
    () => ({
      opacity: isOpen ? 1 : 0,
    }),
    [isOpen],
  );

  const [iconStyle] = useSpring(
    () => ({
      rotate: isOpen ? '0deg' : '-90deg',
      height: 24,
      width: 24,
    }),
    [isOpen],
  );

  return (
    <animated.div style={containerStyle}>
      <Pressable role="button" onClick={() => setOpen(!isOpen)}>
        <Text
          size={small ? FontSize.fp : FontSize.lg}
          weight={small ? FontWeight.semibold : FontWeight.medium}
        >
          {title}
        </Text>
        <animated.div style={iconStyle}>
          <IconSvg svgComponent={Arrow} size={24} color="subtle" />
        </animated.div>
      </Pressable>
      <animated.div ref={contentHeight} style={contentStyle}>
        {children}
      </animated.div>
    </animated.div>
  );
};

export default Accordion;
