import { toast } from 'react-toastify';
import { Copy } from '@catch/types';
import { FontSize, FontWeight } from '@app/types';
import { Stack } from '@app/layouts';
import Text from '../Text';

export enum ToastType {
  success = 'success',
  error = 'error',
  neutral = 'neutral',
  important = 'important',
  coverage = 'coverage',
}

const themes: Record<ToastType, 'light' | 'dark'> = {
  [ToastType.success]: 'light',
  [ToastType.error]: 'light',
  [ToastType.neutral]: 'light',
  [ToastType.important]: 'dark',
  [ToastType.coverage]: 'light',
};

interface ToastProps {
  title: Copy;
  type: ToastType;
  autoCloseIn?: number | false;
  msg?: Copy;
}

/**
 * Note: if you're looking for the styling of the toast itself,
 * see ToastContainer for radiuses, sizing, etc.
 */
export function Toast({ t }: { t: ToastProps }) {
  const theme = themes[t.type];

  return (
    <Stack>
      <Text
        size={FontSize.fp}
        weight={FontWeight.medium}
        group
        color={theme === 'light' ? 'text' : 'snow'}
      >
        {t.title}
      </Text>
      {!!t.msg && (
        <Text size={FontSize.sm} color={theme === 'light' ? 'subtle' : 'snow'}>
          {t.msg}
        </Text>
      )}
    </Stack>
  );
}

export const popToast = (t: ToastProps) => {
  toast(<Toast t={t} />, {
    autoClose: t.autoCloseIn,
    position: 'bottom-right',
    className: `CatchToast__${t.type}`,
  });
};
