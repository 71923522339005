import React from 'react';

import { createLogger } from '@app/utils/logger';

const Log = createLogger('idle');

// Convert to string to do deep compare
const eventsChanged = (yeoldevents, yonnewevents) =>
  yeoldevents.sort().toString() !== yonnewevents.sort().toString();

class Idle extends React.PureComponent {
  static defaultProps = {
    defaultIdle: false,
    children: () => null,
    onChange: () => {},
    timeout: 120000,
    preTimeout: 60000,
    events: ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll'],
  };

  constructor(props) {
    super(props);
    this.state = {
      idle: props.defaultIdle,
      warning: false,
    };
  }

  timeout = null;
  preTimeout = null;

  componentDidMount() {
    this.attachEvents();
    this.setTimeouts();
  }

  componentWillUnmount() {
    this.removeEvents();
    clearTimeout(this.timeout);
    if (this.preTimeout) clearTimeout(this.preTimeout);
  }

  componentDidUpdate(prevProps) {
    if (eventsChanged(prevProps.events, this.props.events)) {
      this.removeEvents();
      this.attachEvents();
    }
  }

  attachEvents = (_) => {
    this.props.events.forEach((event) => {
      window.addEventListener(event, this.handleEvent, true);
    });
  };

  removeEvents = (_) => {
    this.props.events.forEach((event) => {
      window.removeEventListener(event, this.handleEvent, true);
    });
  };

  handleChange = (idle) => {
    this.props.onChange({ idle });
    this.setState({ idle });
  };

  handleWarn = (warning) => {
    Log.debug('idle warning');
    this.props.onWarn({ warning });
    this.setState({ warning });
  };

  // If an event is triggered we're not iddle
  handleEvent = () => {
    if (this.state.idle) {
      this.handleChange(false);
    }
    if (this.state.warning) {
      this.handleWarn(false);
    }
    clearTimeout(this.timeout);
    if (this.preTimeout) clearTimeout(this.preTimeout);
    this.setTimeouts();
  };

  setTimeouts = (_) => {
    this.timeout = setTimeout(() => {
      this.handleChange(true);
    }, this.props.timeout);

    if (this.props.onWarn) {
      this.preTimeout = setTimeout(() => {
        this.handleWarn(true);
      }, this.props.timeout - this.props.preTimeout);
    }
  };

  render() {
    return this.props.children(this.state);
  }
}

export default Idle;
