import React, { useCallback, useMemo } from 'react';
import gql from 'graphql-tag';
import { Segment, SegmentEvent, useCopy } from '@util';
import { HealthApplicationQuery, useMutation, useQuery } from '@data';
import { navigate } from '@navigate';
import {
  determineDeprivationNavigation,
  determineOtherCoverage,
  isMarketplacePrelim,
} from '@navigate/EDENavigationGuard';
import { SplitFormBlueprint } from '@blueprints';
import { Trinary } from '@app/types';
import { fields } from './outsideHouseholdFields';
import { formatPayload, getInitialValues } from './outsideHouseholdUtils';
import { Route } from '@types';

const UpsertOutsideHouseholdMembers = gql`
  mutation UpsertOutsideHouseholdMembers($input: UpsertApplicationMembersInput!) {
    upsertApplicationMembers(input: $input) {
      id
      hasAbsentParent
    }
  }
`;

const OutsideHousehold = ({ applicationID }) => {
  const { c: ede } = useCopy('catch.ede');

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const members = useMemo(() => {
    if (data?.viewerTwo?.health?.application) {
      const { applicant, members } = data?.viewerTwo?.health?.application || {};
      return members ? [applicant, ...members] : [applicant];
    }
  }, [data]);

  const handleNext = useCallback(() => {
    Segment.track(SegmentEvent.APPLICATION_OUTSIDE_HOUSEHOLD_COMPLETED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    if (determineDeprivationNavigation(data?.viewerTwo?.health.application.uiQuestionsToDisplay)) {
      return navigate(Route.EDE_MEMBER_WORK_HOURS);
    } else if (determineOtherCoverage(members)) {
      return navigate(Route.EDE_OTHER_COVERAGE);
    } else if (isMarketplacePrelim(members)) {
      return navigate(Route.EDE_SEP);
    } else {
      return navigate(Route.EDE_REVIEW);
    }
  }, []);

  // handles filtering members and setting initial values
  const initialValues = useMemo(() => getInitialValues(members), [members]);

  const [upsert, { loading: upserting }] = useMutation<
    {
      upsertApplicationMembers: Array<{
        id: string;
        hasAbsentParent: boolean;
        __typename: 'ApplicationMember';
      }>;
    },
    {
      input: {
        applicationID: string;
        applicationMembersInput: Array<{ id: string; hasAbsentParent: Trinary }>;
      };
    }
  >(UpsertOutsideHouseholdMembers);

  const onSubmit = async (values) => {
    try {
      upsert({
        variables: {
          input: {
            applicationID,
            applicationMembersInput: formatPayload(values),
          },
        },
        optimisticResponse: {
          upsertApplicationMembers: values.members.map((m) => ({
            id: m.id,
            hasAbsentParent: m.hasAbsentParent,
            __typename: 'ApplicationMember',
          })),
        },
      });

      handleNext();
    } catch (e) {}
  };

  return (
    <SplitFormBlueprint
      title={ede('HealthEnrollment.241_L')}
      subtitle={ede('HealthEnrollment.241_Q')}
      formConfig={{
        fields,
        initialValues,
        onSubmit,
      }}
      loading={!data}
      submitting={upserting}
    />
  );
};

export const OutsideHouseholdView = {
  name: Route.EDE_OUTSIDE_HOUSEHOLD,
  component: OutsideHousehold,
  options: {
    title: 'Family details',
  },
};
