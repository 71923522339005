import React from 'react';
import { shallow } from 'zustand/shallow';
import { useAuth, useAuthEvents } from '@client/Auth';
import { navigate } from '@navigate';
import {
  createLogger,
  Segment,
  Env,
  useCopy,
  Storage,
  SegmentEvent,
  safeFormatDate,
} from '@app/utils';
import { Button, Link, Spinner, Text } from '@app/_ui-kit';
import {
  DirectSignup,
  FontSize,
  HealthPathway,
  OnboardingBenefit,
  RegistrationType,
} from '@app/types';
import { getSignupContextOptions } from './util/context';
import { AuthBlueprint } from '@app/blueprints';
import { RegistrationDisclosures } from '@app/_common';
import { Route } from '@types';
import { Color } from '@app/styles';
import { useContentQuery, GetPartner } from '@app/data';

const Log = createLogger('signup:view');

// needs to be shared with signin
interface RegisterComponentParams {
  initialVals?: any;
  benefit?: OnboardingBenefit;
  vertical?: OnboardingBenefit;
  r?: string;
  hr?: boolean;
  hid?: string;
  pathway?: HealthPathway;
  phone?: string;
  email?: string;
  zip?: string;
  partner?: string;
  next?: string;
  income?: string;

  // grouped into signup params
  direct?: DirectSignup;
}

const copyConfigs: Record<RegistrationType, { title: string; subtitle: string }> = {
  [RegistrationType.Standard]: {
    title: 'module.login.registerform.title',
    subtitle: 'module.login.registerform.subtitle',
  },
  [RegistrationType.Concierge]: {
    title: 'signup.concierge.title',
    subtitle: 'signup.concierge.subtitle',
  },
};

const signupFields = [
  {
    name: 'name',
    type: 'legalName',
    label: 'Name',
    required: true,
    subfields: {
      givenName: true,
      middleName: false,
      familyName: true,
      nameSuffix: false,
    },
  },
  { name: 'alias', type: 'email', required: true },
  { name: 'password', type: 'password', passwordType: 'new', label: 'Password', required: true },
  {
    type: 'checkbox',
    name: 'brokerConsent',
    dependencies: ['name'],
    required: true,
    scroll: 105,
    plain: true,
    label: ({ name }) => {
      const fullName = name.givenName
        ? `${name?.givenName}${name.familyName ? ` ${name.familyName}` : ''}`
        : '';

      return {
        id: 'ede.HealthEnrollment.BROKER_CONSENT',
        data: {
          legalName: fullName ? `, ${fullName},` : '',
          date: safeFormatDate(new Date(), 'MMMM d, yyyy'),
        },
      };
    },
  },
];

/**
 * does a lot of lifting on the signup params from the website or partner sites
 * @todo share types with signIn ...
 */
const RegisterComponent = ({
  initialVals,
  r: signupCode,
  hr: hideSignupCode,
  pathway,
  phone: initialPhone,
  email: initialEmail,
  zip,
  vertical,
  benefit,
  partner,
  income,
  next, // otherwise, this navigation prop gets sent on signup params and fails
  importing,
  ...additionalSignupParams
}: RegisterComponentParams) => {
  const { c } = useCopy('catch');

  const { loading: loadingPartner, data } = useContentQuery(GetPartner, {
    variables: { slug: partner || signupCode },
    skip: !(partner || signupCode),
  });

  const type: RegistrationType = [DirectSignup.BenefitsAdvisor, DirectSignup.Fastlane].includes(
    additionalSignupParams?.direct,
  )
    ? RegistrationType.Concierge
    : RegistrationType.Standard;

  const config = copyConfigs[type];

  const { confirmedExpressBenefit, requestedBenefit, signupContext, onboardingType } =
    getSignupContextOptions({ benefit, healthID: additionalSignupParams.hid, pathway });

  delete additionalSignupParams['*'];
  const signupParams = additionalSignupParams;

  const { signUp, error, loading } = useAuth(
    (state) => ({
      temporaryAlias: state.temporaryCredentials.alias,
      loading: state.loading,
      step: state.authState,
      steps: state.states,
      signUp: state.signUp,
      confirmCode: state.confirmCode,
      error: state.error,
    }),
    shallow,
  );

  useAuthEvents({
    onPendingConfirmation: ({ temporaryAlias }) => {
      Segment.identifyUser(null, {
        alias: temporaryAlias,
        referral_link: document.referrer,
        initial_user: true,
      });
      Segment.pageView();
      Segment.track(SegmentEvent.REGISTRATION_SUBMITTED, { alias: temporaryAlias });
    },
    onSignedIn: ({ user }) => {
      if (!!user) {
        Segment.identifyUser(user.id, {
          email: user.email,
          phoneNumber: user.phoneNumber,
          firstname: user.givenName,
          coupon_code: user.referralCode || signupCode,
          partner,
        });
      }
    },
  });

  Log.debug({
    signupContext: {
      signupCode,
      hideSignupCode,
      pathway,
      initialPhone,
      initialEmail,
      zip,
      vertical,
      benefit,
      requestedBenefit,
      confirmedExpressBenefit,
      onboardingType,
      partner,
      ...additionalSignupParams,
    },
  });

  const initialValues = {
    alias: initialPhone || initialEmail,
    password: initialVals?.password || '',
    signupCode,
    zip,
    signupParams,
    signupContext,
    expressBenefit: confirmedExpressBenefit,
  };

  const handleAuto = () => {
    const name =
      Storage.getItem('devAlias') || prompt('email alias, por favor (email part before the @)');
    Storage.setItem('devAlias', name);
    const email = `${name}+2fa_${Date.now()}@catch.co`;
    signUp({ ...initialValues, alias: email, password: '1Like$oup' });
  };

  const handleLogin = () => {
    navigate(Route.LOGIN, {
      hid: additionalSignupParams.hid,
      benefit,
      zip,
      income,
      pathway,
      email: initialEmail,
    });
  };

  if (loadingPartner) {
    return <Spinner />;
  }

  return (
    <AuthBlueprint<{}>
      loading={false}
      submitting={loading || importing}
      logo={data?.partner?.logo?.url}
      hex={data?.partner?.colorLight?.hex}
      title={c(config.title)}
      subtitle={c(config.subtitle, {
        login: (
          <Link testID="sign-in" onPress={handleLogin}>
            sign in
          </Link>
        ),
        partner: data?.partner?.name || false,
      })}
      error={error}
      formConfig={{
        initialValues,
        fields: signupFields,
        onSubmit: signUp,
      }}
      actions={({ handleSubmit, disabled, submitting }) => [
        <Button
          key="submit"
          testID="submit"
          type="submit"
          full
          disabled={disabled}
          loading={submitting}
          onPress={handleSubmit}
        >
          Create account
        </Button>,
        Env.isDevLike ? (
          <Button testID="auto" full scheme="brand" alt disabled={submitting} onPress={handleAuto}>
            Just make me one
          </Button>
        ) : null,
      ]}
      postContent={
        <>
          <Text color="subtler" size={FontSize.fp}>
            {c('module.login.registerform.signinLabel')}{' '}
            <Link testID="sign-in" color={Color.text} onPress={handleLogin}>
              {c('module.login.registerform.signinLink')}
            </Link>
          </Text>
          <RegistrationDisclosures />
        </>
      }
    />
  );
};

export const RegisterView = {
  name: Route.REGISTER,
  component: RegisterComponent,
  guest: true,
  options: {
    title: 'Get started',
    largeTitle: true,
    drawBehindNav: true,
  },
};
