import React from 'react';

import { Text } from '@uikit';
import { Env } from '@app/utils';
import { FlexAlignType, View } from 'react-native';

const SIZE = 'sm';

const Entry: React.FC<{
  name: string;
  value?: string;
}> = ({ name, value }) => {
  if (!value) {
    return null;
  }

  return (
    <View style={{ opacity: 0.5 }}>
      <Text>
        <Text size={SIZE} color="subtler">
          {name}{' '}
        </Text>
        <Text size={SIZE} color="secondary">
          {value}
        </Text>
      </Text>
    </View>
  );
};

/**
 * VersionLabel to show which build we're on
 */
const VersionLabel: React.FC<{
  always?: boolean;
  align?: FlexAlignType;
}> = ({ always = false, align = 'flex-start' }) => {
  return !!Env.isInternal || always ? (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: align,
      }}
    >
      <Entry
        name={Env.envName}
        value={`${Env.buildConfig.version} ${
          Env.envName && Env.envName !== '' ? '(' + Env.buildConfig.number + ')' : ''
        }`}
      />
    </View>
  ) : (
    <></>
  );
};

export default VersionLabel;
