import { useState } from 'react';
import useDeprecatedQuery from '@data/useDeprecatedQuery';
import { safeObject as safe } from '@util';

export const usePagination = (
  query,
  { pageSize = 25, variables = {}, joinResults, filter, queryOptions },
) => {
  const [page, setPage] = useState(1);

  const { waiting, loading, fetchMore, pageInfo, ...data } = useDeprecatedQuery(query, {
    filter,
    variables: {
      page: { pageNumber: 1, pageSize },
      ...variables,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    ...safe(queryOptions),
  });

  const next = () => {
    fetchMore({
      variables: {
        page: {
          pageNumber: page + 1,
          pageSize,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return joinResults(prev, fetchMoreResult);
      },
    });

    setPage(page + 1);
  };

  return {
    initialLoading: waiting,
    loadingMore: loading,
    canFetchMore: pageInfo?.hasNextPage,
    fetchMore: next,
    ...data,
  };
};
