import { usePagination } from './usePagination';

export const useActivity = ({ goalID, enrollmentID, pageSize, eventTypes, sourceID } = {}) => {
  const { events, initialLoading, loadingMore, canFetchMore, fetchMore } = usePagination(
    'activity',
    {
      pageSize,
      returnPartialData: true,
      variables: { goalID, enrollmentID, eventTypes, sourceID },
      joinResults: (prev, next) => ({
        ...prev,
        viewer: {
          ...prev.viewer,
          activity: {
            ...prev.viewer.activity,
            edges: [...prev.viewer.activity.edges, ...next.viewer.activity.edges],
            pageInfo: next.viewer.activity.pageInfo,
          },
        },
      }),
    },
  );

  return {
    initialLoading,
    loadingMore,
    canFetchMore,
    fetchMore,
    events: initialLoading ? Array.from(Array(pageSize).keys()) : events,
  };
};
