import React, { useMemo } from 'react';
import { SectionList, View } from 'react-native';

import {
  boolToTri,
  capitalizeAll,
  lastFour,
  safeFormatDate,
  useCopy,
  formatList,
  capitalize,
  replaceAll,
  Segment,
  SegmentEvent,
} from '@app/utils';
import {
  useQuery,
  HealthApplicationQuery,
  getMembers,
  useMutation,
  UpsertHealthApplication,
} from '@data';
import { navigate, pop } from '@navigate';
import { Button, ComplexRow, Divider, Link } from '@uikit';
import { SectionTitle, SplitLayout, Toolbar } from '@layouts';
import { Route } from '@types';
import { usePrint } from '@app/hooks';

interface ReviewField {
  display?: boolean;
  title: string;
  value: any;
  path: Route;
}

interface Section {
  key: string;
  title: string;
  data: Array<ReviewField>;
  display?: boolean;
}

const formatName = (m) => {
  const middle = m?.middleName ? `${m.middleName} ` : '';
  const suffix = m?.nameSuffix || m?.suffix ? ` ${m?.nameSuffix || m?.suffix}` : '';
  return `${m?.givenName || m?.firstName} ${middle}${m?.familyName || m?.lastName}${suffix}`;
};

const formatBool = (val) => {
  return capitalize(boolToTri(val));
};

const formatValue = (val) => {
  return capitalizeAll(replaceAll(val, '_', ' '));
};

const formatPhone = (phone) => {
  // check if the phone is stored formatted or not
  return phone?.includes('-')
    ? phone
    : `${phone?.substring(0, 3)}-${phone?.substring(3, 6)}-${phone?.substring(6, 10)}`;
};

const formatContact = (method, phone) => {
  if (method === 'E_TEXT') {
    const phoneNumber = phone ? `\n${phone}` : '';
    return `Text Message ${phoneNumber}`;
  }

  return capitalizeAll(method);
};

const formatAddress = (address) => {
  const street2 = address?.street2 ? `${address?.street2}, ` : '';
  return `${address?.street1}, ${street2}${address?.city}, ${address?.state}`;
};

const formatSSN = (ssn) => {
  return ssn ? `••• - •• - ${lastFour(ssn)}` : 'None';
};

const formatPeople = (members) => {
  return members?.map((m) => `${formatName(m)}\n`);
};

const formatDate = (date) => {
  return safeFormatDate(date, 'MMM d, yyyy');
};

const formatSEP = (member) => {
  let sep = '';

  const hasAnySEP = [
    member?.isLostCoverageLast60Days,
    member?.isWillLoseCoverageNext60Days,
    member?.isMarriedLast60Days,
    member?.isReleasedFromIncarceration,
    member?.isMoved,
    member?.isGainDependent,
    member?.isGainedLawfulPresence,
  ].some((val) => val !== null);

  if (!hasAnySEP) return '';

  if (member?.isLostCoverageLast60Days) {
    sep += `Lost coverage ${formatDate(member?.coverageLostDate)}\n`;
  }
  if (member?.isWillLoseCoverageNext60Days) {
    sep += `Will lose coverage ${formatDate(member?.coverageLostDateFuture)}\n`;
  }
  if (member?.isMarriedLast60Days) {
    sep += `Married on ${formatDate(member?.whenMarried)}\n`;
  }
  if (member?.isReleasedFromIncarceration) {
    sep += `Released from incarceration on ${formatDate(member?.whenReleasedFromIncarceration)}\n`;
  }
  if (member?.isMoved) {
    sep += `Moved on ${formatDate(member?.whenRelocated)}\n`;
  }
  if (member?.isGainDependent) {
    sep += `Adopted or placed in foster care on ${formatDate(member?.whenBecomeADependent)}\n`;
  }
  if (member?.isGainedLawfulPresence) {
    sep += `Gained lawful presence ${formatDate(member?.whenLawfulPresence)}\n`;
  }

  return sep || 'None';
};

const ReviewApplication = ({ applicationID }) => {
  const { printRef, handlePrint } = usePrint();

  const { c, $ } = useCopy('catch.ede');

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: upserting }] = useMutation(UpsertHealthApplication);

  const sections: Array<Section> = useMemo(() => {
    const app = data?.viewerTwo?.health?.application;
    const applicant = app?.applicant;
    const allMembers = getMembers(data);

    const household: Section = {
      key: 'household',
      title: 'Household',
      data: [
        { title: 'Coverage state', value: app?.coverageState, path: Route.EDE_COVERAGE_INFO },
        {
          title: 'Coverage year',
          value: app?.coverageYearNumber,
          path: Route.EDE_COVERAGE_INFO,
        },
        {
          title: 'Members',
          value: formatPeople(allMembers),
          path: Route.EDE_CONFIRM_APPLICANTS,
        },
        {
          title: 'Marital status',
          value: capitalizeAll(app?.maritalStatus),
          path: Route.EDE_HOUSEHOLD,
        },
        {
          title: 'Num tax dependents',
          value: app?.numTaxDependents,
          path: Route.EDE_HOUSEHOLD,
          display: app?.numTaxDependents > 0,
        },
      ],
    };

    const assistors = {
      key: 'assistors',
      title: 'Assistance',
      data: app?.applicationAssistors?.map((assistor) => ({
        title: formatName(assistor),
        value: c(`enums.${assistor?.applicationAssistorType?.replace('ASSISTOR_TYPE_', '')}`),
        path: Route.EDE_PRO_ASSISTANCE,
      })),
      display: app?.applicationAssistors?.length > 0,
    };

    const communications: Section = {
      key: 'comms',
      title: 'Communications',
      data: [
        {
          title: 'Written Language',
          value: capitalizeAll(applicant?.writtenLanguageType),
          path: Route.EDE_ID_INFO,
        },
        {
          title: 'Spoken Language',
          value: capitalizeAll(applicant?.spokenLanguageType),
          path: Route.EDE_ID_INFO,
        },
        {
          title: 'Contact Method',
          value: formatContact(
            applicant?.contactMethod,
            applicant?.mobileNotificationPhoneNumber || applicant?.phone?.number,
          ),
          path: Route.EDE_ID_INFO,
        },
        { title: 'Email', value: applicant?.email, path: Route.EDE_ID_INFO },
        { title: 'Phone', value: formatPhone(applicant?.phone?.number), path: Route.EDE_ID_INFO },
        {
          title: 'Home Address',
          value: formatAddress(applicant?.homeAddress),
          path: Route.EDE_ID_INFO,
        },
        {
          title: 'Mailing Address',
          value: formatAddress(applicant?.mailingAddress),
          path: Route.EDE_ID_INFO,
        },
      ],
    };

    const applicants: Array<Section> = allMembers.map((m) => ({
      key: m?.id,
      title: formatName(m),
      data: [
        {
          title: 'Relation',
          value: capitalizeAll(m?.relation),
          path: Route.EDE_CONFIRM_APPLICANTS,
        },
        {
          title: 'Requesting Coverage',
          value: formatBool(m?.isRequestingCoverage),
          path: Route.EDE_CONFIRM_APPLICANTS,
        },
        { title: 'Date of Birth', value: formatDate(m?.dob), path: Route.EDE_MEMBER_INFO },
        { title: 'Sex', value: capitalizeAll(m?.sex), path: Route.EDE_MEMBER_INFO },
        { title: 'SSN', value: formatSSN(m?.ssn), path: Route.EDE_MEMBER_INFO },
        { title: 'U.S. Citizen', value: formatBool(m?.isCitizen), path: Route.EDE_MEMBER_INFO },
        { title: 'SSN', value: formatBool(m?.ssn), path: Route.EDE_MEMBER_INFO },
        {
          title: 'Race',
          value: formatList(m?.race, (field) => formatValue(field)),
          path: Route.EDE_MEMBER_INFO,
        },
        {
          title: 'Pregnant',
          value: formatBool(m?.isPregnant),
          display: m?.uiQuestionsToDisplay?.['147'],
          path: Route.EDE_MEMBER_QUESTIONS,
        },
        {
          title: 'Incarcerated',
          value: capitalize(m?.incarcerationType?.split('_').join(' ')),
          display: m?.uiQuestionsToDisplay?.['250'],
          path: Route.EDE_MEMBER_QUESTIONS,
        },
        {
          title: 'Foster Care',
          value: formatBool(m?.isFosterCare1825),
          display: m?.uiQuestionsToDisplay?.['149'],
          path: Route.EDE_MEMBER_QUESTIONS,
        },
        {
          title: 'Full Time Student',
          value: formatBool(m?.isFullTimeStudentStatus),
          display: m?.uiQuestionsToDisplay?.['144'],
          path: Route.EDE_MEMBER_QUESTIONS,
        },
        {
          title: 'Medicaid Denied',
          value: m?.medicaidDeniedDate ? formatDate(m?.medicaidDeniedDate) : 'No',
          path: Route.EDE_MEDICAID_DENIAL,
          display: m?.medicaidDeniedDate !== null,
        },
        {
          title: 'Medicaid Ended',
          value: m?.medicaidEndDate ? formatDate(m?.medicaidEndDate) : 'No',
          path: Route.EDE_MEDICAID_DENIAL,
          display: m?.medicaidEndDate !== null,
        },
        {
          title: 'Income',
          value: $(m?.annualTaxIncome?.incomeAmount),
          path: Route.EDE_MEMBER_INCOME_CONFIRM,
          display: app?.screening?.isRequestingFinancialAssistance,
        },
        {
          title: 'Other Coverage',
          value: formatList(m?.otherCoverages, (coverage) =>
            c(`enums.${coverage?.insuranceMarketType}`),
          ),
          path: Route.EDE_OTHER_COVERAGE,
          display: app?.screening?.isRequestingFinancialAssistance,
        },
      ],
    }));

    const hasAnySEP = (allMembers?.filter((m) => !!formatSEP(m)) || [])?.length > 0;

    const specialEnrollment: Section = {
      key: 'sep',
      title: 'Special Enrollment',
      data: allMembers?.map((member) => ({
        title: formatName(member),
        value: formatSEP(member),
        path: Route.EDE_SEP,
        display: !!formatSEP(member),
      })),
      display: hasAnySEP,
    };

    // filter out non displayed things
    const review = [household, assistors, communications, ...applicants, specialEnrollment];

    return review
      .filter((section) => section.display !== false)
      .map((section) => {
        return {
          ...section,
          data: section?.data?.filter((item) => item.display !== false),
        };
      });
  }, [data?.viewerTwo?.health?.application]);

  const handleNext = () => {
    upsert({
      variables: {
        input: {
          id: applicationID,
          lastUsedRoute: 'review',
        },
      },
    });

    Segment.track(SegmentEvent.APPLICATION_REVIEWED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    navigate(Route.EDE_AGREEMENTS);
  };

  return (
    <SplitLayout
      loading={!data}
      title={c('ReviewApplication.title')}
      subtitle={
        <>
          {c('ReviewApplication.subtitle')}
          <br />
          <br />
          <Link testID="print" onPress={handlePrint}>
            Save and print
          </Link>
        </>
      }
      toolbar={
        <Toolbar onBack={pop}>
          <Button
            disabled={upserting}
            loading={upserting}
            inherit
            testID="enroll"
            onPress={handleNext}
          >
            Next
          </Button>
        </Toolbar>
      }
    >
      <div ref={printRef}>
        <SectionList<{ title: string; data: Array<ReviewField> }>
          sections={sections}
          renderItem={({ item }) => {
            return (
              <ComplexRow
                label={item.title}
                sublabel={item.value}
                accessory={
                  <Link className="no-print" testID="edit" onPress={() => navigate(item.path)}>
                    Edit
                  </Link>
                }
              />
            );
          }}
          renderSectionHeader={({ section }) => {
            return <SectionTitle>{section.title}</SectionTitle>;
          }}
          ItemSeparatorComponent={() => <Divider />}
          renderSectionFooter={() => <View style={{ marginBottom: 32 }} />}
        />
      </div>
    </SplitLayout>
  );
};

export const ReviewApplicationView = {
  name: Route.EDE_REVIEW,
  component: ReviewApplication,
  options: {
    ...SplitLayout.options,
    title: 'Application review',
  },
};
