import gql from 'graphql-tag';

const ActivityGoal = gql`
  fragment ActivityGoal on Goal {
    id
    type: goalType
    name
    slug
  }
`;

const ActivityAccount = gql`
  fragment ActivityAccount on Account {
    id
    accountNumber
    name
  }
`;

const ActivitySource = gql`
  fragment ActivitySource on IncomeTransactionSource {
    id
    text
    taggedSource {
      id
      name
      logo {
        url
      }
    }
  }
`;

export default {
  ActivitySource,
  ActivityAccount,
  ActivityGoal,
};
