import { ApolloClient, InMemoryCache } from '@apollo/client';
import Env from '@app/utils/env';

/**
 * Public client
 */
export const publicClient = new ApolloClient({
  name: 'public',
  uri: Env.graphql.uri?.replace('/graphql', '/public/graphql'),
  cache: new InMemoryCache(),
  devtools: {
    name: 'public',
  },
});

/**
 * Content client
 */
export const contentClient = new ApolloClient({
  name: 'hygraph',
  uri: Env.graphcms.uri,
  cache: new InMemoryCache(),
  devtools: {
    name: 'hygraph',
  },
});
