import { ExternalLink } from '@navigate';
import { Situation } from './base';
import { InteractionAccessoryType, Route } from '@types';
import { SemanticColor, RenderedIcon } from './theme';

export type PromptContext =
  | 'BLOCKING'
  | 'BLOCKING_CLOCK'
  | 'STATUS'
  | 'TODO'
  | 'HEALTH'
  | 'ACCOUNT';
export type PromptSize = 'ALERT' | 'CARD' | 'PAGE';

export type ActionType = 'HELP' | 'LINK' | 'ROUTE' | 'CUSTOM' | 'TOGGLE_LANGUAGE';

export interface ActionIconSet {
  web?: string;
  svg?: string;
  color: string;
}

interface OnActionProps {
  setLoading?: (loading: boolean) => void;
}

export enum PromptName {
  START_HEALTH_APPLICATION = 'START_HEALTH_APPLICATION',
  COVERAGE_SETUP = 'COVERAGE_SETUP',
  WINDOW_SHOPPING = 'WINDOW_SHOPPING',
  OPEN_ENROLLMENT = 'OPEN_ENROLLMENT',
  NON_FFM_OE = 'NON_FFM_OE',
  CONTINUE_APP = 'CONTINUE_APP',
  FINISH_ENROLLING = 'FINISH_ENROLLING',
  NEEDS_HEALTH_DOCS = 'NEEDS_HEALTH_DOCS',
  PAYMENT_REDIRECT = 'PAYMENT_REDIRECT',
  MANUAL_ID_REVIEW = 'MANUAL_ID_REVIEW',
  DOCS_AVAILABLE_2 = 'DOCS_AVAILABLE_2',
  REVIEW_AVAILABLE = 'REVIEW_AVAILABLE',
}

export interface Action {
  id?: string;
  testID?: string;
  label: string;
  type: ActionType;
  route?: Route;
  link?: ExternalLink;
  routeData?: object;
  onAction?: (props: OnActionProps) => void;
  icon?: ActionIconSet;
  destructive?: boolean;
  highlight?: boolean;
  hidden?: boolean;
  loading?: boolean;
  glyph?: any;
  selected?: boolean;
  featureSlug?: string;
  button?: boolean;
  inherit?: boolean;
  interaction?: InteractionAccessoryType;
  selectedByDefault?: boolean;
  disabled?: boolean;
  color?: string;
  accentColor?: SemanticColor;
}

export interface Prompt {
  name: PromptName;
  context: PromptContext;
  title: string;
  subtitle?: string;
  dismissable?: boolean;
  article?: string;
  guide?: string;
  actions?: Action[];
  highlightAsError?: boolean;
  icon?: any;
  dependencies?: Situation[];
  forEach?: boolean; // when this is handled for multiple versions of data
  gradient?: SemanticColor;
  accentColor?: SemanticColor;
  render?: RenderedIcon;
}
