import { Route } from '../paths';

type Tab = 'HOME' | 'INCOME' | 'TAXES' | 'PLAN' | 'COVERAGE' | 'ACCOUNT';
type TabRoute = Tab & Route;

// Pages that are the native bottom tabs
export const TAB_NAMES: Array<TabRoute> = ['HOME', 'COVERAGE'];

// Pages that are web tabs
export const WEB_TABS: Array<TabRoute> = ['HOME', 'COVERAGE'];

export const NEW_TAB_NAMES = {
  HOME: 'Home',
  COVERAGE: 'Coverage',
};
