import gql from 'graphql-tag';

export const RequestManualReview = gql`
  mutation RequestManualReview($identityId: ID!) {
    requestManualReview(id: $identityId) {
      id
      status
      providerType
    }
  }
`;
