import React from 'react';

import { IconSvg, Pressable } from '@uikit';

const NavButton = ({ onPress, icon, iconComponent, isActive, color = 'text' }) => {
  return (
    <Pressable handleOnPress={onPress}>
      {({ intrx }) =>
        iconComponent || (
          <IconSvg
            svgComponent={icon}
            color={isActive ? 'page' : color}
            size="md"
            bg={
              isActive
                ? color
                : intrx.pressed
                ? 'border'
                : intrx.hovered
                ? 'skeleton'
                : 'transparent'
            }
          />
        )
      }
    </Pressable>
  );
};

export default NavButton;
