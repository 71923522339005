import React from 'react';
import { View } from 'react-native';

import { Text } from '@uikit/components/Text';
import { useTheme } from '@uikit/hooks/useTheme';
import SectionTitle from './SectionTitle';
import { CatchTheme } from '@app/_ui-kit/themes/catch';

interface SectionProps {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  style?: any;
  color?: string;
  loading?: boolean;
}

const Section: React.FC<SectionProps> = ({
  children,
  title,
  subtitle,
  style,
  color,
  loading,
  ...other
}) => {
  const { theme } = useTheme();
  return (
    <View style={[theme.ySpace3, theme.fullWidth, theme[`${color}Bg`], style]} {...other}>
      {!!title && (
        <View style={[theme.bottomSpace1]}>
          {!!title && <SectionTitle loading={loading}>{title}</SectionTitle>}
          {!!subtitle && (
            <Text
              loading={loading}
              weight="regular"
              size="fp"
              color="secondary"
              style={styles.subtitle}
            >
              {subtitle}
            </Text>
          )}
        </View>
      )}
      <View style={[theme.fullWidth]}>{children}</View>
    </View>
  );
};

const styles = {
  subtitle: {
    maxWidth: CatchTheme.containers.dialog,
    marginTop: -8,
    marginBottom: 8,
  },
};

export default Section;
