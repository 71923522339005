import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@uikit/hooks/useTheme';
import { Icon, IconSvg, Pressable } from '@app/_ui-kit';
import { openIntercom } from '@app/utils';

import LogoJetpack from '@svg/jetpack.svg';
import LogoFull from '@svg/lockup.svg';

const Nav = styled.nav`
  z-index: 2;
  width: 100%;
  min-height: 56px;
  display: flex;
  align-items: center;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    min-height: 64px;
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 24px;
  padding-right: 24px;
  flex: 1;
  align-items: center;
`;

const NavSection = styled.div`
  flex: 1;
  flex-direction: row;
  align-items: center;
  display: flex;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
`;

const Spacing = styled.div`
  padding-left: 24px;
  margin-left: 24px;
  border-left: 1.5px solid ${({ theme }) => theme.colors.text};
  max-height: 40px;
`;

const StyledLogo = styled.img`
  max-height: 30px;
  max-width: 150px;
  width: fit-content;
  height: auto;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    max-height: 40px;
  }
`;

const NavBar = ({ onLogo, middle, right, useFullLogo, partnerLogo }) => {
  const { themeColors } = useTheme();

  return (
    <Nav>
      <Container>
        <NavSection className="left">
          <Pressable handleOnPress={onLogo}>
            {useFullLogo ? (
              <LogoFull fill={themeColors.textColor} style={{ width: 120 }} />
            ) : (
              <IconSvg svgComponent={LogoJetpack} size="md" color="text" />
            )}
          </Pressable>
          {partnerLogo && (
            <Spacing>
              <StyledLogo src={partnerLogo} />
            </Spacing>
          )}
        </NavSection>
        <>
          {middle}
          <NavSection className="right">
            {right || <Icon type="Help" label="Help" onPress={() => openIntercom()} />}
          </NavSection>
        </>
      </Container>
    </Nav>
  );
};

export default NavBar;
