import React, { useEffect, useMemo } from 'react';
import { useCopy, safeFormatDate, Segment, SegmentEvent } from '@app/utils';
import { HealthApplicationQuery, useQuery } from '@data';
import { exit } from '@navigate';
import { HiccupBlueprint } from '@blueprints';
import { getCarrierAsset } from '@app/utils';
import { Route } from '@types';

/**
 * This page is shown when a user gets to stopping point
 * in the week before
 *
 * @TODO: do we want to add this back to application flow?
 */
const WindowShopping = ({ applicationID }) => {
  const { c: basics } = useCopy('catch.basics');
  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { startTime, nextOEYear, selectedPlan } = useMemo(() => {
    return {
      startTime: new Date(data?.reference?.health?.openEnrollmentDates?.startTime),
      nextOEYear: data?.reference?.health?.openEnrollmentDates?.nextOEYear,
      selectedPlan: data?.viewerTwo?.health?.application?.plan,
    };
  }, [data]);

  useEffect(() => {
    Segment.track(SegmentEvent.WINDOW_SHOPPING_COMPLETED, { coverageYear: nextOEYear });
  }, []);

  return (
    <HiccupBlueprint
      asset={getCarrierAsset(selectedPlan?.issuer?.name || '')}
      title={c('title')}
      subtitles={[c('subtitle', { oeYear: nextOEYear, date: safeFormatDate(startTime, 'MMM. d') })]}
      onPress={() => exit(Route.HOME)}
      buttonText={basics('soundsGood')}
    />
  );
};

export const WindowShoppingView = {
  name: Route.EDE_WINDOW_SHOPPING,
  component: WindowShopping,
  options: {
    title: 'Window Shopping',
  },
};
