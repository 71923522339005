import React from 'react';
import { Copy } from '@types';
import { Text } from '@uikit/components/Text';
import SheetBlueprint from './SheetBlueprint';
import { Layout } from '@app/layouts';

interface Props {
  title: Copy;
  subtitle?: Copy;
  text1?: Copy | any;
  text2?: Copy | any;
  children: any;
}

export const HelpSheetBlueprint: React.FC<Props> = ({
  title,
  subtitle,
  text1,
  text2,
  children,
}) => {
  return (
    <SheetBlueprint>
      <Layout.Header title={title} subtitle={subtitle} />
      <Text>{text1}</Text>
      <Text>{text2}</Text>
      {children}
    </SheetBlueprint>
  );
};

export default HelpSheetBlueprint;
