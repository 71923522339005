import React, { useEffect, useMemo } from 'react';
import { stacks, Stack, StackComponent, navigate, history } from '@navigate';
import { LoginView } from './SignInView';
import { RegisterView } from './RegisterView';
import { ConfirmCodeView } from './ConfirmCodeView';
import { ConfirmPasswordView } from './ConfirmPasswordView';
import { ForgotPasswordView } from './ForgotPasswordView';
import { ConsentView } from './ConsentView';
import { useLocation, useSearchParams } from 'react-router-dom';
import { HealthExplorerQuery, useDeprecatedMutation, useQuery } from '@app/data';
import { useSession } from '@app/hooks';
import { DirectSignup, Route } from '@app/types';
import { useAuth, useAuthEvents } from '@app/client';
import { Loading } from '@app/_ui-kit';

const config = {
  stackName: stacks.AUTH_STACK,
  options: {},
  screens: [
    ConsentView,
    RegisterView,
    LoginView,
    ConfirmCodeView,
    ConfirmPasswordView,
    ForgotPasswordView,
  ],
};

const AuthStack: StackComponent = () => {
  const location = useLocation();
  const [params] = useSearchParams();
  const { init, authState, states } = useAuth();
  const { skipIntake, hid, isConcierge, isFastlane, partner, setSession } = useSession();
  const [importHealth, { loading: importing }] = useDeprecatedMutation('importPublicExplorer');
  const { data } = useQuery(HealthExplorerQuery);

  useEffect(() => {
    setSession({
      direct: (params.get('direct') as DirectSignup) || undefined,
      hid: (params.get('hid') as string) || undefined,
      r: (params.get('r') as string) || undefined,
      skipIntake: Boolean(params.get('sk') || false),
    });

    init();
  }, []);

  // these events will happen anytime a user is signed in, regardless of entry point
  // this includes: 1) users already logged in 2) users logging in and 3) users registering
  useAuthEvents(
    {
      onSignedIn: async ({ created }) => {
        const next = location?.state?.next || {};

        if (next.pathname) {
          history.push(`${next.pathname}${next.search}`);
        } else {
          // pull explorer data from current situation OR the newly imported one
          let explorer = data?.viewerTwo?.healthExplorerData;

          if (hid) {
            const result = await importHealth({ variables: { id: hid } });
            explorer = result?.data?.importExplorerFromPublic;
          }

          if (isConcierge || isFastlane) {
            if (explorer || skipIntake) {
              navigate(Route.EXPLORER_SCHEDULE);
            } else {
              navigate(Route.EXPLORER_ZIPCODE);
            }
          } else if (partner === 'decisely') {
            navigate(Route.EXPLORER_PARTNER_HANDOFF);
          } else if (explorer) {
            if (explorer.canCreateEnrollment && explorer.providerPlanID) {
              navigate(Route.EDE_INTRO);
            } else if (explorer.pathwayType === 'STATE_EXCHANGE' && explorer.providerPlanID) {
              navigate(Route.EXPLORER_STATE_HANDOFF);
            } else if (explorer.state === 'MA') {
              navigate(Route.EXPLORER_CONCIERGE);
            } else {
              navigate(Route.EXPLORER_CHOOSE_PLAN);
            }
          } else {
            if (created) {
              navigate(Route.EXPLORER_ZIPCODE);
            } else {
              navigate(Route.HOME);
            }
          }
        }
      },
    },
    [hid, isConcierge, skipIntake, partner, data],
  );

  const sharedData = useMemo(
    () => ({
      importing,
    }),
    [importing],
  );

  if (authState === states.SIGNED_IN) {
    return <Loading full page />;
  }

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={sharedData}
    />
  );
};

AuthStack.config = config;
export default AuthStack;
