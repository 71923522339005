import React from 'react';
import styled from 'styled-components';
import { AssetSize, RenderedIcon } from '@app/types';
import { CatchTheme } from '@uikit/themes/catch';

/** The Big 5 */
import Taxes from '@renders/tax.png';
import Coverage from '@renders/health.png';
import Retirement from '@renders/retirement.png';
import Saved from '@renders/savings.png';
import Jetpack from '@renders/brand.png';

/** The Big 5 */
import TaxesAlt from '@renders/tax_alt.png';
import CoverageAlt from '@renders/health_alt.png';
import RetirementAlt from '@renders/retirement_alt.png';
import SavedAlt from '@renders/savings_alt.png';

/** Standard Goals */
import TimeOff from '@renders/time-off.png';
import EmergencySavings from '@renders/emergency-savings.png';
import FamilyLeave from '@renders/family-leave.png';
import HealthExpenses from '@renders/health-expenses.png';

/** Custom Goals */
import Cake from '@renders/cake.png';
import Car from '@renders/car.png';
import Gift from '@renders/gift.png';
import House from '@renders/house.png';
import Laptop from '@renders/laptop.png';
import Luggage from '@renders/luggage.png';
import Target from '@renders/target.png';
import Tools from '@renders/tools.png';
import PriceTag from '@renders/price-tag.png';
import Bag from '@renders/bag.png';
import Pet from '@renders/pet.png';
import Safe from '@renders/safe.png';

/** Insurance Types */
import Dental from '@renders/dental.png';
import Vision from '@renders/vision.png';
import Life from '@renders/life.png';

/** Utility */
import BankLink from '@renders/link.png';
import FaceId from '@renders/faceid.png';
import Cog from '@renders/cog.png';
import Bell from '@renders/bell.png';
import Search from '@renders/search.png';
import Pay from '@renders/pay.png';
import Person from '@renders/person.png';

const renders: Record<RenderedIcon, any> = {
  taxes: Taxes,
  income: Taxes,
  coverage: Coverage,
  retirement: Retirement,
  saved: Saved,

  taxesAlt: TaxesAlt,
  coverageAlt: CoverageAlt,
  retirementAlt: RetirementAlt,
  savedAlt: SavedAlt,

  brand: Jetpack,
  link: BankLink,
  faceid: FaceId,
  cog: Cog,
  life: Life,
  vision: Vision,
  dental: Dental,
  timeOff: TimeOff,
  emergencySavings: EmergencySavings,
  familyLeave: FamilyLeave,
  healthExpenses: HealthExpenses,
  cake: Cake,
  car: Car,
  gift: Gift,
  house: House,
  laptop: Laptop,
  luggage: Luggage,
  target: Target,
  tools: Tools,
  bell: Bell,
  search: Search,
  pay: Pay,
  priceTag: PriceTag,
  pet: Pet,
  safe: Safe,
  bag: Bag,
  person: Person,
};

export interface IconSvgProps {
  name: RenderedIcon;
  size?: AssetSize;
  style?: any;
  blend?: boolean;
}

const StyledImage = styled.img<{ $size: AssetSize; $blend: boolean }>`
  height: ${({ $size }) => CatchTheme.assets[$size]}px;
  width: ${({ $size }) => CatchTheme.assets[$size]}px;
  ${({ $blend }) => $blend && 'mix-blend-mode: luminosity;'}
`;

export const Render = ({ name, size = 'lg', style = {}, blend }: IconSvgProps) => {
  return <StyledImage src={renders[name]} $size={size} $blend={!!blend} />;
};

export default Render;
