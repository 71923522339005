import React from 'react';
import { View } from 'react-native';
import { Asset, Text } from '@uikit';
import { TextColor } from '@types';
import { close, pop } from '../misc';
import Back from '@svg/chevron-left.svg';
import Close from '@svg/close.svg';

interface Props {
  title?: string;
  subtitle?: string;
  navHex: string;
  isTransparent: boolean;
  textColor: TextColor;
  canGoBack: boolean;
  right?: any;
  onClose?: () => void;
}

/**
 * Magic nav is very magical
 * - It takes the current route and handles displaying data
 * - Can be used on full page or nav modals
 * - Is really awesome generally
 */
const SheetNav = ({
  title,
  subtitle,
  isTransparent,
  navHex,
  textColor,
  right,
  canGoBack,
  onClose,
  style,
}: Props) => {
  const onPress = () => {
    if (canGoBack) {
      pop();
    } else if (onClose) {
      onClose();
    } else {
      close();
    }
  };

  return (
    <View style={[styles.nav, style]}>
      <View style={styles.left}>
        <Asset svg={canGoBack ? Back : Close} size="xs" onPress={onPress} color={textColor} />
      </View>
      <View style={styles.center}>
        {title ? (
          <View>
            <Text size="fp" align="center">
              {title}
            </Text>
          </View>
        ) : null}
        {subtitle ? (
          <View>
            <Text size="ffp" color="subtle" align="center">
              {subtitle}
            </Text>
          </View>
        ) : null}
      </View>
      <View style={styles.right}>{right}</View>
    </View>
  );
};

const styles = {
  center: {
    zIndex: -1, // so the title goes behind the buttons
    position: 'absolute', // for absolute center of the title
    left: 0, // for absolute center of the title
    right: 0, // for absolute center of the title
    width: '100%',
  },
  nav: {
    zIndex: 999,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 48,
  },
  left: {
    position: 'absolute',
    left: 16,
  },
  right: {
    position: 'absolute',
    right: 16,
  },
};

export default SheetNav;
